<div fxLayout="row" fxLayoutAlign="space-between center" class="p-2" *ngIf="!isRecommendation">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <ng-content select="[ehTitleIcon]"></ng-content>
    <span class="eh-title"><ng-content select="[ehTitle]"></ng-content></span>
  </div>

  <div>
    <!--
      if more flexibility is needed, we'll need directives so we can use content projection,
      and also listen the click event of the projected content
    <ng-content select="[ehCancelButton]"></ng-content>
    <ng-content select="[ehEditButton]"></ng-content>
    <ng-content select="[ehSaveButton]"></ng-content>
    -->
    <button *ngIf="!isEditMode" mat-icon-button (click)="handleEditClick()">
      <mat-icon aria-label="Edit">create</mat-icon>
    </button>
    <button *ngIf="isEditMode" mat-icon-button (click)="isEditMode = false">
      <mat-icon aria-label="Cancel">clear</mat-icon>
    </button>
    <button *ngIf="isEditMode" mat-icon-button color="primary" (click)="handleSaveClick()">
      <mat-icon aria-label="Save">save</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="isRecommendation" class="recomendation-headings">
  <button *ngIf="isEditMode" mat-icon-button (click)="isEditMode = false">
    <mat-icon aria-label="Cancel">clear</mat-icon>
  </button>
  <button *ngIf="isEditMode" mat-icon-button color="primary" (click)="handleSaveClick()">
    <mat-icon aria-label="Save">save</mat-icon>
  </button>
</div>
<div *ngIf="!isEditMode">
  <!-- .ql-editor is used here, so the div will look like the quill js editor, since that's we use for editing the content -->
  <quill-editor *ngIf="!isEditMode && !isRecommendation" [theme]="'snow'" [style]="quillEditorReadOnlyOptions.style"  [modules]="quillEditorReadOnlyOptions.modules" [readOnly]="quillEditorReadOnlyOptions.readOnly" [(ngModel)]="html" #editor>
  </quill-editor>
  <div *ngIf="isRecommendation" class="recommendation-content">
    <div class="recommendation-text" [innerHTML]="html | trust: 'html'" (click)="handleRecommendationClick()"></div>
    <div *ngIf="!isEditMode && !isCompleted && !isLocked" class="recomendation-icon-content">
      <mat-icon aria-label="Edit" (click)="handleEditClick()" class="recomendation-icon">create</mat-icon>
    </div>
    <div *ngIf="!isEditMode && !isCompleted && !isLocked">
      <mat-icon aria-label="Link" (click)="handleLinkClick()" class="recomendation-icon">link</mat-icon>
    </div>
    <div *ngIf="!isEditMode" class="recomendation-icon">
      <ng-content select="[ehDeleteIcon]"></ng-content>
    </div>
  </div>
</div>
<quill-editor *ngIf="isEditMode" [theme]="'snow'" [style]="quillEditorOptions.style" [modules]="quillEditorOptions.modules" [(ngModel)]="editHtml" #editor></quill-editor>

