export const SHIFT_TIMES = [
  {
    label: '5-7 PM ET',
    value: 1,
  },
  {
    label: '7-9 PM ET',
    value: 2,
  }
];

export const SHIFT_DAYS = [
  {
    label: 'Monday',
    value: '1',
  },
  {
    label: 'Tuesday',
    value: '2',
  },
  {
    label: 'Wednesday',
    value: '3',
  },
  {
    label: 'Thursday',
    value: '4',
  },
  {
    label: 'Friday',
    value: '5',
  },
  {
    label: 'Saturday',
    value: '6',
  },
  {
    label: 'Sunday',
    value: '7',
  },
  {
    label: 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday',
    value: '8',
  },
];

export enum CLAIM_STATUSES {
  pending = 'PENDING',
  verified = 'VERIFIED',
  denied = 'DENIED'
};

export enum SUBMITTED_CLAIM_STATUSES {
  A0 = "Acknowledgement/Forwarded-The claim/encounter has been forwarded to another entity",
  A1 = "Acknowledgement/Receipt-The claim/encounter has been received. This does not mean that the claim has been accepted for adjudication",
  A2 = "Acknowledgement/Acceptance into adjudication system-The claim/encounter has been accepted into the adjudication system",
  A3 = "Acknowledgement/Returned as unprocessable claim-The claim/encounter has been rejected and has not been entered into the adjudication system",
  A4 = "Acknowledgement/Not Found-The claim/encounter can not be found in the adjudication system",
  A5 = "Acknowledgement/Split Claim-The claim/encounter has been split upon acceptance into the adjudication system",
  A6 = "Acknowledgement/Rejected for Missing Information - The claim/encounter is missing the information specified in the Status details and has been rejected",
  A7 = "Acknowledgement/Rejected for Invalid Information - The claim/encounter has invalid information as specified in the Status details and has been rejected",
  A8 = "Acknowledgement/Rejected for relational field in error",
  DR01 = "Acknowledgement/Receipt - The claim/encounter has been received. This does not mean the claim has been accepted into the data reporting/processing system. Usage: Can only be used in the Data Reporting Acknowledgement Transaction",
  DR02 = "Acknowledgement/Acceptance into the data reporting/processing system - The claim/encounter has been accepted into the data reporting/processing system. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  DR03 = "Acknowledgement/Returned as unprocessable claim - The claim/encounter has been rejected and has not been entered into the data reporting/processing system. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  DR04 = "Acknowledgement/Not Found - The claim/encounter can not be found in the data reporting/processing system. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  DR05 = "Acknowledgement/Rejected for Missing Information - The claim/encounter is missing the information specified in the Status details and has been rejected. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  DR06 = "Acknowledgment/Rejected for invalid information - The claim/encounter has invalid information as specified in the Status details and has been rejected. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  DR07 = "Acknowledgement/Rejected for relational field in error. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  DR08 = "Acknowledgement/Warning - The claim/encounter has been accepted into the data reporting/processing system but has received a warning as specified in the Status details. Usage: Can only be used in the Data Reporting Acknowledgment Transaction",
  P0 = "Pending: Adjudication/Details-This is a generic message about a pended claim. A pended claim is one for which no remittance advice has been issued, or only part of the claim has been paid",
  P1 = "Pending/In Process-The claim or encounter is in the adjudication system",
  P2 = "Pending/Payer Review-The claim/encounter is suspended and is pending review (e.g. medical review, repricing, Third Party Administrator processing)",
  P3 = "Pending/Provider Requested Information - The claim or encounter is waiting for information that has already been requested from the provider. (Usage: A Claim Status Code identifying the type of information requested, must be reported)",
  P4 = "Pending/Patient Requested Information - The claim or encounter is waiting for information that has already been requested from the patient. (Usage: A status code identifying the type of information requested must be sent)",
  P5 = "Pending/Payer Administrative/System hold",
  F0 = "Finalized-The claim/encounter has completed the adjudication cycle and no more action will be taken",
  F1 = "Finalized/Payment-The claim/line has been paid",
  F2 = "Finalized/Denial-The claim/line has been denied",
  F3 = "Finalized/Revised - Adjudication information has been changed",
  F3F = "Finalized/Forwarded-The claim/encounter processing has been completed. Any applicable payment has been made and the claim/encounter has been forwarded to a subsequent entity as identified on the original claim or in this payer's records",
  F3N = "Finalized/Not Forwarded-The claim/encounter processing has been completed. Any applicable payment has been made. The claim/encounter has NOT been forwarded to any subsequent entity identified on the original claim",
  F4 = "Finalized/Adjudication Complete - No payment forthcoming-The claim/encounter has been adjudicated and no further payment is forthcoming",
  R0 = "Requests for additional Information/General Requests-Requests that don't fall into other R-type categories",
  R1 = "Requests for additional Information/Entity Requests-Requests for information about specific entities (subscribers, patients, various providers)",
  R3 = "Requests for additional Information/Claim/Line-Requests for information that could normally be submitted on a claim",
  R4 = "Requests for additional Information/Documentation-Requests for additional supporting documentation. Examples: certification, x-ray, notes",
  R5 = "Request for additional information/more specific detail-Additional information as a follow up to a previous request is needed. The original information was received but is inadequate. More specific/detailed information is requested",
  R6 = "Requests for additional information – Regulatory requirements",
  R7 = "Requests for additional information – Confirm care is consistent with Health Plan policy coverage",
  R8 = "Requests for additional information – Confirm care is consistent with health plan coverage exceptions",
  R9 = "Requests for additional information – Determination of medical necessity",
  R10 = "Requests for additional information – Support a filed grievance or appeal",
  R11 = "Requests for additional information – Pre-payment review of claims",
  R12 = "Requests for additional information – Clarification or justification of use for specified procedure code",
  R13 = "Requests for additional information – Original documents submitted are not readable. Used only for subsequent request(s)",
  R14 = "Requests for additional information – Original documents received are not what was requested. Used only for subsequent request(s)",
  R15 = "Requests for additional information – Workers Compensation coverage determination",
  R16 = "Requests for additional information – Eligibility determination",
  R17 = "Replacement of a Prior Request. Used to indicate that the current attachment request replaces a prior attachment request",
  E0 = "Response not possible - error on submitted request data",
  E1 = "Response not possible - System Status",
  E2 = "Information Holder is not responding; resubmit at a later time",
  E3 = "Correction required - relational fields in error",
  E4 = "Trading partner agreement specific requirement not met: Data correction required. (Usage: A status code identifying the type of information requested must be sent)",
  D0 = "Data Search Unsuccessful - The payer is unable to return status on the requested claim(s) based on the submitted search criteria",
}
