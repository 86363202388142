<div fxLayout="column" fxLayoutGap="15px">
  <!-- <ngx-datatable class="material mr-table" [columnMode]="'force'" [rows]="mostCommonAces" [rowHeight]="'auto'" [headerHeight]="'auto'">
    <ngx-datatable-column name="Rank" maxWidth="50">
      <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <strong>{{rowIndex + 1}}</strong>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="5 Most common ACEs" minWidth="150" prop="question">
      <ng-template let-mostCommonAce="row" let-value="value" ngx-datatable-cell-template>
        {{mostCommonAce.question}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Frequency" maxWidth="125" prop="count">
      <ng-template let-mostCommonAce="row" let-value="value" ngx-datatable-cell-template>
        {{mostCommonAce.count}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Percentage" maxWidth="125">
      <ng-template let-mostCommonAce="row" let-value="value" ngx-datatable-cell-template>
        {{mostCommonAce.percentage + '%'}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable> -->

  <!-- <ngx-datatable class="material mr-table" [columnMode]="'force'" [rows]="frequentPtsdSymptoms" [rowHeight]="'auto'" [headerHeight]="'auto'">
    <ngx-datatable-column name="Rank" maxWidth="50">
      <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <strong>{{rowIndex + 1}}</strong>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column  minWidth="200" name="Frequency of symptoms in order of most commonly experienced (responded 'Yes')" prop="question">
      <ng-template let-frequentPtsdSymptom="row" let-value="value" ngx-datatable-cell-template>
        {{frequentPtsdSymptom.question}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Frequency" maxWidth="125">
      <ng-template let-frequentPtsdSymptom="row" let-value="value" ngx-datatable-cell-template>
        {{frequentPtsdSymptom.frequency}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Percentage" maxWidth="125">
      <ng-template let-frequentPtsdSymptom="row" let-value="value" ngx-datatable-cell-template>
        {{frequentPtsdSymptom.percentage + '%'}}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable> -->

  <div matTooltip="Average Score (higher score means strategy is used more often among students)" fxFlex="100%">
    <!-- <ngx-datatable class="material mr-table" [columnMode]="'force'" [rows]="postitiveRsqStrategies" [rowHeight]="'auto'" [headerHeight]="'auto'">
      <ngx-datatable-column name="Positive RSQ Strategies" minWidth="200" prop="question">
        <ng-template let-postitiveRsqStrategie="row" let-value="value" ngx-datatable-cell-template>
          {{postitiveRsqStrategie.question}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Average Score" maxWidth="125"  prop="score">
        <ng-template let-postitiveRsqStrategie="row" let-value="value" ngx-datatable-cell-template>
          {{postitiveRsqStrategie.score}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Percentage" maxWidth="125">
        <ng-template let-postitiveRsqStrategie="row" let-value="value" ngx-datatable-cell-template>
          {{postitiveRsqStrategie.percentage + '%'}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable> -->

    <!-- <ngx-datatable class="material mr-table" [columnMode]="'force'" [rows]="negativeRsqStrategies" [rowHeight]="'auto'" [headerHeight]="'auto'">
      <ngx-datatable-column name="Negative RSQ Strategies" minWidth="200" prop="question">
        <ng-template let-negativeRsqStrategie="row" let-value="value" ngx-datatable-cell-template>
          {{negativeRsqStrategie.question}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Average Score" maxWidth="125" prop="score">
        <ng-template let-negativeRsqStrategie="row" let-value="value" ngx-datatable-cell-template>
          {{negativeRsqStrategie.score}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Percentage" maxWidth="125">
        <ng-template let-negativeRsqStrategie="row" let-value="value" ngx-datatable-cell-template>
          {{negativeRsqStrategie.percentage + '%'}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable> -->
  </div>

  <div matTooltip="{{matTooltipForEpochLow}} {{matTooltipForEpochHigh}}" fxFLex="100%">
    <!-- <ngx-datatable class="material mr-table" [columnMode]="'force'" [rows]="highAndLowEpochAreas" [rowHeight]="'auto'" [headerHeight]="'auto'">
      <ngx-datatable-column name="EPOCH Area" minWidth="150" prop='question'>
        <ng-template let-highAndLowEpochArea="row" let-value="value" ngx-datatable-cell-template>
          {{highAndLowEpochArea.question}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Highest | Frequency" maxWidth="150" prop="highest.frequency">
        <ng-template let-highAndLowEpochArea="row" let-value="value" ngx-datatable-cell-template >
          {{highAndLowEpochArea.highest.frequency}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Lowest | Frequency" maxWidth="150" prop="lowest.frequency">
        <ng-template let-highAndLowEpochArea="row" let-value="value" ngx-datatable-cell-template >
          {{highAndLowEpochArea.lowest.frequency}}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable> -->
  </div>
</div>
