import { Injectable } from '@angular/core';
import { User } from 'app/models/user';

@Injectable()
export class UtilityService {

  constructor() {}

  cleanUpQueryFilter(filterToClean: any = {}) {
    let filter: any = {};

    filter.studentId = filterToClean.studentId || '';
    filter.partnerId = filterToClean.partnerId || '';
    filter.city = filterToClean.city || '';
    filter.partnerLocation = filterToClean.partnerLocation || "";
    filter.gender = filterToClean.gender || '';
    filter.age = filterToClean.age || '';
    filter.ethnicity = filterToClean.ethnicity || '';
    filter.orientation = filterToClean.orientation || "";
    filter.grade = filterToClean.grade || '';
    filter.aceScoreRange = filterToClean.aceScoreRange || '';
    filter.ptsdScoreRange = filterToClean.ptsdScoreRange || '';
    filter.rsqScoreRange = filterToClean.rsqScoreRange || '';
    filter.epochScoreRange = filterToClean.epochScoreRange || '';
    filter.startDate = filterToClean.startDate || '';
    filter.endDate = filterToClean.endDate || '';
    filter.live = filterToClean.live;

    return filter;
  }

  getTimeZones() {
    return [
      {  value: 'Pacific/Midway', utcDisplay: '(UTC-11:00) Midway Island, American Samoa' },
      {  value: 'America/Adak', utcDisplay: '(UTC-10:00) Aleutian Islands' },
      {  value: 'Pacific/Honolulu', utcDisplay: '(UTC-10:00) Hawaii' },
      {  value: 'Pacific/Marquesas', utcDisplay: '(UTC-09:30) Marquesas Islands' },
      {  value: 'America/Anchorage', utcDisplay: '(UTC-09:00) Alaska' },
      {  value: 'America/Tijuana', utcDisplay: '(UTC-08:00) Baja California' },
      {  value: 'America/Los_Angeles', utcDisplay: '(UTC-08:00) Pacific Time (US and Canada)' },
      {  value: 'America/Phoenix', utcDisplay: '(UTC-07:00) Arizona' },
      {  value: 'America/Chihuahua', utcDisplay: '(UTC-07:00) Chihuahua, La Paz, Mazatlan' },
      {  value: 'America/Denver', utcDisplay: '(UTC-07:00) Mountain Time (US and Canada)' },
      {  value: 'America/Belize', utcDisplay: '(UTC-06:00) Central America' },
      {  value: 'America/Chicago', utcDisplay: '(UTC-06:00) Central Time (US and Canada)  ' },
      {  value: 'Pacific/Easter', utcDisplay: '(UTC-06:00) Easter Island' },
      {  value: 'America/Mexico_City', utcDisplay: '(UTC-06:00) Guadalajara, Mexico City, Monterrey' },
      {  value: 'America/Regina', utcDisplay: '(UTC-06:00) Saskatchewan' },
      {  value: 'America/Bogota', utcDisplay: '(UTC-05:00) Bogota, Lima, Quito' },
      {  value: 'America/Cancun', utcDisplay: '(UTC-05:00) Chetumal' },
      {  value: 'America/New_York', utcDisplay: '(UTC-05:00) Eastern Time (US and Canada)' },
      {  value: 'America/Port-au-Prince', utcDisplay: '(UTC-05:00) Haiti' },
      {  value: 'America/Havana', utcDisplay: '(UTC-05:00) Havana' },
      {  value: 'America/Indiana/Indianapolis', utcDisplay: '(UTC-05:00) Indiana (East)' },
      {  value: 'America/Asuncion', utcDisplay: '(UTC-04:00) Asuncion' },
      {  value: 'America/Halifax', utcDisplay: '(UTC-04:00) Atlantic Time (Canada)' },
      {  value: 'America/Caracas', utcDisplay: '(UTC-04:00) Caracas' },
      {  value: 'America/Cuiaba', utcDisplay: '(UTC-04:00) Cuiaba' },
      {  value: 'America/Manaus', utcDisplay: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' },
      {  value: 'America/Santiago', utcDisplay: '(UTC-04:00) Santiago' },
      {  value: 'America/Grand_Turk', utcDisplay: '(UTC-04:00) Turks and Caicos' },
      {  value: 'America/St_Johns', utcDisplay: '(UTC-03:30) Newfoundland' },
      {  value: 'America/Fortaleza', utcDisplay: '(UTC-03:00) Araguaina' },
      {  value: 'America/Sao_Paulo', utcDisplay: '(UTC-03:00) Brasilia' },
      {  value: 'America/Cayenne', utcDisplay: '(UTC-03:00) Cayenne, Fortaleza' },
      {  value: 'America/Buenos_Aires', utcDisplay: '(UTC-03:00) City of Buenos Aires' },
      {  value: 'America/Godthab', utcDisplay: '(UTC-03:00) Greenland' },
      {  value: 'America/Montevideo', utcDisplay: '(UTC-03:00) Montevideo' },
      {  value: 'America/Miquelon', utcDisplay: '(UTC-03:00) Saint Pierre and Miquelon' },
      {  value: 'America/Bahia', utcDisplay: '(UTC-03:00) Salvador' },
      {  value: 'America/Noronha', utcDisplay: '(UTC-02:00) Fernando de Noronha' },
      {  value: 'Atlantic/Azores', utcDisplay: '(UTC-01:00) Azores' },
      {  value: 'Atlantic/Cape_Verde', utcDisplay: '(UTC-01:00) Cabo Verde Islands' },
      {  value: 'Africa/Casablanca', utcDisplay: '(UTC) Casablanca' },
      {  value: 'Europe/London', utcDisplay: '(UTC) Dublin, Edinburgh, Lisbon, London' },
      {  value: 'Africa/Monrovia', utcDisplay: '(UTC) Monrovia, Reykjavik' },
      {  value: 'Europe/Amsterdam', utcDisplay: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
      {  value: 'Europe/Belgrade', utcDisplay: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
      {  value: 'Europe/Brussels', utcDisplay: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' },
      {  value: 'Europe/Warsaw', utcDisplay: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
      {  value: 'Africa/Algiers', utcDisplay: '(UTC+01:00) West Central Africa' },
      {  value: 'Africa/Windhoek', utcDisplay: '(UTC+01:00) Windhoek' },
      {  value: 'Asia/Amman', utcDisplay: '(UTC+02:00) Amman' },
      {  value: 'Europe/Athens', utcDisplay: '(UTC+02:00) Athens, Bucharest' },
      {  value: 'Asia/Beirut', utcDisplay: '(UTC+02:00) Beirut' },
      {  value: 'Africa/Cairo', utcDisplay: '(UTC+02:00) Cairo' },
      {  value: 'Asia/Damascus', utcDisplay: '(UTC+02:00) Damascus' },
      {  value: 'Asia/Gaza', utcDisplay: '(UTC+02:00) Gaza, Hebron' },
      {  value: 'Africa/Harare', utcDisplay: '(UTC+02:00) Harare, Pretoria' },
      {  value: 'Europe/Helsinki', utcDisplay: '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius' },
      {  value: 'Asia/Jerusalem', utcDisplay: '(UTC+02:00) Jerusalem' },
      {  value: 'Europe/Kaliningrad', utcDisplay: '(UTC+02:00) Kaliningrad' },
      {  value: 'Africa/Tripoli', utcDisplay: '(UTC+02:00) Tripoli' },
      {  value: 'Asia/Baghdad', utcDisplay: '(UTC+03:00) Baghdad' },
      {  value: 'Asia/Istanbul', utcDisplay: '(UTC+03:00) Istanbul' },
      {  value: 'Asia/Kuwait', utcDisplay: '(UTC+03:00) Kuwait, Riyadh' },
      {  value: 'Europe/Minsk', utcDisplay: '(UTC+03:00) Minsk' },
      {  value: 'Europe/Moscow', utcDisplay: '(UTC+03:00) Moscow, St. Petersburg, Volgograd' },
      {  value: 'Africa/Nairobi', utcDisplay: '(UTC+03:00) Nairobi' },
      {  value: 'Asia/Tehran', utcDisplay: '(UTC+03:30) Tehran' },
      {  value: 'Asia/Muscat', utcDisplay: '(UTC+04:00) Abu Dhabi, Muscat' },
      {  value: 'Europe/Astrakhan', utcDisplay: '(UTC+04:00) Astrakhan, Ulyanovsk' },
      {  value: 'Asia/Baku', utcDisplay: '(UTC+04:00) Baku' },
      {  value: 'Europe/Samara', utcDisplay: '(UTC+04:00) Izhevsk, Samara' },
      {  value: 'Indian/Mauritius', utcDisplay: '(UTC+04:00) Port Louis' },
      {  value: 'Asia/Tbilisi', utcDisplay: '(UTC+04:00) Tbilisi' },
      {  value: 'Asia/Yerevan', utcDisplay: '(UTC+04:00) Yerevan' },
      {  value: 'Asia/Kabul', utcDisplay: '(UTC+04:30) Kabul' },
      {  value: 'Asia/Tashkent', utcDisplay: '(UTC+05:00) Tashkent, Ashgabat' },
      {  value: 'Asia/Yekaterinburg', utcDisplay: '(UTC+05:00) Ekaterinburg' },
      {  value: 'Asia/Karachi', utcDisplay: '(UTC+05:00) Islamabad, Karachi' },
      {  value: 'Asia/Kolkata', utcDisplay: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
      {  value: 'Asia/Colombo', utcDisplay: '(UTC+05:30) Sri Jayawardenepura' },
      {  value: 'Asia/Katmandu', utcDisplay: '(UTC+05:45) Kathmandu' },
      {  value: 'Asia/Almaty', utcDisplay: '(UTC+06:00) Astana' },
      {  value: 'Asia/Dhaka', utcDisplay: '(UTC+06:00) Dhaka' },
      {  value: 'Asia/Novosibirsk', utcDisplay: '(UTC+06:00) Novosibirsk' },
      {  value: 'Asia/Rangoon', utcDisplay: '(UTC+06:30) Yangon (Rangoon)' },
      {  value: 'Asia/Bangkok', utcDisplay: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
      {  value: 'Asia/Barnaul', utcDisplay: '(UTC+07:00) Barnaul, Gorno-Altaysk' },
      {  value: 'Asia/Hovd', utcDisplay: '(UTC+07:00) Hovd' },
      {  value: 'Asia/Krasnoyarsk', utcDisplay: '(UTC+07:00) Krasnoyarsk' },
      {  value: 'Asia/Tomsk', utcDisplay: '(UTC+07:00) Tomsk' },
      {  value: 'Asia/Chongqing', utcDisplay: '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi' },
      {  value: 'Asia/Irkutsk', utcDisplay: '(UTC+08:00) Irkutsk' },
      {  value: 'Asia/Kuala_Lumpur', utcDisplay: '(UTC+08:00) Kuala Lumpur, Singapore' },
      {  value: 'Australia/Perth', utcDisplay: '(UTC+08:00) Perth' },
      {  value: 'Asia/Taipei', utcDisplay: '(UTC+08:00) Taipei' },
      {  value: 'Asia/Ulaanbaatar', utcDisplay: '(UTC+08:00) Ulaanbaatar' },
      {  value: 'Asia/Pyongyang', utcDisplay: '(UTC+08:30) Pyongyang' },
      {  value: 'Australia/Eucla', utcDisplay: '(UTC+08:45) Eucla' },
      {  value: 'Asia/Chita', utcDisplay: '(UTC+09:00) Chita' },
      {  value: 'Asia/Tokyo', utcDisplay: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
      {  value: 'Asia/Seoul', utcDisplay: '(UTC+09:00) Seoul' },
      {  value: 'Asia/Yakutsk', utcDisplay: '(UTC+09:00) Yakutsk' },
      {  value: 'Australia/Adelaide', utcDisplay: '(UTC+09:30) Adelaide' },
      {  value: 'Australia/Darwin', utcDisplay: '(UTC+09:30) Darwin' },
      {  value: 'Australia/Brisbane', utcDisplay: '(UTC+10:00) Brisbane' },
      {  value: 'Australia/Canberra', utcDisplay: '(UTC+10:00) Canberra, Melbourne, Sydney' },
      {  value: 'Pacific/Guam', utcDisplay: '(UTC+10:00) Guam, Port Moresby' },
      {  value: 'Australia/Hobart', utcDisplay: '(UTC+10:00) Hobart' },
      {  value: 'Asia/Vladivostok', utcDisplay: '(UTC+10:00) Vladivostok' },
      {  value: 'Australia/Lord_Howe', utcDisplay: '(UTC+10:30) Lord Howe Island' },
      {  value: 'Pacific/Bougainville', utcDisplay: '(UTC+11:00) Bougainville Island' },
      {  value: 'Asia/Srednekolymsk', utcDisplay: '(UTC+11:00) Chokirdakh' },
      {  value: 'Asia/Magadan', utcDisplay: '(UTC+11:00) Magadan' },
      {  value: 'Pacific/Norfolk', utcDisplay: '(UTC+11:00) Norfolk Island' },
      {  value: 'Asia/Sakhalin', utcDisplay: '(UTC+11:00) Sakhalin' },
      {  value: 'Pacific/Guadalcanal', utcDisplay: '(UTC+11:00) Solomon Islands, New Caledonia' },
      {  value: 'Asia/Anadyr', utcDisplay: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky' },
      {  value: 'Pacific/Auckland', utcDisplay: '(UTC+12:00) Auckland, Wellington' },
      {  value: 'Pacific/Fiji', utcDisplay: '(UTC+12:00) Fiji Islands' },
      {  value: 'Pacific/Chatham', utcDisplay: '(UTC+12:45) Chatham Islands' },
      {  value: 'Pacific/Tongatapu', utcDisplay: '(UTC+13:00) Nuku\'alofa' },
      {  value: 'Pacific/Apia', utcDisplay: '(UTC+13:00) Samoa' },
      {  value: 'Pacific/Kiritimati', utcDisplay: '(UTC+14:00) Kiritimati Island' }
    ];
  }

  isoToDatePickerFormat(isoString) {
    if (!isoString) {
      return null;
    }

    const date = new Date(isoString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  datePickerToISOFormat(datePickerObject) {
    const d = datePickerObject;

    if (d && d.year && d.month && d.day) {
      // Set to NOON in UTC to accomodate for other timezones
      const date = new Date(Date.UTC(d.year, d.month - 1, d.day, 12));
      return date.toISOString();
    }

    return null;
  }

  getDefaultTimeZone() {
    return 'America/New_York';
  }

  formatCoachTeamNames(coaches: Partial<User>[]): string {
    if (coaches.length == 1) {
      return `${coaches[0].firstName} ${coaches[0].lastName}`;
    }

    const lastIndex = coaches.length - 1;
    const coachNames = coaches.map((c, index) => {
      if (index == lastIndex) {
        return `and ${c.firstName} ${c.lastName}`;
      }

      if (index == (lastIndex - 1)) {
        return `${c.firstName} ${c.lastName}`;
      }

      return `${c.firstName} ${c.lastName},`;
    });

    return coachNames.join(' ');
  }
}
