import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { WellNess } from "../../models/wellness";
import { ApiService } from "./api.service";

interface WellessList {
  payload: WellNess[];
  total: number
}

@Injectable()
export class WellNessService {

  constructor(
    private api: ApiService,
  ) { }

  findWellNesss(
    id: string, pageNumber = 0, pageSize = 5): Observable<WellessList> {
    //response body should be of this format
    // data: {payload: [], total: 500}
    return this.api.get(`/coaches/get-wellness-data?pageSize=${pageSize}&pageNumber=${pageNumber}&memberId=${id}`).pipe(
      map(res => res['data'])
    );
  }

}
