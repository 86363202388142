declare const Notification: any;
import * as toastr from 'toastr';

import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
  audioElement: HTMLAudioElement;

  private readonly TOASTR_OPTIONS = {
    closeButton: true,
    newestOnTop: true,
    progressBar: false,
    positionClass: 'toast-top-right'
  };

  constructor() {
    toastr.options = this.TOASTR_OPTIONS;
    this.audioElement = new Audio('assets/sounds/slow-spring-board.mp3');
  }

  playSound() {
    this.audioElement.play()
      .catch(err => {
        if (err.name === 'NotAllowedError') {
          this.toastr.info('New Message');
        } else {
          return Promise.reject(err);
        }
      });
  }

  requestPermission() {
    if (typeof Notification === 'undefined') {
      return Promise.resolve(false);
    }

    let permissionPromise = Notification.requestPermission();
    if (permissionPromise) {
      return permissionPromise;
    }
    return Promise.resolve('granted');
  }

  desktopNotificationForChat(message: string, studentId) {
    this.requestPermission()
      .then(result => {
        if (result === 'granted') {
          const options = {
            body: message,
            icon: 'assets/images/mr-logo.png',
            sound: 'assets/sounds/slow-spring-board.mp3'
          };

          const notification = new Notification('MindRight', options);

          notification.onclick = () => {
            // this.router.navigate(['students', studentId, 'chat']);
          };
        } else {
          // If permission not granted, tell api to send email / toastr
          this.toastr.info(message);
        }
      }).catch(_ => this.alertError('Browser doesn\'t support notifications'));
  }

  get toastr() {
    return toastr;
  }

  alertError(err) {
    const errInstance = err.name;

    if (errInstance === 'HttpErrorResponse') {
      let message;
      try {
        message = err.error.message || err.message;
      } catch (e) {
        message = 'We are sorry, our server is down momentarily and it will be ' +
                  'resolved shortly, you can contact an administrator if the problem continues.';
      }
      toastr.error(message);
    } else if (errInstance === 'Error') {
      toastr.error(err.message);
    } else {
      toastr.error(err || 'Something went wrong!');
    }
  }
}
