import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";

import {
    GetLeadsQuery,
  LeadUploadUrl,
  UploadLeadResponse,
  GetLeadsResponse,
  GenerateCsvResponse,
  GetLeadReportResponse,
} from "app/types";
import { ApiService } from "./api.service";
import { UploadApiService } from "./upload-api.service";
import { HttpResponse } from "@angular/common/http";

@Injectable()
export class LeadService {
  constructor(
    private api: ApiService,
    private uploadApi: UploadApiService,
  ) {}

  getLeadUploadUrl(fileName: string, mimeType = ""): Observable<LeadUploadUrl> {
    return this.api.post("/uploads/presigned-url", { fileName, mimeType }).pipe(
      map((res: LeadUploadUrl) => {
        if (res.status !== 200) {
          throw new Error(res.message || "Unknown error occurred");
        }
        return res;
      }),
      catchError((error) => throwError(error))
    );
  }

  postUploadLead(fileName: string): Observable<UploadLeadResponse> {
    return this.api.post("/leads/upload", { fileName }).pipe(
      map((res: UploadLeadResponse) => {
        if (res.status !== 200) {
          throw new Error(res.message || "Unknown error occurred");
        }
        return res;
      }),
      catchError((error) => throwError(error))
    );
  }

   getUploadedLead(type: string) {
    return this.api.get(`/leads/upload?type=${type}`).pipe(map(res => res['data']));
  }

  uploadFile(
    uploadUrl: string,
    file: File
  ): Observable<number | HttpResponse<any>> {
    return this.uploadApi.uploadFile(uploadUrl, file);
  }

  getLeadsPaginated(reqBody: GetLeadsQuery, leanData: boolean): Observable<GetLeadsResponse> {
    return this.api.post(`/leads?lean=${leanData}`, reqBody).pipe(
      map((res: any) => {
        if (res.status !== 200) {
          throw new Error(res.message || "Unknown error occurred");
        }
        return res;
      }),
      catchError((error) => throwError(error))
    );
  }

  getCities() {
    return this.api
      .get("/leads/cities")
      .pipe(map((res: any) => res));
  }

  getStates() {
    return this.api
      .get("/leads/states")
      .pipe(map((res: any) => res));
  }

  getZipCodes() {
    return this.api
      .get("/leads/zip-codes")
      .pipe(map((res: any) => res));
  }

  getGender() {
    return this.api
      .get("/leads/genders")
      .pipe(map((res: any) => res));
  }

  generateLeadCsv(reqBody: GetLeadsQuery): Observable<GenerateCsvResponse> {
    return this.api.post("/leads/generate-csv", reqBody).pipe(
      map((res: any) => {
        if (res.status !== 200) {
          throw new Error(res.message || "Unknown error occurred");
        }
        return res;
      }),
      catchError((error) => throwError(error))
    );
  }

  getLeadReports(pageSize: number, pageNumber: number): Observable<GetLeadReportResponse> {
    return this.api.get(`/leads/report-history?pageSize=${pageSize}&pageNumber=${pageNumber}`).pipe(
      map((res: any) => {
        if (res.status !== 200) {
          throw new Error(res.message || "Unknown error occurred");
        }
        return res;
      }),
      catchError((error) => throwError(error))
    );
  }
}
