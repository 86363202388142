
import { throwError as observableThrowError, of, Observable, Subject, timer, BehaviorSubject } from 'rxjs';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IAppState } from 'app/redux/store';

import { NgRedux } from '@angular-redux/store';

import { logoutUser } from 'app/redux/actions/user.actions';

import { AppService } from './app.service';
import { JWTokenService } from './jwtoken.service';
import { NotificationService } from './notification.service';
import { MRUserService } from './mruser.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {

  isAuthenticated$ = new Subject<boolean>();
  currentAuthStatus = null;
  user;

  readonly ROUTES = {
    ROOT:           '/',
    LOGIN:          '/login',
    RESET_PASSWORD: '/reset-password',
    DASHBOARD:      '/dashboard',
    USERS:          '/users',
    STUDENTS:       '/students',
    REPORT:         '/report',
    REPORTING:      '/reporting',
    CSS_DASHBOARD: '/css-dashboard'
  };

  // readonly OPEN_ROUTES = [
  //   this.ROUTES.ROOT,
  //   this.ROUTES.LOGIN,
  //   this.ROUTES.RESET_PASSWORD
  // ];

  // readonly ADMIN_ROUTES = [
  //   this.ROUTES.USERS,
  //   this.ROUTES.STUDENTS,
  //   this.ROUTES.REPORT
  // ];

  private currentUser = null;
  private subject = new BehaviorSubject<boolean>(undefined);    
  enabledMfA$: Observable<boolean> = this.subject.asObservable();

  constructor(
    private router: Router,
    private app: AppService,
    private ngRedux: NgRedux<IAppState>,
    private jwtService: JWTokenService,
    private mrUserService: MRUserService,
    private notification: NotificationService
  ) { }

  emitAuthStatus(isAuthed: boolean, user?) {
    if (this.currentAuthStatus !== isAuthed) {
      this.currentAuthStatus = isAuthed;
      this.isAuthenticated$.next(isAuthed);

      if (!isAuthed) {
        this.app.onLogout$.next();
      }

      if (user) {
        this.app.onUserRefresh$.next(user);
      }
    }
  }

  setFullStoryIdentity(user) {
    if (typeof FS === 'undefined') {
      return ;
    }

    if (user) {
      let fullName = `${user.firstName} ${user.lastName}`;
      FS.identify(user._id, {
        displayName: fullName,
        email: user.email,
        userType: user.type,
        environment: environment.env
      });
    } else {
      FS.identify(false);
    }
  }

  isLoggedIn() {
    if (this.jwtService.getToken()) {
      return this.getCurrentUser().pipe(
        catchError(() => {
          this.jwtService.clearToken();
          this.setFullStoryIdentity(false);
          this.emitAuthStatus(false);
          return of(false);
        }), map(user => {
          this.setFullStoryIdentity(user);
          this.emitAuthStatus(true, user);

          // Return Boolean based on the truthy value of the user
          return Boolean(user);
        }));
    }

    this.setFullStoryIdentity(false);
    this.emitAuthStatus(false);
    return of(false);
  }

  isUserAdmin(user: any) {
    if (user && user._role) {
      return (user._role.title === 'Admin') || (user._role.title === 'SuperAdmin');
    }

    return false;
  }

  
  isUserSuperAdmin() {
    if (this.currentUser && this.currentUser._role) {
      return this.currentUser._role.title === 'SuperAdmin';
    }

    return false;
  }

  isCurrentUserAdmin() {
    return this.isUserAdmin(this.currentUser);
  }

  isCurrentUserAnalystOrAdmin() {
    if (this.currentUser && this.currentUser._role) {
      const roles = ['Admin', 'SuperAdmin', 'Analyst']
      return (roles.includes(this.currentUser._role.title));
    }
  }

  isCurrentUserNotAnalyst() {
    if (this.currentUser && this.currentUser._role) {
      return this.currentUser._role.title !== 'Analyst';
    }
  }

  isAdminOrAnalyst() {
    return this.currentUser && (this.currentUser._role.hierarchy >= 4 || this.currentUser._role.name === 'Analyst');
  }

  isUserCss() {
    return this.currentUser._role.title === 'Css';
  }

  isUserManager() {
    return this.currentUser._role.title === "Manager";
  }

  isUserCoach(user: any) {
    if (user && user._role) {
      return (user._role.title === 'Coach');
    }
    return false;
  }

  isCurrentUserCoach() {
    return this.isUserCoach(this.currentUser);
  }

  isCurrentCoachCss() {
    return this.isUserCoach(this.currentUser) || this.isUserCss();
  }

  isCurrentUserCss() {
    return this.isUserCss();
  }

  login(email: string, password: string) {
    return this.mrUserService.login(email, password).pipe(
      mergeMap((res:any) => {
        if (res.enabledMFA) {
                          this.user = res
                          this.router.navigate(['two-factor', res.userId]);
                          return []
                      }
          this.jwtService.storeToken(res.id);
          this.emitAuthStatus(true);
            return this.getCurrentUser()
      }), map(user => {
        if (this.currentUser) {
          this.app.onUserRefresh$.emit(user);
        }

        this.setFullStoryIdentity(user);
        this.emitAuthStatus(true);
        this.app.onLogin$.emit(user);
        return user;
      })
    );
  }

  logout() {
    this.currentUser = null;
    this.setFullStoryIdentity(false);

    this.ngRedux.dispatch(logoutUser());
    this.emitAuthStatus(false);
    this.jwtService.clearToken();
    this.app.onLogout$.emit();

    this.router.navigate([this.ROUTES.LOGIN]);
  }

  getCurrentUser(): Observable<any> {
    if (this.currentUser) {
      return of(this.currentUser);
    } else {
      return this.mrUserService.getMe().pipe(map(user => {
        this.currentUser = user;
        this.app.setRavenUserContext(user);
        this.app.onUserRefresh$.emit(user);
        return this.currentUser;
      }));
    }
  }

  setMfAstatus(){
    return this.subject.next(true);
  }

  disableMFAstatus(){
    return this.subject.next(false);
  }

  resetPassword(password: string, code: string) {
    return this.mrUserService.resetPassword(password, code);
  }

  returnCurrentUserName() {
    return this.currentUser.firstName.charAt(0).toUpperCase() + this.currentUser.lastName.charAt(0).toUpperCase() + '.';
  }

  sendPasswordResetEmail(email: string) {
    return this.mrUserService.sendPasswordResetEmail(email);
  }

  secondFactor(token: string, userId) {
    return this.mrUserService.twoFactorStatus(token, userId)
        .subscribe(res => {
            if (res['id'] && !this.currentUser) {
                this.jwtService.storeToken(res['id']);
                // this.setFullStoryIdentity(res);
                this.emitAuthStatus(true);
                this.router.navigate(['dashboard']);
                this.notification.toastr.info('Successfuly logged in');
                return this.getCurrentUser();

            }
            if (res['message'] === 'MFA enabled successfully') {
                this.notification.toastr.info(res['message']);
                this.router.navigate(['settings']);
                this.setMfAstatus();
                return [];

            }

            return this.notification.toastr.warning('Please confirm the registered phone number, or click link below to resend code');
        });
}
 closeSecondFactorObservables(subject: Subject<any>, enabledMfA$: boolean): void {
    subject.next(enabledMfA$);
    subject.complete();
   
  }

}
