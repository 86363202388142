import * as TYPES from './action-types';

export function loadAssignedStudentsSuccess(students) {
  return {
    type: TYPES.LOAD_ASSIGNED_STUDENTS_SUCCESS,
    students
  };
}

export function loadCaseloadMembersSuccess(students) {
  return {
    type: TYPES.LOAD_CASELOAD_MEMBERS_SUCCESS,
    students
  };
}

export function loadStudentsSuccess(students) {
  return {
    type: TYPES.LOAD_STUDENTS_SUCCESS,
    students
  };
}

export function addStudentSuccess(student) {
  return {
    type: TYPES.ADD_STUDENT_SUCCESS,
    student
  };
}

export function updateStudentSuccess(student) {
  return {
    type: TYPES.UPDATE_STUDENT_SUCCESS,
    student
  };
}

export function updateStudentAwayMessageSuccess(studentId, isAwayMessageOn) {
  return {
    type: TYPES.UPDATE_STUDENT_AWAY_MESSAGE_SUCCESS,
    studentId,
    isAwayMessageOn
  };
}

export function updateStudentEscalatedModeSuccess(studentId, isEscalated) {
  return {
    type: TYPES.UPDATE_STUDENT_ESCALATED_MODE_SUCCESS,
    studentId,
    isEscalated
  };
}

export function deleteStudentSuccess(studentId) {
  return {
    type: TYPES.DELETE_STUDENT_SUCCESS,
    studentId
  };
}

export function onNewStudent(student) {
  return {
    type: TYPES.ON_NEW_STUDENT,
    student
  };
}
