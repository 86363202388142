import { NgModule } from '@angular/core';
import Quill from 'quill';

@NgModule({
    imports: [],
    providers: [],
  })
export class CustomQuillModule {
  constructor() {
    const Link = Quill.import('formats/link');

    class CustomLink extends Link {
      static create(value: any) {
        let node = super.create(value);
        // Add custom handling for link click
        node.addEventListener('click', (event: any) => {
          event.preventDefault();
          const href = node.getAttribute('href');
          if(href.includes('/chat')) window.location.href = href;
          window.open(href, '_blank');
        });
        return node;
      }
    }

    Quill.register(CustomLink, true);
  }
}
