import { Component } from '@angular/core';

@Component({
  selector: 'app-assessment-description',
  templateUrl: './assessment-description.component.html',
  styleUrls: ['./assessment-description.component.scss']
})
export class AssessmentDescriptionComponent {

  constructor() {

  }
}
