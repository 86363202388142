<div style="width: 100%; margin-bottom: 16px;">
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Search Member By Name</mat-label>
    <input matInput type="text" [(ngModel)]="memberFilter.name">
    <button *ngIf="memberFilter.name" matSuffix mat-icon-button aria-label="Clear" (click)="memberFilter.name=''">
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint align="start">
      <strong>
        <i>To search for both first and last names, use a space to separate the 2 names, otherwise this would search
          for first name only.</i>
      </strong>
    </mat-hint>
  </mat-form-field>
</div>

<mat-card [class.mat-elevation-z0]="disableShadow">
  <mat-card-content fxLayout="row wrap" fxLayoutGap="10px">
    <mat-form-field appearance="fill">
      <mat-label>Current Status</mat-label>
      <mat-select #statusFilter multiple [(value)]="memberFilter.status" placeholder="Current Status">
        <mat-option (click)="toggleAllSelection('status')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.status$ | async" [value]="option.value">{{option.label}}
        </mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="!disablePartnersWellNessSurvey && partnerView">
      <mat-label>Partner</mat-label>
      <mat-select #partnerFilter multiple [(value)]="memberFilter.partner" placeholder="Partner">
        <mat-option (click)="toggleAllSelection('partner')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.partner$ | async" [value]="option.value">{{option.label}}
        </mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="managerCoachView">
      <mat-label>Coach Team</mat-label>
      <mat-select #coachTeamFilter multiple [(value)]="memberFilter.coachTeam" placeholder="Coach Team">
        <mat-option (click)="toggleAllSelection('coachTeam')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.coachTeam" [value]="option.value">{{option.label}}
        </mat-option>
        <mat-option  [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="shiftTimeView">
      <mat-label>Session Time</mat-label>
      <mat-select #shiftTimeFilter multiple [(value)]="memberFilter.shiftTime" placeholder="Shift Time">
        <mat-option (click)="toggleAllSelection('shiftTime')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.shiftTime" [value]="option.value">{{option.label}}</mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="shiftDayView">
      <mat-label>Session Day</mat-label>
      <mat-select #shiftDayFilter multiple [(value)]="memberFilter.shiftDay" placeholder="Shift Day">
        <mat-option (click)="toggleAllSelection('shiftDay')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.shiftDay" [value]="option.value">{{option.label}}</mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="!disablePartnersWellNessSurvey">
      <mat-label>Wellness Survey Group</mat-label>
      <mat-select #wellnessSurveyGroupFilter multiple [(value)]="memberFilter.wellnessSurveyGroup"
        placeholder="Wellness Survey Group">
        <mat-option (click)="toggleAllSelection('wellnessSurveyGroup')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.wellnessSurveyGroup" [value]="option.value">{{option.label}}
        </mat-option>
       <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Race/Ethnicity</mat-label>
      <mat-select #raceAndEthnicityFilter multiple [(value)]="memberFilter.raceAndEthnicity"
        placeholder="Race/Ethnicity">
        <mat-option (click)="toggleAllSelection('raceAndEthnicity')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.raceAndEthnicity$ | async" [value]="option.value">
          {{option.label}}
        </mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Gender</mat-label>
      <mat-select #genderFilter multiple [(value)]="memberFilter.gender" placeholder="Gender">
        <mat-option (click)="toggleAllSelection('gender')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.gender$ | async" [value]="option.value">{{option.label}}
        </mat-option>
        <mat-option  [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Orientation</mat-label>
      <mat-select #orientationFilter multiple [(value)]="memberFilter.orientation" placeholder="Orientation">
        <mat-option (click)="toggleAllSelection('orientation')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.orientation$ | async" [value]="option.value">{{option.label}}
        </mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>City</mat-label>
      <mat-select #cityFilter multiple [(value)]="memberFilter.city" placeholder="City">
        <mat-option (click)="toggleAllSelection('city')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.city" [value]="option.value">{{option.label}}
        </mat-option>
        <mat-option [value]="'0'">No Data</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Age</mat-label>
      <mat-select #agesFilter multiple [(value)]="memberFilter.ages" placeholder="Age">
        <mat-option (click)="toggleAllSelection('ages')">-- ALL --</mat-option>
        <mat-option *ngFor="let option of filterOptions.ages" [value]="option.value">
          {{option.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Engagement Status</mat-label>
      <mat-select #engagementStatusFilter multiple [(value)]="memberFilter.engagementStatus" placeholder="ACTIVE ENGAGED">
        <mat-option *ngFor="let option of engagementStatusOptions" [value]="option.value">
          {{option.displayTitle}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>

  <mat-card-actions>
    <div fxLayout="row wrap" style="justify-content: center;" fxFlex="0 1 auto" fxLayoutGap="15px" *ngIf="EnableDateRange">
      <mat-form-field appearance="fill">
        <mat-label>Show</mat-label>
        <mat-select [(value)]="filterOptions.show" (selectionChange)="onSelectShowFilter()">
          <mat-option value="all-time">All Time</mat-option>
          <mat-option value="custom-range">Custom Range</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field appearance="fill" *ngIf="filterOptions.show === 'custom-range'">
        <mat-label>From</mat-label>
        <input matInput [max]="startMaxDate" (ngModelChange)="onDateInputChange('startDate', $event)"
          [ngModel]="filterOptions.startDate" [matDatepicker]="startDatePicker" placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="filterOptions.show === 'custom-range'">
        <mat-label>To</mat-label>
        <input matInput [min]="endMinDate" (ngModelChange)="onDateInputChange('endDate', $event)"
          [ngModel]="filterOptions.endDate" [matDatepicker]="endDatePicker" placeholder="Choose an end date">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>  
    </div>
   

    <div fxFlex="1 1 auto">
      <button mat-flat-button color="primary" (click)="broadcastUpdate()">Apply Filter</button>
      <button mat-flat-button (click)="clearFilter()" color="warn">Clear Filter</button>
    </div>
  </mat-card-actions>
</mat-card>
