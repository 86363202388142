export const MemberFilter = {
  status: '',
  partner: '',
  coachTeam: '',
  shiftTime: '',
  shiftDay: '',
  wellnessSurveyGroup: '',
  raceAndEthnicity: '',
  gender: '',
  orientation: '',
  city: '',
  name: '',
  ages: '',
  engagementStatus: '',
  show: 'all-time',
  startDate: null,
  endDate: null,
};
