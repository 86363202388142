import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { createStore, applyMiddleware, compose } from 'redux';

// SERVICES
import { AppService } from 'app/core/services/app.service';
import { ApiService, LoaderInterceptor } from 'app/core/services/api.service';
import { AuthService } from 'app/core/services/auth.service';
import { CoachService } from 'app/core/services/coach.service';
import { MRUserService } from 'app/core/services/mruser.service';
import { PartnerAccountService } from 'app/core/services/partner-account.service';
import { RiskAlertService } from 'app/core/services/risk-alert.service';
import { StatisticsService } from 'app/core/services/statistics.service';
import { StudentService } from 'app/core/services/student.service';
import { PartnerService } from 'app/core/services/partner.service';
import { JWTokenService } from 'app/core/services/jwtoken.service';
import { SocketIOService } from 'app/core/services/socket.service';
import { NotificationService } from 'app/core/services/notification.service';
import { ConversationService } from 'app/core/services/conversation.service';
import { UtilityService } from 'app/core/services/util.service';
import { MindRightService } from 'app/core/services/mindright.service';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { CannedMessagesService } from 'app/core/services/canned-messages.service';
import { UserRoleService } from 'app/core/services/role.service';
import { LocalStorageService } from 'app/core/services/local-storage.service';
import { CssService } from 'app/core/services/css.service';
// END: SERVICES

// PIPES
import { DisplayStudentNamePipe } from 'app/shared/pipes/display-student-name.pipe';
import { DisplayUserNamePipe } from 'app/shared/pipes/display-user-name.pipe'
import { MomentFormatPipe } from 'app/shared/pipes/moment-format.pipe';
import { ScheduleMomentFormatPipe } from 'app/shared/pipes/schedule-moment.pipe';
import { FormatPhoneNumberPipe } from 'app/shared/pipes/format-phone-number.pipe';
import { DisplayGenderPipe } from 'app/shared/pipes/display-gender.pipe';
import { DisplayPronounsPipe } from 'app/shared/pipes/display-pronouns.pipe';
import { DisplayOrientationPipe } from 'app/shared/pipes/display-orientation.pipe';
import { DisplayRacePipe } from 'app/shared/pipes/display-race.pipe';
import { ComponentFnPipe } from 'app/shared/pipes/component-function.pipe';
// END: PIPES

import { createLogger } from 'redux-logger';
import { NgRedux } from '@angular-redux/store';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from '../redux/reducers';
import { IAppState, INITIAL_STATE } from '../redux/store';
import { environment } from 'environments/environment';

import * as Raven from 'raven-js';
import * as createRavenMiddleware from 'raven-for-redux';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CheckUserGuard } from './guards/check-user.guard';
import { ReportService } from './services/report.service';
import { ApplicationService } from 'app/core/services/application.service';
import { WellNesssDataSource } from 'app/core/services/wellnessData.service';
import { WellNessService } from 'app/core/services/wellness.services';
import { EngagementDataSource } from 'app/core/services/engagement.service'
import { SessionStorageService } from './services/session-storage.service';
import { RecommendationsService } from './services/recommendations.service';
import { CheckCssGuard } from './guards/check-css.guard';
import { UploadApiService } from './services/upload-api.service';
import { LeadService } from './services/lead.service';
import { CoachPreferenceService } from './services/coach-preference.service';

const AppProviders: Array<Provider> = [
  // SERVICES
  JwtHelperService,
  AppService,
  ApplicationService,
  ApiService,
  UploadApiService,
  LoaderInterceptor,
  AuthService,
  CoachService,
  MRUserService,
  PartnerAccountService,
  RiskAlertService,
  StatisticsService,
  StudentService,
  LeadService,
  PartnerService,
  JWTokenService,
  SocketIOService,
  NotificationService,
  ConversationService,
  UtilityService,
  MindRightService,
  AdminSettingsService,
  CannedMessagesService,
  LocalStorageService,
  UserRoleService,
  ReportService,
  WellNessService,
  WellNesssDataSource,
  EngagementDataSource,
  CssService,
  SessionStorageService,
  RecommendationsService,
  CoachPreferenceService,
  // END: SERVICES

  // GUARDS
  CheckUserGuard,
  CheckCssGuard,
  // END: GUARDS

  // PIPES
  DisplayStudentNamePipe,
  DisplayUserNamePipe,
  FormatPhoneNumberPipe,
  MomentFormatPipe,
  ScheduleMomentFormatPipe,
  DisplayGenderPipe,
  DisplayPronounsPipe,
  DisplayOrientationPipe,
  DisplayRacePipe,
  ComponentFnPipe,
  // END: PIPES

  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
];

const allowRaven = () => {
  const envs = ['dev', 'staging', 'production'];
  return envs.indexOf(environment.env) > -1;
};
// Angular ErrorHandler to send raven app errors
// TODO: This most likely should be in its own file and imported
export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    console.error(err);
    Raven.captureException(err);
  }
}

if (allowRaven()) {
  Raven
    .config('https://8e8698f20fe84409ac5382d968f3597c@sentry.io/166634', {
      environment: environment.env
    })
    .install();

  AppProviders.push({ provide: ErrorHandler, useClass: RavenErrorHandler });
}

// export function tokenGetter() {
//   return window.localStorage.getItem('jwt');
// }
export function tokenGetter() {
  return window.sessionStorage.getItem('jwt');
}

@NgModule({
  providers: AppProviders,
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter,
        headerName: 'X-ACCESS-TOKEN'
      }
    }),
    HttpClientModule
  ]
})
export class CoreModule {
  constructor(
    ngRedux: NgRedux<IAppState>
  ) {
    const reduxMiddlewares = [];

    if (allowRaven()) {
      // Middleware to intercept redux errors
      const reduxRavenMiddleware = createRavenMiddleware(Raven, {});
      reduxMiddlewares.push(reduxRavenMiddleware);
    } else {
      const logger = createLogger({ collapsed: true });
      reduxMiddlewares.push(logger);
    }

    const store = createStore(
      rootReducer,
      // INITIAL_STATE,
      compose(composeWithDevTools(
        applyMiddleware(
          ...reduxMiddlewares
        )
      ))
    );

    ngRedux.provideStore(store);
  }
}
