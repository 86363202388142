<div fxLayout="column" fxLayoutAlign="center" style="min-height: 100vh; background-image: url(assets/images/website-header-master.png);background-size: cover;background-position-x: right;">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px" fxLayoutAlign="center">
    <img fxFlexAlign="center" src="assets/images/mr-logo.png" class="image" style="width: 200px">

    <mat-card>
      <mat-card-content fxLayout="column">
        <div fxLayout="column" fxLayoutAlign="center start" class="p-5">
          <i class="fa fa-unlink fa-4x card-bg-icon" style="pointer-events: none"></i>

          <h2 class="text-muted">Page Not Found</h2>
          <a routerLink="/login">Go back home</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
