import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'trust',
  pure: true
})
export class BypassSecurityPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(data: string, type = 'html'): any {
    data = data || '';

    if (type === 'html') {
      return this.sanitizer.bypassSecurityTrustHtml(data);
    }
  }
}
