import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

export interface IManagerPermission{
  message: string,
  data?: any 
}

export interface IManagerPermissions {
  title: string,
  name: string,
  description: string
}

export interface IManagerResponse{
  title: string,
  name: string,
  id: string,
  description: string
} 

export interface assignManagerPermission {
  managerId: string,
  permissions: string[]
}

@Injectable()
export class UserRoleService {
  constructor(private api: ApiService) { }

  getUserRoles(): any {
    return this.api.get('/roles');
  }

  getManagerRoles(): Observable<IManagerResponse[]> {
    return this.api.getData<IManagerResponse[]>('/roles/all-manager-permission');
  }

  assignManagerPermission(data: assignManagerPermission): Observable<{message: string}> {   
    return this.api.postData<{message: string}>('/roles/assign-manager-permission', {...data});
  }

  unassignManagerPermission(data: assignManagerPermission): Observable<{message: string}> {   
    return this.api.postData<{message: string}>('/roles/unassign-manager-permission', {...data});
  }

  createManagerPermission(data): Observable<IManagerPermission> {
    return this.api.postData<IManagerPermission>('/roles/create-manager-permission', {data});
  }

  getIndividualManagerPermission(managerId: string) {
    return this.api.get('/roles/individual-manager-permission/'+ managerId);
  }

  checkRole (arr: string[], currentRole: string):boolean {
    return arr.includes(currentRole);
  }
}
