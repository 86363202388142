export const CLEAR_STORE = 'CLEAR_STORE';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const LOAD_COACH_SUCCESS = 'LOAD_COACH_SUCCESS';

export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const LOAD_ASSIGNED_STUDENTS = 'LOAD_ASSIGNED_STUDENTS';
export const LOAD_ASSIGNED_STUDENTS_SUCCESS = 'LOAD_ASSIGNED_STUDENTS_SUCCESS';
export const LOAD_ASSIGNED_STUDENTS_FAILURE = 'LOAD_ASSIGNED_STUDENTS_FAILURE';
export const LOAD_CASELOAD_MEMBERS_SUCCESS = 'LOAD_CASELOAD_MEMBERS_SUCCESS';
export const LOAD_CASELOAD_MEMBERS_FAILURE = 'LOAD_CASELOAD_MEMBERS_FAILURE';

export const LOAD_ASSIGNED_MEMBER_IDS = 'LOAD_ASSIGNED_MEMBER_IDS'

export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILURE = 'UPDATE_STUDENT_FAILURE';

export const ADD_STUDENT = 'ADD_STUDENT';
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
export const ADD_STUDENT_FAILURE = 'ADD_STUDENT_FAILURE';

export const UPDATE_STUDENT_AWAY_MESSAGE_SUCCESS = 'UPDATE_STUDENT_AWAY_MESSAGE_SUCCESS';

export const UPDATE_STUDENT_ESCALATED_MODE_SUCCESS = 'UPDATE_STUDENT_ESCALATED_MODE_SUCCESS';

export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';

export const LOAD_STUDENTS = 'LOAD_STUDENTS';
export const LOAD_STUDENTS_SUCCESS = 'LOAD_STUDENTS_SUCCESS';
export const LOAD_STUDENTS_FAILURE = 'LOAD_STUDENTS_FAILURE';

export const DASHBOARD_TOGGLE_STUDENT_SELECT = 'DASHBOARD_TOGGLE_STUDENT_SELECT';
export const DASHBOARD_SELECT_ALL_STUDENTS = 'DASHBOARD_SELECT_ALL_STUDENTS';
export const DASHBOARD_DESELECT_ALL_STUDENTS = 'DASHBOARD_DESELECT_ALL_STUDENTS';
export const DASHBOARD_PASTE_TO_SELECTED_STUDENTS = 'DASHBOARD_PASTE_TO_SELECTED_STUDENTS';
export const DASHBOARD_CLEAR_ALL_INPUT_BOXES = 'DASHBOARD_CLEAR_ALL_INPUT_BOXES';
export const DASHBOARD_FILTER_STUDENTS = 'DASHBOARD_FILTER_STUDENTS';
export const DASHBOARD_CLEAR_FILTER_STUDENTS = 'DASHBOARD_CLEAR_FILTER_STUDENTS';

export const DASHBOARD_SET_IS_ACTIVE_FILTER = 'DASHBOARD_SET_IS_ACTIVE_FILTER';

export const ON_NEW_STUDENT = 'ON_NEW_STUDENT';
export const ON_NEW_STUDENT_CONVERSATION = 'ON_NEW_STUDENT_CONVERSATION';

export const CLEAR_CONVERSATIONS = 'CLEAR_CONVERSATIONS';

export const LOAD_CONVERSATION_SUCCESS = 'LOAD_CONVERSATION_SUCCESS';
export const LOAD_CONVERSATIONS_SUCCESS = 'LOAD_CONVERSATIONS_SUCCESS';
export const LOAD_CONVERSATIONS_FAILURE = 'LOAD_CONVERSATIONS_FAILURE';

export const LOAD_MORE_IN_CONVERSATION = 'LOAD_MORE_IN_CONVERSATION';
export const LOAD_MORE_IN_CONVERSATION_SUCCESS = 'LOAD_MORE_IN_CONVERSATION_SUCCESS';
export const LOAD_MORE_IN_CONVERSATION_FAILURE = 'LOAD_MORE_IN_CONVERSATION_FAILURE';

export const ON_SEND_MESSAGE = 'ON_SEND_MESSAGE';
export const ON_SEND_MESSAGE_SUCCESS = 'ON_SEND_MESSAGE_SUCCESS';
export const ON_SEND_MESSAGE_FAILURE = 'ON_SEND_MESSAGE_FAILURE';

export const LOAD_MOOD_NUMBERS = 'LOAD_MOOD_NUMBERS';
export const LOAD_MOOD_NUMBERS_SUCCESS = 'LOAD_MOOD_NUMBERS_SUCCESS';
export const LOAD_MOOD_NUMBERS_FAILURE = 'LOAD_MOOD_NUMBERS_FAILURE';

export const ON_CONVERSATION_NEW_MESSAGE = 'ON_CONVERSATION_NEW_MESSAGE';
export const ON_CONVERSATION_MESSAGE_UPDATE = 'ON_CONVERSATION_MESSAGE_UPDATE';

export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const UNSET_CURRENT_CONVERSATION = 'UNSET_CURRENT_CONVERSATION';

export const LOAD_GENDER_SELECTIONS = 'LOAD_GENDER_SELECTIONS';
export const LOAD_GENDER_SELECTIONS_SUCCESS = 'LOAD_GENDER_SELECTIONS_SUCCESS';
export const LOAD_GENDER_SELECTIONS_FAILURE = 'LOAD_GENDER_SELECTIONS_FAILURE';

export const LOAD_SEXUAL_ORIENTATION_SELECTIONS = 'LOAD_SEXUAL_ORIENTATION_SELECTIONS';
export const LOAD_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS = 'LOAD_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS';
export const LOAD_SEXUAL_ORIENTATION_SELECTIONS_FAILURE = 'LOAD_SEXUAL_ORIENTATION_SELECTIONS_FAILURE';

export const LOAD_PRONOUNS_SELECTIONS = 'LOAD_PRONOUNS_SELECTIONS';
export const LOAD_PRONOUNS_SELECTIONS_SUCCESS = 'LOAD_PRONOUNS_SELECTIONS_SUCCESS';
export const LOAD_PRONOUNS_SELECTIONS_FAILURE = 'LOAD_PRONOUNS_SELECTIONS_FAILURE';

export const LOAD_RACE_SELECTIONS = 'LOAD_RACE_SELECTIONS';
export const LOAD_RACE_SELECTIONS_SUCCESS = 'LOAD_RACE_SELECTIONS_SUCCESS';
export const LOAD_RACE_SELECTIONS_FAILURE = 'LOAD_RACE_SELECTIONS_FAILURE';

export const LOAD_COACH_RACE_SELECTIONS = 'LOAD_COACH_RACE_SELECTIONS';
export const LOAD_COACH_RACE_SELECTIONS_SUCCESS = 'LOAD_COACH_RACE_SELECTIONS_SUCCESS';
export const LOAD_COACH_RACE_SELECTIONS_FAILURE = 'LOAD_COACH_RACE_SELECTIONS_FAILURE';

export const LOAD_COACH_GENDER_SELECTIONS = 'LOAD_COACH_GENDER_SELECTIONS';
export const LOAD_COACH_GENDER_SELECTIONS_SUCCESS = 'LOAD_COACH_GENDER_SELECTIONS_SUCCESS';
export const LOAD_COACH_GENDER_SELECTIONS_FAILURE = 'LOAD_COACH_GENDER_SELECTIONS_FAILURE';

export const LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS = 'LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS';
export const LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS = 'LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS_SUCCESS';
export const LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS_FAILURE = 'LOAD_COACH_SEXUAL_ORIENTATION_SELECTIONS_FAILURE';

export const LOAD_PARTNERS_SELECTIONS = 'LOAD_PARTNERS_SELECTIONS';
export const LOAD_PARTNERS_SELECTIONS_SUCCESS = 'LOAD_PARTNERS_SELECTIONS_SUCCESS';
export const LOAD_PARTNERS_SELECTIONS_FAILURE = 'LOAD_PARTNERS_SELECTIONS_FAILURE';

export const LOAD_MANAGERS_PERMISSION = 'LOAD_MANAGERS_PERMISSION';
export const LOAD_MANAGERS_PERMISSION_SUCCESS = 'LOAD_MANAGERS_PERMISSION_SUCCESS';
export const LOAD_MANAGERS_PERMISSION_FAILURE = 'LOAD_MANAGERS_PERMISSION_FAILURE';

// App Actions
export const SET_APP_LOADING = 'app/SET_APP_LOADING'
export const SET_APP_LOADING_PERCENTAGE = 'app/SET_APP_LOADING_PERCENTAGE'
export const SET_APP_LOADING_MESSAGE = 'app/SET_APP_LOADING_MESSAGE'
export const SET_STATUS_OPTIONS = 'app/SET_STATUS_OPTIONS'
