import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, share, switchMap, take } from 'rxjs/operators';

import { EventEmitter, Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';

import * as lodash from 'lodash'

import {
  clearConversations,
  setCurrentConversation,
  unsetCurrentConversation,
  loadConversationSuccess,
  loadMoreInConversationSuccess,
  loadConversationsSuccess,
  onConversationNewMessage,
  onSendMessageSuccessful,
  onNewStudentConversation,
  onConversationMessageUpdate,
  loadAssignedMemberIds
} from 'app/redux/actions/conversation.actions';

import { onNewStudent } from 'app/redux/actions/student.actions';

import { SELECTORS as ConvoSelectors } from 'app/redux/reducers/conversation.reducer';

import { SocketIOService } from './socket.service';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { CoachService } from './coach.service';
import { NotificationService } from './notification.service';

import { DisplayStudentNamePipe } from 'app/shared/pipes/display-student-name.pipe';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { Student } from 'app/models/student';

@Injectable()
export class ConversationService {

  private readonly NEW_TEXT_SOCKET_EVENT_NAME = 'TEXT:';
  private readonly NEW_TEXT_CRISIS_SOCKET_EVENT_NAME = 'TEXT_CRISIS:';
  private readonly MESSAGE_UPDATE_SOCKET_EVENT_NAME = 'MESSAGE_UPDATE:';

  private readonly NEW_STUDENT_SOCKET_EVENT_NAME = 'STUDENT:';
  private readonly ASSIGNED_STUDENTS_UPDATE_SOCKET_EVENT_NAME = 'USER:PROFILE_UPDATE:ASSIGNED_STUDENTS:';

  private readonly LOCAL_STORAGE_CONVERSATION_DICT_LAST_SEEN_KEY = 'conversationTimestampDict';

  private conversationsObservable = {};
  private crisisConversationsObservable = {};
  private newStudentSubscription: Subscription;
  private messageUpdatesSubscription: Subscription;
  private assignedStudentsChangesSubscription: Subscription;
  private conversationsSubscriptions: Array<Subscription> = [];
  private crisisConversationsSubscriptions: Array<Subscription> = [];

  onUpdateInitLoad$ = new EventEmitter<any>();
  onUpdatedConversationMessage$ = new EventEmitter<any>();

  private allAssignedMemberIds = []
  crisisTextMembers = new BehaviorSubject<Student[]>([]);

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private rxsocket: SocketIOService,
    private coachService: CoachService,
    private ngRedux: NgRedux<IAppState>,
    private notification: NotificationService,
    private localStorage: LocalStorageService,
    private displayStudentNamePipe: DisplayStudentNamePipe,
    private notificationService: NotificationService,
    private sessionStorage: SessionStorageService,
  ) { }

  getConversationTimestampDict() {
    return this.localStorage.getItem(this.LOCAL_STORAGE_CONVERSATION_DICT_LAST_SEEN_KEY);
  }

  init() {
    this.handleInit()
  }

  async handleInit() {
    this.onUpdateInitLoad$.emit({
      message: 'Initializing assigned members',
      percentage: Math.floor(Math.random() * 71) + 30, // Random number between 30 and 100
    })

    const assignedMemberIds = await this.getAssignedMemberUserIds()
    this.allAssignedMemberIds = assignedMemberIds

    this.onUpdateInitLoad$.emit({
      message: 'Updating local store',
      percentage: 100,
      complete: true,
    })

    this.subscribeMembersToConversations(assignedMemberIds)

    this.listenForNewStudents();

    this.listenForMessageUpdates();

    this.listenForAssignedStudentsChanges();
  }

  refresh() {
    let currentConversation = this.ngRedux.getState().entities.conversations.currentConversation;

    this.loadAssignedStudentsConversations(30);

    this.ngRedux.dispatch(clearConversations());
    if (currentConversation) {
      this.loadConversation(currentConversation);
    }
  }

  listenForMessageUpdates() {
    let dispatch = this.ngRedux.dispatch;

    // If Subscribed already, no need to resubscribe
    if (!this.messageUpdatesSubscription) {
      this.messageUpdatesSubscription =
        this.rxsocket.on(this.MESSAGE_UPDATE_SOCKET_EVENT_NAME)
          .subscribe(message => {
            dispatch(onConversationMessageUpdate(message));
          });
    }
  }

  listenForAssignedStudentsChanges() {
    // If Subscribed already, no need to resubscribe
    if (!this.assignedStudentsChangesSubscription) {
      this.assignedStudentsChangesSubscription =
        this.auth.getCurrentUser()
          .pipe(
            switchMap((user) => this.rxsocket.on(
              this.ASSIGNED_STUDENTS_UPDATE_SOCKET_EVENT_NAME + user._id
            ))
          ).subscribe((item) => {
            // this.coachService.loadAssignedStudents(true);
            let finalConversations = []
            const assignedMembers = item.assignedMembers
            if ('conversations' in assignedMembers) {
              finalConversations = assignedMembers.conversations || []
            }
            if (finalConversations.length) {
              this.ngRedux.dispatch(loadConversationsSuccess(finalConversations));
              this.buildConversationTimestampDict(finalConversations);
              this.onUpdatedConversationMessage$.emit({
                memberId: null,
                message: null,
                allConversations: finalConversations,
              })
            }
          });
    }
  }

  listenForNewStudents() {
    let dispatch = this.ngRedux.dispatch;

    // Only listen if User is An Admin or Super Admin
    this.auth.getCurrentUser().subscribe(user => {
      if (this.auth.isUserAdmin(user)) {
        // If Subscribed already, no need to resubscribe
        if (!this.newStudentSubscription) {
          this.newStudentSubscription =
            this.rxsocket.on(this.NEW_STUDENT_SOCKET_EVENT_NAME)
              .subscribe(student => {
                let studentName = this.displayStudentNamePipe.transform(student, []);

                dispatch(onNewStudent(student));
                dispatch(onNewStudentConversation(student));
                this.notification.playSound();
                this.notification.desktopNotificationForChat(
                  `New Student: ${studentName}`, student['_id']
                );
              });
        }
      }
    });
  }

  getCurrentConversation() {
    return this.ngRedux.select(ConvoSelectors.getCurrentConversation);
  }

  setCurrentConversation(studentId: string) {
    this.ngRedux.dispatch(setCurrentConversation(studentId));
  }

  unsetCurrentConversation() {
    this.ngRedux.dispatch(unsetCurrentConversation());
  }

  getAssignedStudentsConversations() {
    return this.ngRedux.select(ConvoSelectors.getAssignedStudentsConversations);
  }

  private onNewMessage = (text, memberId) => {
    // Only proceed if the member is in the users' assigned member list
    const finder = this.allAssignedMemberIds.find(item => {
      return item === memberId
    })

    if (!finder) return false

    // Only notify if the text has a _sender attached and
    // text is from a Student
    if (text['_sender'] && text['_sender']['type'] === 'Student') {
      const firstName = text['_sender']['firstName'] || text['_sender']['phone'];

      const message =
        `From ${firstName}: ${text['message']}`
          .replace(/\s+/g, ' ');

      this.notification.playSound();
      this.notification.desktopNotificationForChat(message, memberId);   
    }

    let currentConvId =
      this.ngRedux.getState().entities.conversations.currentConversation;

    this.ngRedux.dispatch(onConversationNewMessage(text, currentConvId));

    let conversations: any = this.ngRedux.getState()
      .entities.conversations.assignedStudentsConversations

    // Find the conversation with this member
    const textIndex = lodash.findIndex(conversations, (item: any) => item._id === memberId)
    if (textIndex !== -1) {
      conversations[textIndex].lastMessage = text
      conversations[textIndex].lastMessageCreatedAt = text.createdAt
    }
    this.ngRedux.dispatch(loadConversationsSuccess(conversations));

    this.onUpdatedConversationMessage$.emit({
      memberId,
      message: text,
      allConversations: conversations,
    })
  }

  private subscribeToConversations = (students) => {
    this.conversationsSubscriptions.forEach(cSub => cSub.unsubscribe());
    this.conversationsSubscriptions = [];

    students.forEach(student => {
      // Save the observables for each message
      // in the this.conversationsObservable object
      // using the student._id as the key
      const messageObservable =
        this.rxsocket.on(this.NEW_TEXT_SOCKET_EVENT_NAME + student['_id']);

      this.conversationsObservable[student['_id']] = messageObservable;

      // Subscribe to the Observable for each student
      let subscription = messageObservable
        .subscribe(text => this.onNewMessage(text, student._id));

      this.conversationsSubscriptions.push(subscription);

      // Crisis Conversation updates
      const crisisObservable = this.rxsocket
        .on(this.NEW_TEXT_CRISIS_SOCKET_EVENT_NAME + student['_id'])
      this.crisisConversationsObservable[student['_id']] = crisisObservable;

      let crisisSubscription = crisisObservable
        .subscribe(message => this.handleCrisisConversationUpdates(message))
      this.crisisConversationsSubscriptions.push(crisisSubscription)
    });
  }

  private subscribeMembersToConversations = (memberIds) => {
    this.conversationsSubscriptions.forEach(cSub => cSub.unsubscribe());
    this.conversationsSubscriptions = [];

    memberIds.forEach(id => {
      // Save the observables for each message
      // in the this.conversationsObservable object
      // using the student._id as the key
      const messageObservable =
        this.rxsocket.on(this.NEW_TEXT_SOCKET_EVENT_NAME + id);

      this.conversationsObservable[id] = messageObservable;

      // Subscribe to the Observable for each student
      let subscription = messageObservable
        .subscribe(text => this.onNewMessage(text, id));

      this.conversationsSubscriptions.push(subscription);

      // Crisis Conversation updates
      const crisisObservable = this.rxsocket
        .on(this.NEW_TEXT_CRISIS_SOCKET_EVENT_NAME + id)
      this.crisisConversationsObservable[id] = crisisObservable;

      let crisisSubscription = crisisObservable
        .subscribe(message => this.handleCrisisConversationUpdates(message))
      this.crisisConversationsSubscriptions.push(crisisSubscription)
    });
  }

  async loadAssignedStudentsConversations(limit = null, page = 0) {
    return this.api.get(`/coaches/me/students/recent-conversations?limit=${limit}&page=${page}`).toPromise()
      .then((res: any) => {
        // this.coachService.getPotentialCrisisTexts()
        // .subscribe((response: any) => {
        //   const crisisTexts = response.data.crisisConversations || []
        //   const conversations = res.data

        //   conversations.forEach((element, index) => {
        //     const crisisMessage = lodash.find(crisisTexts, item => item._id === element.lastMessage._id)
        //     if (crisisMessage) {
        //       conversations[index].potentialCrisisTexts = true
        //     } else {
        //       conversations[index].potentialCrisisTexts = false
        //     }
        //   });

        //   this.ngRedux.dispatch(loadConversationsSuccess(conversations));
        //   this.notificationService.toastr.info('Conversations Updated successfully');
        // })
        const conversations = res.data.conversations
        this.ngRedux.dispatch(loadConversationsSuccess(conversations));
        // this.notificationService.toastr.info('Conversations Updated successfully');
      }, (error) => {
        console.error(error);
        this.notificationService.toastr.error('Failed to update conversations');
      });
  }

  async loadAssignedMemberConversations(limit = null, page = 0, reset = false) {
    try {
      const request: any = await this.api.get(`/coaches/me/students/recent-conversations?limit=${limit}&page=${page}`)
        .toPromise()

      const data = request.data
      if (!data) throw new Error('Could not retrieve list of conversations')

      const oldConversations = await this.ngRedux
        .select(ConvoSelectors.getAssignedStudentsConversations).pipe(take(1))
        .toPromise() || []

      const newConversations = data.conversations
      let finalConversations = []

      if (reset) {
        finalConversations = newConversations
      } else {
        finalConversations = [
          ...oldConversations,
          ...newConversations
        ]
      }

      this.ngRedux.dispatch(loadConversationsSuccess(finalConversations));
      this.buildConversationTimestampDict(finalConversations);
      return {
        conversations: finalConversations,
        totalCount: data.totalCount,
      }
    } catch (error) {
      console.error(error)
      this.notificationService.toastr.error(error.message || 'Failed to update conversations');
      return {
        conversations: [],
        totalCount: 0,
      }
    }
  }

  async getAssignedMemberUserIds() {
    try {
      const request: any = await this.api.get('/coaches/me/assigned-member-ids')
        .toPromise()

      const data = request.data
      if (!data) throw new Error('Failed to Initialize members. This means that new messages from members may not alert you. Please create a bug ticket to report this. problem')

      this.ngRedux.dispatch(loadAssignedMemberIds(request.data || []));

      return request.data || []
    } catch (error) {
      console.error(error)
      this.notificationService.toastr.error(error.messasge || 'Failed to Initialize members. This means that new messages from members may not alert you. Please create a bug ticket to report this problem.');
      return []
    }
  }

  loadConversation(studentId: string, force = false) {
    let dispatch = this.ngRedux.dispatch;

    // If false is true, then bypass redux check and get the conversations
    if (!force && this.ngRedux.getState().entities.conversations.byId[studentId]) {
      return;
    }

    this.api.get('/students/' + studentId + '/conversation')
      .subscribe((res: any) => {
        dispatch(loadConversationSuccess(studentId, res.data));
      });
  }

  getConversation(studentId: string, options: { before_date?: string, after_date?: string, limit?: number }) {
    // const dispatch =  this.ngRedux.dispatch;
    const validOptions = ['before_date', 'after_date', 'limit'];

    let filter = {};
    validOptions.forEach(opt => filter[opt] = options[opt]);

    let apiCall = this.api.get('/students/' + studentId + '/conversation', filter).pipe(share());

    // apiCall.subscribe(res => {
    //   // dispatch(loadMoreInConversationSuccess(studentId, res.data));
    // });

    return apiCall;
  }

  loadMoreInConversation(studentId: string, options: { before_date?: string, after_date?: string, limit?: number }) {
    const dispatch = this.ngRedux.dispatch;
    const validOptions = ['before_date', 'after_date', 'limit'];

    let filter = {};
    validOptions.forEach(opt => filter[opt] = options[opt]);

    let apiCall = this.api.get('/students/' + studentId + '/conversation', filter).pipe(share());

    apiCall.subscribe((res: any) => {
      dispatch(loadMoreInConversationSuccess(studentId, res.data));
    });

    return apiCall;
  }

  sendText(studentId: string, message: string) {
    return this.api.post('/students/' + studentId + '/texts', { message }).pipe(
      catchError(err => {
        this.notification.alertError(err);
        return throwError(err);
      }));
  }

  attachTagsToMessage(conversationId: string, tags: string[]) {
    return this.api.post('/conversations/' + conversationId + '/tags', { tags }).pipe(
      catchError(err => {
        this.notification.alertError(err);
        return throwError(err);
      }));
  }

  setConversationRestrictions(conversationIds: string[], restrictions: any) {
    return this.api.patch('/conversations/restrictions', {
      conversationIds,
      restrictions
    }).pipe(
      catchError(err => {
        this.notification.alertError(err);
        return throwError(err);
      }));
  }
  getMoods(studentId) {
    return this.api.get('/students/' + studentId + '/mood');
  }

  saveMoodNumber(studentId, moodNumber) {
    return this.api.post('/students/' + studentId + '/mood', { moodNumber });
  }

  getFeelings(studentId) {
    return this.api.get('/students/' + studentId + '/feelings');
  }

  saveFeeling(studentId, feeling) {
    return this.api.post('/students/' + studentId + '/feelings', { feeling });
  }

  saveNotes(studentId, notes) {
    return this.api.put('/students/' + studentId + '/notes', { notes });
  }

  saveRecommendations(studentId, recommendations) {
    return this.api.put('/students/' + studentId + '/recommendations', { recommendations });
  }

  markMessagesAsRead(studentId) {
    let dict = this.localStorage.getItemSync(this.LOCAL_STORAGE_CONVERSATION_DICT_LAST_SEEN_KEY) || {};

    dict[studentId] = { lastSeenTimestamp: new Date().getTime() };
    this.localStorage.setItem(this.LOCAL_STORAGE_CONVERSATION_DICT_LAST_SEEN_KEY, dict);

    // return this.api.patch('/students/' + studentId + '/mark-as-read')
    //   .subscribe();
  }


  buildConversationTimestampDict(conversations) {
    // This sets default last seen timestamps for all un indexed conversation to the current time
    let dict = this.localStorage.getItemSync(this.LOCAL_STORAGE_CONVERSATION_DICT_LAST_SEEN_KEY) || {};

    conversations.forEach(conversation => {
      if (!dict[conversation._id]) {
        dict[conversation._id] = { lastSeenTimestamp: new Date().getTime() };
      }
    });

    this.localStorage.setItem(this.LOCAL_STORAGE_CONVERSATION_DICT_LAST_SEEN_KEY, dict);
  }


  handleCrisisConversationUpdates(message) {
    try {
      let conversations: any = this.ngRedux.getState()
        .entities.conversations.assignedStudentsConversations

      // Find the conversation with this member
      const crisisIndex = lodash.findIndex(conversations, (item: any) => item._id === message.memberId)
      if (crisisIndex !== -1) {
        conversations[crisisIndex].potentialCrisisTexts = message.potentialCrisisTexts
      }

      this.ngRedux.dispatch(loadConversationsSuccess(conversations));
    } catch (error) {
      console.error(error)
    }
  }

  fetchConversationsAwaitingResponses(memberIds: string[]) {
    return this.api.post('/conversations/awaiting-response', { members: memberIds });
  }

  fetchConversationTags(memberIds: string[], startDate = '', endDate = '') {
    return this.api.post('/conversations/tags', { members: memberIds, startDate, endDate }).pipe(
      map(res => res['data'])
    );
  }

  getCrisisTextsMembers(): Observable<Student[]> {
    return this.crisisTextMembers.asObservable();
  }
}
