import { combineReducers } from 'redux';

import { IAppState, INITIAL_STATE } from 'app/redux/store';

import * as TYPES from 'app/redux/actions/action-types';

import meReducer from 'app/redux/reducers/me.reducer';
import userReducer from 'app/redux/reducers/user.reducer';
import dashboardReducer from 'app/redux/reducers/dashboard.reducer';
import conversationReducer from 'app/redux/reducers/conversation.reducer';
import appReducer from './app.reducer';

export const rootReducer = (state, action) => {
  if (action.type === TYPES.LOGOUT_USER) {
    state = INITIAL_STATE;
  }

  return combineReducers({
    entities: combineReducers({
      me: meReducer,
      users: userReducer,
      conversations: conversationReducer,
      dashboard: dashboardReducer,
      app: appReducer,
      moods: s => [],
      feelings: s => [],
      studentSlots: s => []
    })
  })(state, action);
};
