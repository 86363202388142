import * as TYPES from './action-types';

export function loginUser(email, password, loginSuccessRoute) {
  return {
    type: TYPES.LOGIN_USER,
    email,
    password,
    toUrl: loginSuccessRoute
  };
}
export function loginUserSuccess(data) {
  return {
    type: TYPES.LOGIN_USER_SUCCESS,
    payload: data
  };
}
export function loginUserFailure(error) {
  return {
    type: TYPES.LOGIN_USER_FAILURE,
    error
  };
}

export function logoutUser() {
  return {
    type: TYPES.LOGOUT_USER
  };
}

export function loadUser(userId) {
  return {
    type: TYPES.LOAD_USER,
    id: userId
  };
}

export function loadUserSuccess(user) {
  return {
    type: TYPES.LOAD_USER_SUCCESS,
    user
  };
}

export function loadUserFailure(error) {
  return {
    type: TYPES.LOAD_USER_FAILURE,
    error
  };
}

export function loadUsersSuccess(users) {
  return {
    type: TYPES.LOAD_USERS_SUCCESS,
    users
  };
}

export function addUserSuccess(user) {
  return {
    type: TYPES.ADD_USER_SUCCESS,
    user
  };
}
