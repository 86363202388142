
import { UiSwitchModule } from 'ngx-ui-switch';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { NgReduxModule } from '@angular-redux/store';

import { EmojiPickerModule } from './LIB/ng-emoji-picker';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

import { MaterialModule } from './material.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AssessmentDescriptionComponent } from './components/assessment-description/assessment-description.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SurveyConfirmationDialogComponent } from './modules/app-settings/configuration.component';
import { CrisisDetectionDialogComponent, LinkUnlinkRecommendationDialogComponent } from './modules/coaching/pages/chat/chat.component';
import { CrisisPendingDetectionDialogComponent } from './modules/coaching/pages/chat/chat.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MdePopoverModule } from '@material-extended/mde'
import { NgxEchartsModule }  from 'ngx-echarts'
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
    AssessmentDescriptionComponent,
    PageNotFoundComponent,
    SurveyConfirmationDialogComponent,
    CrisisDetectionDialogComponent,
    CrisisPendingDetectionDialogComponent,
    LinkUnlinkRecommendationDialogComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgReduxModule,
    EmojiPickerModule,
    BrowserModule,
    BrowserAnimationsModule,
    UiSwitchModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatMomentDateModule,
    MdePopoverModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
  ],
  entryComponents: [
    SurveyConfirmationDialogComponent,
    CrisisDetectionDialogComponent,
    CrisisPendingDetectionDialogComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {}
}
