import { Injectable, EventEmitter } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { IAllCss, ICssLoaded, IEditCssSchedule } from 'app/models/css';
import { Student } from "app/models/student";
import { Coach } from "app/models/coach";

type CssAssignedRecords = { members: Student[], coaches: Coach[] };

@Injectable({
  providedIn: "root"
})
export class CssService {
  currentPartnerInPartnerDashboard$ = new EventEmitter();

  constructor(private api: ApiService) {}

  getAllCss(params = {}): Observable<ICssLoaded[]> {
    const queryString = new HttpParams({ fromObject: params }).toString();
    return this.api.getData<IAllCss>(`/csses?${queryString}`).pipe(map(res => res['data']['data']));
  }

  editCssSchedule(cssId: string, date: string, data: IEditCssSchedule) {
    return this.api.patch(`/csses/schedule/${cssId}?scheduleDate=${date}`, data)
      .pipe(map((res) => res['data'].data));
  }

  getCssMembers(cssId: string): Observable<CssAssignedRecords> {
    return this.api.getData(`/csses/${cssId}/assigned-members`).pipe(map(res => res['data']));
  }

}
