
import {Observable, BehaviorSubject, of} from 'rxjs';
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {catchError, finalize} from "rxjs/operators";
import {WellNess} from '../../models/wellness';
import { WellNessService } from './wellness.services'
import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';


@Injectable()
export class WellNesssDataSource implements DataSource<WellNess> {

    private WellNesssSubject = new BehaviorSubject<WellNess[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    private _paginator: MatPaginator|null;

    constructor(private WellNessService: WellNessService) {}

    connect(collectionViewer: CollectionViewer): Observable<WellNess[]> {
        return this.WellNesssSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.WellNesssSubject.complete();
        this.loadingSubject.complete();
    }

    loadWellNesss(id: string, pageIndex = 0, pageSize = 5) {
        this.loadingSubject.next(true);

        this.WellNessService.findWellNesss(id, pageIndex, pageSize).pipe(
            catchError(() => of({payload: [], total: 0})),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(WellessList => {
            this.WellNesssSubject.next(WellessList.payload);
            this._updatePaginator(WellessList.total);
        });
    }

  get paginator(): MatPaginator | null { return this._paginator; }
  set paginator(paginator: MatPaginator|null) {
    this._paginator = paginator;
  }

  _updatePaginator(filteredDataLength: number) {
    Promise.resolve().then(() => {
      if (!this.paginator) { return; }

      this.paginator.length = filteredDataLength;

      // If the page index is set beyond the page, reduce it to the last page.
      if (this.paginator.pageIndex > 0) {
        const lastPageIndex = Math.ceil(this.paginator.length / this.paginator.pageSize) - 1 || 0;
        this.paginator.pageIndex = Math.min(this.paginator.pageIndex, lastPageIndex);
      }
    });
  }
}
