<div style="padding: 20px">
  <div class="alert alert-info">
    Description of Assessment Types
  </div>
  <table class="table table-bordered" style="table-layout: fixed">
    <thead>
        <th style="width: 20%">Assessment</th>
        <th style="width: 60%">Description</th>
        <th style="width: 20%">Source</th>
    </thead>
    <tbody>
      <tr>
        <th  scope="row">
          Adverse Childhood Experiences (ACEs)
        </th>
        <td >
          ACEs scores count the number of specified traumatic events someone has been exposed to before age 18. There are 19 ACEs in total. Research shows that people with scores of 3 or above (3 or more traumatic events) are statistically more likely to experience negative health and education outcomes. This is based on the adolescent ACEs screen developed and published by the Center for Youth Wellness.
        </td>
        <td >
          <a href="https://mindright.typeform.com/to/VGfVTC">https://mindright.typeform.com/to/VGfVTC</a>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Non-clinical PTSD Pre-screen
        </th>
        <td>
          This screen asks whether a student has experienced any of four PTSD-related symptoms over the last month: Nightmares/flashbacks, Avoidance, Hypervigilance, and Numbness. The number represents the number of symptoms the student reported to have experienced in the last month. Students with a score of 3 or above are likely be referred to a clinical expert for a formal PTSD diagnosis.
        </td>
        <td>
          <a href="http://www.integration.samhsa.gov/clinical-practice/PC-PTSD.pdf">http://www.integration.samhsa.gov/clinical-practice/PC-PTSD.pdf</a>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Response to Stress (RSQ) Measure
        </th>
        <td>
          This assessment measures how students respond to stress. Examples include seeking support, shutting down, getting angry, positive self-talk, and more. The max score is 100%. A higher score indicates more use of positive response to stress strategies.
        </td>
        <td>
          <a href="https://docs.google.com/document/d/15kc7EegePe3FUUQ8849coGneFgphST6GWPnbj729QvI/edit?usp=sharing">https://docs.google.com/document/d/15kc7EegePe3FUUQ8849coGneFgphST6GWPnbj729QvI/edit?usp=sharing</a>
        </td>
      </tr>
      <tr>
        <th scope="row">
          EPOCH Psychological Wellness Measure
        </th>
        <td>
          EPOCH Psychological Wellness Measure: This assessment measures adolescent psychological wellness in terms of Engagement, Perseverance, Optimism, Connectedness, and Happiness. The max score is 100%. A higher score indicates higher levels of wellness.
        </td>
        <td>
          <a href="http://www.peggykern.org/uploads/5/6/6/7/56678211/epoch_full_revised_paper_050115.pdf">Kern et al, “The EPOCH Measure of Adolescent Well-being,” University of Pennsylvania</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
