import { Injectable } from '@angular/core';
import { Observable, Observer, Subject, BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';

import { AppService } from './app.service';
import { JWTokenService } from './jwtoken.service';

import * as io  from 'socket.io-client';

@Injectable()
export class SocketIOService {
  sock: io;
  socketId: string;

  isOpen = false;

  hasDisconnected = false;

  socketStatus$ = new BehaviorSubject(this.getStatusObject());

  constructor(
    private app: AppService,
    private jwtService: JWTokenService
  ) {
    this.sock = io(environment.socketUrl, {
      autoConnect: false,
      transports: ['websocket']
    });

    this.init();

    this.app.onLogin$.subscribe(() =>  this.connect());
    this.app.onUserRefresh$.subscribe(() => this.connect());

    this.app.onLogout$.subscribe(() => this.disconnect());
    this.app.onUnauthorisedResponse$.subscribe(() => this.disconnect());
  }

  init() {
    this.sock.on('connect', () => {
      this.socketId = this.sock.id;
      this.isOpen = true;

      this.socketStatus$.next(this.getStatusObject());
    });

    this.sock.on('disconnect', (reason) => {
      if (reason === 'transport close') {
        // the disconnection was from network issues
        this.hasDisconnected = true;
        this.socketId = null;
        this.isOpen = false;

        this.socketStatus$.next(this.getStatusObject());
      } else {
        this.socketId = null;
        this.isOpen = false;
        this.hasDisconnected = true;
        this.socketStatus$.next(this.getStatusObject());    
      }
    });

    this.sock.on('connect_failed', () => {
      this.socketId = null;
      this.isOpen = false;
      this.hasDisconnected = true;
      this.socketStatus$.next(this.getStatusObject()); 
    });
  }

  getStatusObject() {
    return {
      isOpen: this.isOpen,
      hasDisconnected: this.hasDisconnected,
      onlineStatus: this.isOpen ? 'Online' : 'Offline'
    };
  }

  connect() {
    if (!this.isOpen) {
      const query = 'token=' + this.jwtService.getToken();
      this.sock.query = query;
      this.sock.io.opts.query = query;
      this.sock.open();
    }
    else this.sock.disconnect(true)
  }

  disconnect() {
    this.sock.disconnect(true);
  }

  on(socketEvent: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      function listen(data) {
        observer.next(data);
      }

      this.sock.on(socketEvent, listen);

      return () => {
        this.sock.removeListener(socketEvent, listen);
        // kill this.io.on to unsubscribe
      };
    });
  }

}
