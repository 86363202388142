
import { of, Observer, Observable, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';


// import { loadUsersSuccess } from 'app/redux/actions/user.actions';
// import { loadAssignedStudentsSuccess } from 'app/redux/actions/student.actions';
// import { SELECTORS as USER_SELECTORS } from 'app/redux/reducers/user.reducer';
// import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';

import { ApiService } from './api.service';
import { AppService } from './app.service';

@Injectable()
export class MRUserService {

  currentUser = null;
  currentUserPartner = null;

  constructor(
    private api: ApiService,
    private app: AppService
    // private ngRedux: NgRedux<IAppState>
  ) {
    app.onLogout$.subscribe(() => {
      this.currentUser = null;
      this.currentUserPartner = null;
    });
  }

  login(email: string, password: string) {
    // return this.api.post('/accounts/login', {
    return this.api.post('/accounts/login-v2', {
      email,
      password
    });
  }

  twoFactorStatus(token: string, userId: any) {
    return this.api.post(`/accounts/${userId}/verify-mfa`,{
      code : token
    })
  }

  activateTwoFactor(enabledMFA: boolean) {
    return this.api.patch('/accounts/update-mfa',{
      enabledMfa : enabledMFA
    })
  }
  
  setTwoFactor() {
    return this.api.patch('/accounts/enable-mfa')
  }
  deactivateTwoFactor() {
    return this.api.patch('/accounts/disable-mfa')
  }

  resendVerificationCode(userId: any) {
    return this.api.post(`/accounts/${userId}/resend-mfa-code`,{
    })
  }

  getMe() {
    return this.api.get('/accounts/me')
      .pipe(map(res => {
        this.currentUser = res;
        return this.currentUser;
      }));
  }

  updatePassword(currentPassword, newPassword) {
    return this.api.put('/accounts/me/password', {
      currentPassword,
      newPassword
    });
  }

  resetPassword(password, code) {
    return this.api.put('/accounts/reset-password/password', {
      password,
      code
    });
  }

  sendPasswordResetEmail(email) {
    return this.api.post('/accounts/reset-password', {
      email
    });
  }

  disposeMember(userId, token) {
    return this.api.dispose('/accounts/dispose', {
      userId: userId,
      token: token
    });
  }
}
