import { Pipe, PipeTransform } from '@angular/core';

import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({ name: 'formatPhoneNumber' })
export class FormatPhoneNumberPipe implements PipeTransform {
  constructor() { }

  transform(phoneNumber: any, format: string): any {
    if (typeof phoneNumber === 'string') {
      try {
        return parsePhoneNumber(phoneNumber).formatInternational();
      } catch (e) {
        return 'Invalid Number';
      }
    }
    return 'Invalid Number';
  }
}
