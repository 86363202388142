import { Pipe, PipeTransform } from '@angular/core';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { NgRedux } from '@angular-redux/store';
import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';
import { IAppState } from 'app/redux/store';

@Pipe({ name: 'displayRace' })
export class DisplayRacePipe implements PipeTransform {

  constructor(private ngRedux: NgRedux<IAppState>, private adminService: AdminSettingsService) { }

  transform(race = '', args: string[]) {
    const raceSelections = this.ngRedux.getState().entities.dashboard.profileSelections['race'];
    if (Object.keys(raceSelections).includes(race)) {
      return raceSelections[parseInt(race, 10)];
    } else {
      return race || '';
    }
  }
}
