import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";
import { CoachService } from "./coach.service";
import { CssService } from "./css.service";
import { ICssLoaded } from "app/models/css";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  private dailyScheduleSource = new BehaviorSubject<any>(null);
  dailySchedule$ = this.dailyScheduleSource.asObservable();
  constructor(
    private coachService: CoachService,
    private cssService: CssService
  ) {}

  getWeeklySchedule(
    startTime: string,
    endTime: string
  ): Observable<{
    allCsses: ICssLoaded[];
    weeklySchedule: any[];
    finalSchedule: any[];
    scheduleToDisplay: any[];
    dailySchedule: any[];
  }> {
    return combineLatest([
      this.coachService.getCssSchedule(startTime, endTime, ["1", "2"]),
      this.cssService.getAllCss({ deleted: false, archived: false }),
    ]).pipe(
      map(([schedules, csses]) => {
        const weeklySchedule = this.processWeeklySchedule(schedules, csses);
        const finalSchedule = this.getFinalSchedule(
          JSON.parse(JSON.stringify(weeklySchedule))
        );
        const dailySchedule = this.getDailySchedule(finalSchedule);
        if (dailySchedule) {
            this.updateDailySchedule(dailySchedule);
        }

        return {
          allCsses: csses,
          weeklySchedule,
          finalSchedule,
          scheduleToDisplay: finalSchedule,
          dailySchedule,
        };
      })
    );
  }

  private processWeeklySchedule(schedules: any, csses: ICssLoaded[]): any[] {
    let weeklySchedule = Object.entries(schedules["data"].data);
    weeklySchedule.sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    );
    this.addDayOfWeek(weeklySchedule);
    return this.addAllCsses(weeklySchedule, csses);
  }

  private addDayOfWeek(data: any[]): void {
    const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    if (data.length === 0) return;
    // Parse the date from the first item in the data array
    const startDate = new Date(data[0][0]);
    // Calculate the day offset
    // This converts Sunday (0) to 6, and other days shift by -1
    // Ensuring the week starts with Monday as 0
    const dayOffset = (startDate.getUTCDay() + 6) % 7;
  
    data.forEach((item, index) => {
      item["day"] = weekDays[(index + dayOffset) % 7];
    });
  }

  private addAllCsses(schedule: any[], csses: ICssLoaded[]): any[] {
    return schedule.map((item) => {
      ["shiftOne", "shiftTwo"].forEach((shift) => {
        if (item[1][shift]) {
          csses.forEach((css) => {
            const findCss = item[1][shift].find((i) => i.cssId == css._id);
            if (!findCss) {
              item[1][shift].push({
                coaches: [],
                cssId: css._id,
                firstName: css.firstName,
                lastName: css.lastName,
                status: "UNAVAILABLE",
              });
            }
          });
        }
      });
      return item;
    });
  }

  private getFinalSchedule(schedule: any[]): any[] {
    return schedule.map((item) => {
      ["shiftOne", "shiftTwo"].forEach((shift) => {
        if (item[1][shift].length) {
          item[1][shift] = item[1][shift].filter(
            (i) => i.status != "UNAVAILABLE" && i.selected
          );
        }
      });
      return item;
    });
  }

  getDailySchedule(finalSchedule: any[]): any[] {
    const today = new Date().toISOString().split("T")[0];
    const currentDayData = finalSchedule.find(
      (dayData) => dayData[0] === today
    );
    return currentDayData ? currentDayData[1] : null;
  }

  updateDailySchedule(schedule: any) {
    this.dailyScheduleSource.next(schedule);
  }

  refreshDailySchedule(): Observable<any> {
    const startTime = moment().startOf("week").add(1, 'day').format("YYYY-MM-DD");
    const endTime = moment().endOf("week").add(1, 'day').format("YYYY-MM-DD");
    return this.getWeeklySchedule(startTime, endTime)
  }
}
