import * as moment from 'moment-timezone';

export const calculateAgeFromDate = (date: Date): Number => {
  if (!date) {
    return 0;
  }

  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getSlotHumanReadableFormat = slot =>
  `${moment().hour(slot.start).format('hA')}-${moment().hour(slot.end).format('hA')}`;

export const getMemberAgeRange = () => {
  let agesRange = [];
  for (let i = 0; i < 23; i++) {
    agesRange.push({ label: i + 13, value: i + 13 });
  }
  agesRange.push({ label: '36+', value: '36+' });
  return agesRange;
}
