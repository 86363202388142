import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostBinding, SimpleChanges } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MindRightService } from 'app/core/services/mindright.service';
import { ConversationService } from 'app/core/services/conversation.service';
import { AuthService } from 'app/core/services/auth.service';
import { MomentFormatPipe } from 'app/shared/pipes/moment-format.pipe';
import * as moment from 'moment-timezone';
import { EditableHtmlComponent } from '../editable-html/editable-html.component';
import { RecommendationsService } from 'app/core/services/recommendations.service';
import { NotificationService } from 'app/core/services/notification.service';
import { StudentService } from 'app/core/services/student.service';
import { htmlToText } from "html-to-text";
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from "@angular/cdk/clipboard"

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [MindRightService]
})
export class MessageComponent implements OnInit {

  @HostBinding('class.sent-message')
  sentMessage = false;
  showConversationResponseTime = false;
  coachResponseTime: number;
  responseTimeStyleClass = '';

  formattedTimeStamp;

  tagModal;
  linkConversationModal;
  @ViewChild('tagModal', { read: null, static: false })
  tagModalContent;
  @ViewChild('linkConversationModal', { read: null, static: false })
  linkConversationModalContent;

  @Input()
  message;

  @Input()
  student

  @Input() 
  studentNotes;

  noteKeys = [];
  noteExpand = {
    GENERAL: false,
    BIOLOGICAL: false,
    ENVIRONMENT: false,
    PSYCHOLOGICAL: false,
    SOCIAL: false,
  };

  @Output()
  onSelect = new EventEmitter();
  
  @Output()
  onLinkRecommendation = new EventEmitter<string>()

  @Output()
  onNotesSaved = new EventEmitter<string>()

  isUserAdmin = false;

  tempTagsModelDict = {
    topic: [],
    emotion: [],
    strategy: []
  };

  tempTagsModel = [];

  tags = [];
  tagsDict = {
    topic: [],
    emotion: [],
    strategy: []
  };
  conversationLinkOptions = ['Recommendations', 'Notes']
  linkConversationOption = 'Recommendations'
  currentUserInitials = '';
  currentRecommendationToBeLinked;

  isInterpreted = false;
  sentiment = { score: null, vote: null };

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private mindRightService: MindRightService,
    private studentService: StudentService,
    private conversationService: ConversationService,
    private momentFormatePipe: MomentFormatPipe,
    private auth: AuthService,
    private recommendationService: RecommendationsService,
    private notification: NotificationService,
    private clipboard: Clipboard,
  ) {}

  ngOnInit() {
    if (this.message.sentiment) {
      this.sentiment = this.message.sentiment;
    }
    this.tempTagsModel = this.message.tags;
    this.masterTempTagChange(this.tempTagsModel);
    this.currentUserInitials = this.auth.returnCurrentUserName();

    this.currentRecommendationToBeLinked = {
      text: `<p>${moment().format('MM/DD/YYYY')} [${this.currentUserInitials}] :</p>`,
      isCompleted: false,
      saved: false,
      userId: null,
      linkType: 'CONVERSATION',
      linkedDate: ''
    }
    this.isUserAdmin = this.auth.isCurrentUserAdmin();

    this.formattedTimeStamp = this.momentFormatePipe.transform(this.message.createdAt, null);
    this.breakNotesToSection();

    const sender = this.message._sender;
    this.sentMessage = sender && (sender.type === 'Coach' || sender.type === 'Admin');
    if (this.sentMessage && this.message.conversationResponseTime) {
      this.showConversationResponseTime = true;
      this.coachResponseTime = moment(this.message.createdAt).diff(moment(this.message.conversationResponseTime), 'minutes');
      this.responseTimeStyleClass = this.coachResponseTime >= 5 ? 'text-danger' : 'text-success';
    }
  }

  displayMessage(message) {
    // Check if the string is Base64 like
    // By scanning for 30 characters without a space
    // TODO: Not full proof but it works for our use case
    if (/\S{30,}/.test(message)) {
      try {
        message = atob(message);
      } catch (e) {
        // Do nothing
      }
    }

    // To show smileys
    try {
      return decodeURIComponent(message);
    } catch (err) {
      // If the URI component is malformed Error
      return message;
    }
  }

  filterTagsType(tags, type: string) {
    if (tags && tags.length) {
      return tags.filter(t => t.category === type);
    }

    return [];
  }

  masterTempTagChange(tags) {
    this.tempTagsModel = tags;

    this.tempTagsModelDict['topic'] = this.filterTagsType(tags, 'topic');
    this.tempTagsModelDict['emotion'] = this.filterTagsType(tags, 'emotion');
    this.tempTagsModelDict['strategy'] = this.filterTagsType(tags, 'strategy');
  }

  tempTagModelChange(value, type) {
    this.tempTagsModelDict[type] = value;
    this.tempTagsModel = [
      ...this.tempTagsModelDict['topic'],
      ...this.tempTagsModelDict['emotion'],
      ...this.tempTagsModelDict['strategy']
    ];
  }

  linkConversationType(value) {
    this.linkConversationOption = value;
  }

  expandNotes(subnotes) {
    this.noteExpand[subnotes] = !this.noteExpand[subnotes];
  }

  breakNotesToSection() {
    if(!this.student?._data) return;
    if (!this.student._data.notes) {
      this.student._data.notes = "<p></p>";
    }
    let data = this.student._data.notes.replace(/(\r\n|\n|\r)/gm, "");
    let swembsContent = '';
    let newArray = data.split("<p>");
    newArray = newArray.map((x) => {
      if (x.trim().slice(-4) == "</p>") {
        x = `<p>` + x;
        return x;
     }

     if(x.trim()?.toLowerCase().includes('swemwbs')) {
      x = `<p>` + x;
      const swembsArray = x.split('</p>');
      let swembsStr = ''
      swembsArray.forEach((swemb, index) => {
        if(swemb.trim().toLowerCase().includes('swemwbs')) {
          swembsStr= swemb + '</p>';
          swembsArray.splice(index, 1);
        }
      });
      swembsContent= swembsArray.join();
      return swembsStr;
     }
    });

    if(swembsContent.length > 0) {
      newArray.push(swembsContent);
    };

    let acc1 = {
      SWEMWBS: "",
      GENERAL: "",
      BIOLOGICAL: "",
      ENVIRONMENT: "",
      PSYCHOLOGICAL: "",
      SOCIAL: "",
    };

    let sectionList = Object.keys(acc1);
    let currentSection = "";
    let newObj = newArray.reduce((acc, curr) => {
      if(!curr) return acc;
      let item = curr.replace(/<[^>]+>/g, "");
      if (sectionList.indexOf(item.toUpperCase().trim()) != -1) {
        currentSection = item.toUpperCase().trim();
      } else {
        if (currentSection != "") acc[currentSection] += curr;
      }
      return acc;
    }, acc1);
    newObj = this.AddNewNotesTemplates(newObj);
    this.student._data.notesObj = newObj;
    this.noteKeys = Object.keys(newObj);
  }

  AddNewNotesTemplates(totalObject) {
    let enableDeletingOldFields = false
   const arrOfFieldsToBeRemoved = ['Access to Care (do not include insurance info)', 'Personal/Professional Goals']
    const generalObject = {
      GENERAL: {
        "Hope to Gain": "",
        "Info. from Enrollment Survey": "",
        "Challenges described by Member": "",
        "Current Issues": "",
        "Past Trauma and/or Triggers": "",
        "Strengths": "",
        "Coaching Strategies used Successfully": "",
      },
      SOCIAL: {
        "Relationships/Social Supports": "",
        'Stressors': "",
        "Hobbies/Extracurricular": "",
        "Personal/Professional Goals": ""
      },
      PSYCHOLOGICAL: {
        "Coping Strategies/Self-Care": "",
        "Self-Esteem": "",
        "Values/Beliefs": "",
      },
      BIOLOGICAL: {
        "Family Medical History (mental health, substance use/etc.)": "",
        "Add Medical/Physical History": "",
        "Access to Care (do not include insurance info)": ""
      },
      ENVIRONMENT: {
        "Home (family structure, SES, expectations, cultural norms, conflict style, communication, safety, goals)":
          "",
        "School and/or Work (location, demographic, class difficulty such as honors, etc., type of job)":
          "",
      },
    };
    let totalData = Object.keys(totalObject).reduce((accum, x, index) => {
      let data = totalObject[`${x}`];
      let newObj;
      if (data == '') {
        data = '<p></p>';
      }
      let generalArray = generalObject[x];    
      let totalText = "";
      if (generalArray) {
        const subfieldsKeysArray = Object.keys(generalArray);
        const copygeneralArray = Object.keys(generalArray).map(x => x.replace(/\s+/g, ''));
        let newArray = data.split("<p>");        
        newArray = newArray.map((i) => {
          if (i.slice(-1) == ">") i = `<p>` + i;
          return i;
        });
        let currentSection = "";
      
        newObj = newArray.reduce((acc, curr) => {
          let otherContent = "";
          let currentText = htmlToText(curr).trim();
   

          if (currentText.charAt(currentText.length - 1) == ":" ) {
            currentText = currentText.slice(0, -1);
            // instead of cutting away split on this and select the first part
            // append the second part later if its not equal to null...
          }
          if (currentText.includes(':')) {
            let currentTextArray = currentText.split(':');
            currentText = currentTextArray[0];
            currentTextArray.splice(0, 1);
            otherContent = currentTextArray.join('');
          }
          const map = {
            'Strategies Used Successfully':"Coaching Strategies used Successfully",
            'Self-Image': "Self-Esteem",
            'Current Issues':"Challenges described by Member",
            "Family History (mental health/substance use/etc.)": "Family Medical History (mental health, substance use/etc.)",
            "Home (family structure, SES, expectations, cultural norms, conflict style, communication, safety)":"Home (family structure, SES, expectations, cultural norms, conflict style, communication, safety, goals)",
            "Add Info. from Enrollment Survey": "Info. from Enrollment Survey"
          }
          //if the content of the initial file matches the key
          currentText = map[currentText] || currentText
          const copyOfcurrentText = currentText

          currentText = currentText.replace(/\s+/g, '')
          const index = copygeneralArray.indexOf(currentText);
          
          // delete the content of fields that we want removed.
          if(index != -1 && arrOfFieldsToBeRemoved.includes(copyOfcurrentText)) {
            enableDeletingOldFields=true
          }
          
          if(index != -1 && !arrOfFieldsToBeRemoved.includes(copyOfcurrentText)){
            enableDeletingOldFields=false
          }

          if(enableDeletingOldFields) {
            return acc
          }
          else if (index != -1) {
            currentSection = subfieldsKeysArray[index];

            acc[
              currentSection
            ] = `<p><strong style="color: rgb(0, 71, 178);">${currentSection}</strong></p>`;

            if (otherContent.length > 0) {
              acc[
                currentSection
              ] += `<p>${otherContent}</p>`
            }
          } else {
            if (currentSection != "" && currentSection != "<p></p>") {
              acc[currentSection] += curr;
            }
          }
          return acc;
        }, generalArray);
        Object.keys(newObj).forEach((element) => {
          if (newObj[element] == "" && !arrOfFieldsToBeRemoved.includes(element)) {
            newObj[
              element
            ] = `<p><strong style="color: rgb(0, 71, 178);">${element}</strong></p>`;
          }
          totalText += newObj[element];
        });
        accum[x] = totalText;
      }
      return accum;
    }, totalObject);
    return totalData;
  }

  convertNotesObjectToString() {
    let newString = "";

    Object.keys(this.student._data.notesObj).forEach((x) => {
      newString += `<p><strong style="color: rgb(101, 84, 192)">${x}</strong></p> ${this.student._data.notesObj[x]}`;
    });
    return newString;
  }

  preprocessSavedNotes(notes: string): string {
    // wrap all ul with a p tag, if this grows coach can manually reformat
    return notes.split('</ul>').join('</ul></p>').split('<ul>').join('<p><ul>');
  }

  saveNotes(
    notes: string,
    subnote: string,
    ehComponent: EditableHtmlComponent
  ) {
    if (notes && notes.length > 0 && notes.indexOf('<p>') !== 0) {
      notes = `<p>${notes}</p>`;
    }

    const studentId = this.student._id;

    this.student._data.notesObj[subnote] = this.preprocessSavedNotes(notes);
    notes = this.convertNotesObjectToString();
    this.conversationService.saveNotes(studentId, notes).subscribe(() => {
      this.student._data.notes = notes;
      this.notification.toastr.info("Notes saved");
      this.onNotesSaved.emit('An update happened to the notes')
      ehComponent.isEditMode = false;
    });

    if(this.student?._id) {
    this.studentService.getMember(this.student._id, true).then(member => {
      this.student = member;
      this.breakNotesToSection();
    }).catch(err => {
      console.error('Error fetching student', err);
    })
   }
  }

  saveRecommendations(
    recommendations: string,
    ehComponent: EditableHtmlComponent,
  ) {

    this.currentRecommendationToBeLinked = {
      text: recommendations,
      isCompleted: false,
      saved: true,
      userId: null,
      linkType: 'CONVERSATION',
      linkedDate: ''
    }
  }

  linkRecommmendationToMessage(linkConversationDate: string, studentId: string) {
    this.currentRecommendationToBeLinked.userId = studentId;
    this.currentRecommendationToBeLinked.linkedDate = moment(linkConversationDate).format('YYYY-MM-DD');

    this.recommendationService
    .linkRecommendationsToConversation(this.currentRecommendationToBeLinked).subscribe((recommendation: any) => {
        // reset linked recommendation
        this.currentRecommendationToBeLinked = {
          text: `<p>${moment().format('MM/DD/YYYY')} [${this.currentUserInitials}] :</p>`,
          isCompleted: false,
          saved: false,
          userId: null,
          linkType: 'CONVERSATION',
          linkedDate: ''
        }

        this.notification.toastr.success('Recommendation linked successfully');
        this.onLinkRecommendation.emit('A recommendation has been linked')
        this.linkConversationModal.close();
      });
    }

  linkNoteToConversation() {
    this.linkConversationModal.close();
  }

  removeRecommendation() {
    this.currentRecommendationToBeLinked = {
      text: `<p>${moment().format('MM/DD/YYYY')} [${this.currentUserInitials}] :</p>`,
      isCompleted: false,
      saved: false,
      userId: null,
      linkType: 'CONVERSATION',
      linkedDate: ''
    }
  }

  openTagModal() {
    this.mindRightService.getTags().subscribe((tags: any) => {
      this.tags = tags;
      this.tagsDict['topic'] = this.filterTagsType(tags, 'topic');
      this.tagsDict['emotion'] = this.filterTagsType(tags, 'emotion');
      this.tagsDict['strategy'] = this.filterTagsType(tags, 'strategy');
    });

    this.tagModal = this.modalService.open(this.tagModalContent);
  }

  openLinkConversationModal() {
    this.linkConversationModal = this.modalService.open(this.linkConversationModalContent);
  }

  copyConversationLinkToClipboard() {
    let url = window.location.href;
    if(!url.includes('jump_to')) {
      url += `?jump_to=${moment(this.message.updatedAt).format('YYYY-MM-DD')}`
    }else {
      url = url.split('?')[0] + `?jump_to=${moment(this.message.updatedAt).format('YYYY-MM-DD')}`
    }

    this.clipboard.copy(url);
    this.notification.toastr.success('Conversation Link copied to clipboard');
  }

  submitTags() {
    this.conversationService
      .attachTagsToMessage(this.message._id, this.tempTagsModel)
      .subscribe((message: any) => {
        this.tagModal.close();
        this.message.tags = message.tags;
      });
  }

  getAccountName(accountSender) {
    let fullName = 'Wakanda Bot'

    if (!accountSender) return fullName
    const firstName = accountSender.firstName || 'Wakanda'
    const lastName = accountSender.lastName || 'Bot'
    fullName = `${firstName} ${lastName}`

    return fullName
  }

}
