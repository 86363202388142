import { of } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root"
})
export class PartnerService {
  currentPartnerInPartnerDashboard$ = new EventEmitter();
  partners = null;

  constructor(private api: ApiService) {}

  cleanUpFilter(filterToClean) {
    let filter: any = {};

    filter.city = filterToClean.city || "";
    filter.partnerLocation = filterToClean.partnerLocation || "";
    filter.gender = filterToClean.gender || "";
    filter.age = filterToClean.age || "";
    filter.ethnicity = filterToClean.ethnicity || "";
    filter.orientation = filterToClean.orientation || "";
    filter.aceScoreRange = filterToClean.aceScoreRange || "";
    filter.startDate = filterToClean.startDate || "";
    filter.endDate = filterToClean.endDate || "";
    filter.live = filterToClean.live;
    filter.partners = filterToClean.partners || []
    return filter;
  }

  createPartner(partner) {
    return this.api.post("/partners", partner);
  }

  getPartner(id: string) {
    return this.api.get("/partners/" + id);
  }

  getPartners() {
    if (this.partners) {
      return of(this.partners);
    }
    return this.api.get("/partners").pipe(
      map((res: any) => {
        this.partners = res.data;
        return res.data;
      })
    );
  }

  updatePartner(id: string, data) {
    return this.api.patch("/partners/" + id, data);
  }

  deletePartner(partnerId) {
    return this.api.delete("/partners/" + partnerId);
  }

  getCityFilterOptions(partnerId) {
    return this.api
      .get(`/partners/${partnerId}/filters/city`);
  }

  getStudentsForPartner(partnerId) {
    return this.api
      .get(`/partners/${partnerId}/students`)
      .pipe(map((res: any) => res.data));
  }

  getPriorities(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/students/reports/priorities`, this.cleanUpFilter(filter));
  }

  getTotalActiveMembersLiveTime(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members-by-status/2`, this.cleanUpFilter(filter));
  }

  getTotalActiveMembers(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/active`, this.cleanUpFilter(filter));
  }

  getTotalActiveMembersPast7Days(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/active/past7`, this.cleanUpFilter(filter));
  }

  getResponseRateStatistics(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/response-rate-statistics`, this.cleanUpFilter(filter));
  }

  getRetentionStatistics(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/retention-statistics`, this.cleanUpFilter(filter));
  }

  getReturnRateStatistics(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/return-rate-statistics`, this.cleanUpFilter(filter));
  }

  getTotalActiveMembersPast30Days(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/active/past30`, this.cleanUpFilter(filter));
  }

  getTotalAwayMembers(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members-by-status/3`, this.cleanUpFilter(filter));
  }

  getTotalInactiveMembers(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members-by-status/4`, this.cleanUpFilter(filter));
  }

  getTotalExitedMembers(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/members/exited`, this.cleanUpFilter(filter));
  }

  getGenderDistribution(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/gender-distribution`, this.cleanUpFilter(filter));
  }

  getOrientationDistribution(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/orientation-distribution`, this.cleanUpFilter(filter));
  }

  getRaceDistribution(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/race-distribution`, this.cleanUpFilter(filter));
  }

  getAgeStatistics(partnerId, filter?) {
    return this.api.get(`/partners/${partnerId}/age-statistics`, this.cleanUpFilter(filter));
  }

  getStudentsForPartnerWithAssessmentAndEngagementData(partnerId, filter) {
    return this.api
      .get(
        `/partners/${partnerId}/students-with-assessment-and-engagement-data`,
        this.cleanUpFilter(filter)
      )
      .pipe(map(res => res));
  }

  getMostCommonAces(partnerId, filter) {
    return this.api
      .get(
        `/partners/${partnerId}/students/reports/ace/most-common`,
        this.cleanUpFilter(filter)
      )
      .pipe(map(res => res));
  }

  getFrequencyOfPtsdSymptoms(partnerId, filter) {
    return this.api
      .get(
        `/partners/${partnerId}/students/reports/ptsd/frequencies`,
        this.cleanUpFilter(filter)
      )
      .pipe(map(res => res));
  }

  getFrequenciesOfRsq(partnerId, filter) {
    return this.api
      .get(
        `/partners/${partnerId}/students/reports/rsq/frequencies`,
        this.cleanUpFilter(filter)
      )
      .pipe(map(res => res));
  }

  getFrequenciesOfHighAndLowEpoch(partnerId, filter) {
    return this.api
      .get(
        `/partners/${partnerId}/students/reports/epoch/highest-and-lowest`,
        this.cleanUpFilter(filter)
      )
      .pipe(map(res => res));
  }

  runMonthlyReportForBilling() {
    return this.api.post('/partners/run-billing-report');
  }
}
