import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RiskAlertService } from 'app/core/services/risk-alert.service';
import { AppService } from 'app/core/services/app.service';
import { NotificationService } from 'app/core/services/notification.service';

@Component({
  selector: 'app-risk-alert-dialog',
  templateUrl: 'risk-alert-dialog.html',
})
export class RiskAlertDialogComponent {
  riskAlert = null;
  actionPlan: any = {};
  followedUp = false;

  isSavingActionPlan = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private app: AppService,
    private riskAlertService: RiskAlertService,
    public dialogRef: MatDialogRef<RiskAlertDialogComponent>,
    private notification: NotificationService
  ) {
    this.riskAlert = data.riskAlert;

    this.actionPlan = this.findUserActionPlan(this.riskAlert.actionPlans) || this.actionPlan;
    if (this.actionPlan.followedUpAt) {
      this.followedUp = true;
    }
  }

  findUserActionPlan(actionPlans) {
    const idx = actionPlans.findIndex(ac => {
      return ac._partnerAccount === this.app.currentUser._id;
    });

    return actionPlans[idx];
  }

  onFollowedUpChange(ev) {
    this.actionPlan.followedUpAt = ev ? new Date() : null;
  }

  saveActionPlan() {
    this.isSavingActionPlan = true;

    this.riskAlertService.setActionPlan(this.riskAlert._id, this.actionPlan)
      .subscribe((res) => {
        this.isSavingActionPlan = false;
        this.dialogRef.close(true);
      }, err => {
        this.isSavingActionPlan = false;
        this.notification.alertError(err);
      });
  }
}
