import {
  Component,
  AfterContentInit,
  EventEmitter,
  Output,
  ContentChild,
  ElementRef  } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

@Component({
  selector: 'ng-password-validate',
  templateUrl: './password-validate.component.html',
  styleUrls: ['./password-validate.component.scss']
})
export class PasswordValidateComponent implements AfterContentInit {

  password = '';
  repeatPassword = '';

  passwordHelpTexts = [];
  isPasswordStrong = false;

  @ContentChild('password', { read: null, static: false }) passwordElement: ElementRef;
  @ContentChild('repeatPassword', { read: null, static: false }) repeatPasswordElement: ElementRef;

  @Output()
  onChange = new EventEmitter();

  @Output()
  isValid = new EventEmitter();

  constructor() { }

  ngAfterContentInit() {
    this.passwordElement.nativeElement.addEventListener('keyup', this.onPasswordChange);

    if (this.repeatPasswordElement) {
      this.repeatPasswordElement.nativeElement.addEventListener('keyup', this.onRepeatPasswordChange);
    }
  }

  checkPassword() {
    const password = this.password;

    const sixCharCheck = /(?=.{6,})/.test(password);
    const numberCheck = /(?=.*[0-9])/.test(password);
    const upCaseCheck = /(?=.*[A-Z])/.test(password);
    const lowCaseCheck = /(?=.*[a-z])/.test(password);

    const passwordHelpTexts = [];

    if (!sixCharCheck) {
      passwordHelpTexts.push('Password must be at least six (6) characters');
    }
    if (!numberCheck) {
      passwordHelpTexts.push('Password must contain at least one number (numeric value)');
    }
    if (!upCaseCheck) {
      passwordHelpTexts.push('Password must contain at least one upper case letter');
    }
    if (!lowCaseCheck) {
      passwordHelpTexts.push('Password must contain at least one lower case letter');
    }

    // Run Repeat Password Check if a repeatPasswordElement was passed in
    if (this.repeatPasswordElement) {
      const repeatPassword = this.repeatPassword;

      if (password !== repeatPassword) {
        passwordHelpTexts.push('Your repeated password does not match');
      }
    }

    this.passwordHelpTexts = passwordHelpTexts;

    this.onChange.emit(password);
    this.isValid.emit(passwordHelpTexts.length === 0);

    // Check if new password is strong
    if (sixCharCheck && numberCheck && upCaseCheck && lowCaseCheck) {
      this.isPasswordStrong = true;
    } else {
      this.isPasswordStrong = false;
    }
  }

  onPasswordChange = e => {
    this.password = e.target.value;
    this.checkPassword();
  }

  onRepeatPasswordChange = e => {
    this.repeatPassword = e.target.value;
    this.checkPassword();
  }
}
