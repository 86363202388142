  <mat-card [class.mat-elevation-z0]="disableShadow">
    <mat-card-content fxLayout="row wrap" fxLayoutGap="10px">
      <mat-form-field fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>Gender</mat-label>
        <mat-select
          placeholder="Gender"
          [(value)]="filter.gender"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option *ngFor="let gender of filterOptions.gender$ | async" [value]="gender.value">{{gender.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="partner?.name === 'RWJBH'" fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>RWJBH Location</mat-label>
        <mat-select
          placeholder="RWJBH Location"
          [(value)]="filter.partnerLocation"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option *ngFor="let RWJBHLocation of RWJBHLocations" [value]="RWJBHLocation.value">{{RWJBHLocation.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>Orientation</mat-label>
        <mat-select
          placeholder="Orientation"
          [(ngModel)]="filter.orientation"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option *ngFor="let orientation of filterOptions.orientation$ | async" [value]="orientation.value">{{orientation.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>Race/Ethnicity</mat-label>
        <mat-select
          placeholder="Ethnicity"
          [(value)]="filter.ethnicity"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option *ngFor="let race of filterOptions.race$ | async" [value]="race.value">{{race.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>City</mat-label>
        <mat-select
          placeholder="City"
          [(ngModel)]="filter.city"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option *ngFor="let city of filterOptions.city$ | async" [value]="city">{{city}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>ACE Score</mat-label>
        <mat-select
          placeholder="ACE Score"
          [(value)]="filter.aceScoreRange"
          >
          <mat-option value="">--All--</mat-option>
          <mat-option value="0-2">0-2</mat-option>
          <mat-option value="3-5">3-5</mat-option>
          <mat-option value="6-8">6-8</mat-option>
          <mat-option value="9">9+</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="enablePartnerDropDown">
        <mat-label>Partners</mat-label>
        <mat-select [(value)]="filter.partners"  multiple>
          <mat-option *ngFor="let partner of filterOptions?.partners$" [value]="partner?.value?._id">{{partner.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="1 0 calc(16.6% - 10px)" appearance="fill">
        <mat-label>Age</mat-label>
        <mat-select
          placeholder="Age"
          [(value)]="filter.age"
          >
          <mat-option value="">--All--</mat-option>
          <mat-option value="13">13</mat-option>
          <mat-option value="14">14</mat-option>
          <mat-option value="15">15</mat-option>
          <mat-option value="16">16</mat-option>
          <mat-option value="17">17</mat-option>
          <mat-option value="18">18</mat-option>
          <mat-option value="19">19</mat-option>
          <mat-option value="20">20</mat-option>
          <mat-option value="21">21</mat-option>
          <mat-option value="22">22</mat-option>
          <mat-option value="23">23</mat-option>
          <mat-option value="24">24</mat-option>
          <mat-option value="25">25+</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field fxFlex="1 0 calc(25% - 10px)" appearance="fill">
        <mat-label>PTSD Score</mat-label>
        <mat-select
          placeholder="PTSD Score"
          [(value)]="filter.ptsdScoreRange"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option value="0">0</mat-option>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 calc(25% - 10px)" appearance="fill">
        <mat-label>RSQ Score</mat-label>
        <mat-select
          placeholder="RSQ Score"
          [(value)]="filter.rsqScoreRange"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option value="0-25">0-25%</mat-option>
          <mat-option value="26-50">26%-50%</mat-option>
          <mat-option value="51-75">51%-75%</mat-option>
          <mat-option value="76-100">76%-100%</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 calc(25% - 10px)" appearance="fill">
        <mat-label>EPOCH Score</mat-label>
        <mat-select
          placeholder="EPOCH Score"
          [(value)]="filter.epochScoreRange"
        >
          <mat-option value="">--All--</mat-option>
          <mat-option value="0-25">0-25%</mat-option>
          <mat-option value="26-50">26%-50%</mat-option>
          <mat-option value="51-75">51%-75%</mat-option>
          <mat-option value="76-100">76%-100%</mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field fxFlex="1 0 calc(25% - 10px)" appearance="fill">
        <mat-label>Pick Start Date</mat-label>
        <input matInput [max]="startMaxDate" (ngModelChange)="onDateInputChange('startDate', $event)" [ngModel]="filter.startDate" [matDatepicker]="startDatePicker" placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="1 0 calc(25% - 10px)" appearance="fill">
        <mat-label>Pick End Date</mat-label>
        <input matInput [min]="endMinDate" (ngModelChange)="onDateInputChange('endDate', $event)" [ngModel]="filter.endDate" [matDatepicker]="endDatePicker" placeholder="Choose an end date">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
      <span></span> -->
    </mat-card-content>

    <div id="separator"></div>

    <mat-card-actions align="end">
      <div fxLayout="row wrap" fxFlex="0 1 auto" fxLayoutGap="15px" fxLayoutAlign="start">
        <!-- <button mat-flat-button class="green" (click)="onClickLiveTimeFilter()">Live Time</button>
        <button mat-flat-button color="primary" (click)="onClickAllTimeFilter()">All Time</button> -->
        <mat-form-field appearance="fill">
          <mat-label>Show</mat-label>
          <mat-select
            [(value)]="filter.show"
            (selectionChange)="onSelectShowFilter()"
          >
            <mat-option value="live-time" >Live Time</mat-option>
            <mat-option value="all-time" >All Time</mat-option>
            <mat-option value="custom-range" >Custom Range</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="filter.show === 'custom-range'">
          <mat-label>From</mat-label>
          <input matInput [max]="startMaxDate" (ngModelChange)="onDateInputChange('startDate', $event)" [ngModel]="filter.startDate" [matDatepicker]="startDatePicker" placeholder="Choose a start date">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="filter.show === 'custom-range'">
          <mat-label>To</mat-label>
          <input matInput [min]="endMinDate" (ngModelChange)="onDateInputChange('endDate', $event)" [ngModel]="filter.endDate" [matDatepicker]="endDatePicker" placeholder="Choose an end date">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <!-- <button mat-flat-button color="primary" [disabled]="dateHelperButtonState['1d']" (click)="onDateHelperClick('1d')">24 Hours</button>
        <button mat-flat-button color="primary" [disabled]="dateHelperButtonState['7d']" (click)="onDateHelperClick('7d')">7 Days</button>
        <button mat-flat-button color="primary" [disabled]="dateHelperButtonState['start of school year']" (click)="onDateHelperClick('start of school year')">Start Of School Year</button> -->
      </div>
      <div fxFlex="1 1 auto">

        <button
          mat-flat-button
          color="primary"
          (click)="broadcastUpdate()"
          >Apply Filter</button>
        <button
          mat-flat-button
          (click)="clearFilter()"
          color="warn"
          >Clear Filter</button>
      </div>
    </mat-card-actions>
  </mat-card>
