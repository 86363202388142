import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AssessmentDescriptionComponent } from 'app/components/assessment-description/assessment-description.component';
import { PageNotFoundComponent } from 'app/components/page-not-found/page-not-found.component';
import { CheckUserGuard } from './core/guards/check-user.guard';

const appRoutes: Routes = [
  { path: '', loadChildren: () => import('app/modules/mindright-staff/mindright-staff.module').then(m => m.MindRightStaffModule) },
  {
    path: 'partners/old',
    canActivate: [CheckUserGuard],
    loadChildren: () => import('app/modules/old-partner-dashboard/old-partner-dashboard.module').then(m => m.OldPartnerDashboardModule),
    data: { rolesAllowed: ['SuperAdmin', 'Admin', 'Analyst'] },
  },
  {
    path: 'partners/new',
    canActivate: [CheckUserGuard],
    loadChildren: () => import('app/modules/partner-dashboard/partner-dashboard.module').then(m => m.PartnerDashboardModule),
    data: { rolesAllowed: ['SuperAdmin', 'Admin', 'Supervisor', 'PartnerAccount', 'Analyst'] },
  },
  { path: '', loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'assessment-description', component: AssessmentDescriptionComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    preloadingStrategy: QuicklinkStrategy,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}