import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { CoachService } from 'app/core/services/coach.service';
import { StudentService } from 'app/core/services/student.service';
import { NotificationService } from 'app/core/services/notification.service';
import { MindRightService } from 'app/core/services/mindright.service';
import { AuthService } from 'app/core/services/auth.service';
import * as _ from 'lodash';
import { PartnerService } from 'app/core/services/partner.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'app/core/services/api.service';

export interface DialogData {
  surveyName: '';
}
@Component({
  selector: 'survey-confirmation-dialog',
  templateUrl: 'survey-confirmation-dialog.html',
})
export class SurveyConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  host: { class: 'component-container' },
  providers: [
    AdminSettingsService,
    CoachService,
    StudentService,
    NotificationService,
    PartnerService,
  ]
})
export class ConfigurationComponent implements OnInit {

  timeSlotModal;
  assignStudentModal;

  @ViewChild('timeSlotModal', { read: null, static: false }) timeSlotModalContent;
  @ViewChild('assignStudentModal', { read: null, static: false }) assignStudentModalContent;

  coaches = [];
  students = [];
  filteredMembers = []

  memberFilterText = ''

  timeSlots;

  editSlot: any = {};
  editCoach: any = { _data: {} };

  runAssignmentsLoading = false;
  clearAssignmentsLoading = false;
  monthlyReportForBillingLoading = false;
  NPSSurveyLoading = false;
  SWEMWBSSurveyLoading = false;
  negativeSentimentLoading = false;
  transitionCoachStatusLoading = false;
  wellnessDataUploadLoading = false
  wellnessDataFollowUpsLoading = false

  allLoading = true
  currentLoadingItem = ''
  loadingProgress: number = 0

  constructor(
    private api: ApiService,
    private coachService: CoachService,
    private studentService: StudentService,
    private adminService: AdminSettingsService,
    public authService: AuthService,
    private notificationService: NotificationService,
    private mindRightService: MindRightService,
    private modalService: NgbModal,
    private partnerService: PartnerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loadData()
  }

  async loadData() {
    try {
      this.loadingProgress = 10
      // Get Timeslots
      this.adminService.getTimeSlots()
        .subscribe(slots => this.timeSlots = slots);

      this.loadingProgress = 50

      // Load coaches
      const coachRequest: any = await this.coachService.getAllCoaches(null, 0).toPromise()
      this.coaches = coachRequest.data.filter(x => x.type=="Coach") || [];

      this.loadingProgress = Math.floor(Math.random() * 31) + 60;

      // Load Members
      const allMembers = await this.loadMembers()
      this.students = allMembers.filter(s => {
        if (!s) return false
        return s.firstName && s.lastName;
      });

      this.filteredMembers = [...this.students]
      this.loadingProgress = 100

      this.allLoading = false
      this.currentLoadingItem = ''
    } catch (error) {
      console.error(error)
    }
  }

  async loadMembers() {
    try {
      const res: any = await this.api.get('/students?basic=true&ensureName=true').toPromise()
      if ('members' in res.data) {
        return res.data.members
      }
    } catch (error) {
      console.error(error)
      return []
    }
  }

  openSlotModal(slot) {
    this.timeSlotModal = this.modalService.open(this.timeSlotModalContent);

    this.editSlot = slot;
  }

  saveSlot(slot) {
    this.adminService.saveTimeSlot(slot)
      .subscribe(() => {
        this.timeSlotModal.close();
        this.notificationService.toastr.info('Saved slot information');
      });
  }

  openAssignModal(coach) {
    this.assignStudentModal = this.modalService.open(this.assignStudentModalContent);

    coach._data = coach._data || {};

    // Ensure students already assigned to coaches are still active
    coach._data._students = coach._data._students.filter(studentId =>
      _.find(this.students, { _id: studentId })
    );

    this.editCoach = coach;
  }

  saveAssignation(coach) {
    this.adminService.saveAssignation(coach._id, coach._data._students)
      .subscribe(res => {
        this.assignStudentModal.close();
        const index = this.coaches
          .findIndex(user => user['_id'] === coach._id);
        this.coaches[index]._data._students = coach._data._students;
        this.notificationService.toastr.info('Students assigned!');
      }, this.notificationService.alertError);
  }

  runAssignments() {
    this.runAssignmentsLoading = true;
    this.coachService.runMemberAssignments()
      .subscribe((res: any) => {
        const data = res.data;
        this.notificationService.toastr
          .info(data ? data.message : 'Assignments were made successfully');
      }, err => {
        this.notificationService.toastr
          .error('Assignments could not complete');
      }).add(() => this.runAssignmentsLoading = false);
  }

  clearAssignments() {
    this.clearAssignmentsLoading = true;
    this.coachService.clearMemberAssignments()
      .subscribe((res: any) => {
        const data = res.data;
        this.notificationService.toastr
          .info(data ? data.message : 'Assignments were cleared successfully');
      }, () => {
        this.notificationService.alertError('Could not clear assignments');
      }).add(() => this.clearAssignmentsLoading = false);
  }

  runMonthlyReportForBilling() {
    this.monthlyReportForBillingLoading = true;
    this.partnerService.runMonthlyReportForBilling()
      .subscribe((res: any) => {
        const data = res.data;
        this.notificationService.toastr
          .info(data ? data.message : 'Report Generated Successfully');
      }, () => {
        this.notificationService.alertError('Could not generate Report');
      }).add(() => this.monthlyReportForBillingLoading = false);
  }

  openNPSDialog() {
    const dialogRef = this.dialog.open(SurveyConfirmationDialogComponent, {
      data: {
        surveyName: 'NPS Survey'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.runNPSSurveyAdministration();
      }
    });
  }

  openSWEMWBSDialog() {
    const dialogRef = this.dialog.open(SurveyConfirmationDialogComponent, {
      data: {
        surveyName: 'SWEMWBS + SIAOS Survey'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.runSWEMWBSSurveyAdministration();
      }
    });
  }

  openWellnessUploadDialog() {
    const dialogRef = this.dialog.open(SurveyConfirmationDialogComponent, {
      data: {
        surveyName: 'WELLNESS'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.runWellnessDataUpload();
      }
    });
  }

  openWellnessFollowUpsDialog() {
    const dialogRef = this.dialog.open(SurveyConfirmationDialogComponent, {
      data: {
        surveyName: 'WELLNESS_FOLLOW_UPS'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.runWellnessDataFollowUps();
      }
    });
  }

  runNPSSurveyAdministration() {
    try {
      this.NPSSurveyLoading = true;
      this.coachService.runNPSSurveyAdministration()
        .subscribe((res: any) => {
          const data = res.data;
          this.notificationService.toastr
            .info(data ? data.message : 'Operation was successful');
        }, err => {
          this.notificationService.toastr
            .error(err ? err.message : 'Operation Failed');
        }).add(() => this.NPSSurveyLoading = false);
    } catch (error) {
      console.error(error);
      this.NPSSurveyLoading = false;
    }
  }

  runSWEMWBSSurveyAdministration() {
    try {
      this.SWEMWBSSurveyLoading = true;
      this.coachService.runSWEMWBSSurveyAdministration()
        .subscribe((res: any) => {
          const data = res.data;
          this.notificationService.toastr
            .info(data ? data.message : 'Operation was successful');
        }, err => {
          this.notificationService.toastr
            .error(err ? err.message : 'Operation Failed');
        }).add(() => this.SWEMWBSSurveyLoading = false);
    } catch (error) {
      console.error(error);
      this.SWEMWBSSurveyLoading = false;
    }
  }

  runNegativeSentiment() {
    this.negativeSentimentLoading = true;
    this.studentService.runProlongedNegativeSentiment()
      .subscribe((res: any) => {
        const data = res.data;
        this.notificationService.toastr
          .info(data ? data.message : 'Prolonged negative sentiment calculated for all members successfully');
      }, err => {
        this.notificationService.toastr
          .error('Failed to compute prolonged negative sentiment for all members.');
      }).add(() => this.negativeSentimentLoading = false);
  }

  runCoachStatusTransition() {
    this.transitionCoachStatusLoading = true;
    this.coachService.runCoachStatusTransitions()
      .subscribe((res: any) => {
        this.notificationService.toastr
          .info(res.message ? res.message : 'Successful');
      }, err => {
        this.notificationService.toastr
          .error('Something went wrong.');
      }).add(() => this.transitionCoachStatusLoading = false);
  }

  runWellnessDataUpload() {
    this.wellnessDataUploadLoading = true;
    this.coachService.runWellnessDataUpload()
      .subscribe((res: any) => {
        this.notificationService.toastr
          .info(res.message ? res.message : 'Successful');
      }, err => {
        this.notificationService.toastr
          .error('Something went wrong.');
      }).add(() => this.wellnessDataUploadLoading = false);
  }

  runWellnessDataFollowUps() {
    this.wellnessDataFollowUpsLoading = true;
    this.coachService.runWellnessDataFollowUps()
      .subscribe((res: any) => {
        this.notificationService.toastr
          .info(res.message ? res.message : 'Successful');
      }, err => {
        this.notificationService.toastr
          .error('Something went wrong.');
      }).add(() => this.wellnessDataFollowUpsLoading = false);
  }

  onKeyFilter(value) {
    if (!value) this.memberFilterText = ''
    this.filteredMembers = this.filterMembers(value)
  }

  filterMembers(value: string) {
    const filter = value.toLowerCase()
    return this.students.filter(member => {
      return member.firstName.toLowerCase().startsWith(filter) ||
        member.lastName.toLowerCase().startsWith(filter) || this.editCoach._data._students.includes(member._id)
    })
  }
}
