import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanLoad } from '@angular/router';

import { of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { AppService } from 'app/core/services/app.service';
import { AuthService } from 'app/core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CheckUserGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private app: AppService,
    private auth: AuthService
  ) {}

  redirect(attemptedUrl?) {
    attemptedUrl && (this.app.attemptedUrl = attemptedUrl);
    this.router.navigate([this.auth.ROUTES.LOGIN]);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.app.onShowSplashLoading$.next(true);

    // The array of roles allowed from the route data property
    let allowedRoles: Array<string> = route.data.rolesAllowed;

    return this.auth.getCurrentUser().pipe(map(user => {
      this.app.onShowSplashLoading$.next(false);

      let idx = allowedRoles.indexOf(user._role.title);
      if (idx >= 0) {
        return true;
      } else {
        this.redirect();
      }
    }), catchError(() => {
      this.redirect(state.url);
      return of(false);
    }), finalize(() => {
      this.app.onShowSplashLoading$.next(false);
    }));
  }

  canLoad() {
    this.app.onShowSplashLoading$.next(true);

    return this.auth.isLoggedIn().pipe(map(isAuthed => {
      this.app.onShowSplashLoading$.next(false);
      return isAuthed;
    }));
  }
}
