import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';
import { setAppLoading, setAppLoadingMessage, setAppLoadingPercentage, setStatusOptions } from 'app/redux/actions/app.action';
import { ApiService } from './api.service';

export interface AppLoading {
  percentage: number,
  message: string,
  isLoading: boolean,
}

@Injectable()
export class ApplicationService {

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private api: ApiService,
  ) {

    // Load all initial app resources
    this.loadAppResources()
  }

  async loadAppResources() {
    try {
      await this.loadStatusOptions()
    } catch (error) {
      console.error(error)
    }
  }

  updateAppLoading(appLoading: AppLoading) {
    this.ngRedux.dispatch(setAppLoadingPercentage(appLoading.percentage))
    this.ngRedux.dispatch(setAppLoadingMessage(appLoading.message))
    this.ngRedux.dispatch(setAppLoading(appLoading.isLoading))
  }

  async loadStatusOptions() {
    try {
      const options = await this.api.get('/statuses/options').toPromise()
      if (!Array.isArray(options)) throw options

      this.ngRedux.dispatch(setStatusOptions(options))
    } catch (error) {
      console.error(error)
    }
  }
}
