
import { of as observableOf } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CannedMessagesService {

  private cannedMessages;
  private searchableMessages;

  constructor(private apiService: ApiService) {
    this.getCannedMessages().subscribe(messages => {
      this.cannedMessages = messages;
    });
  }

  getCategories() {
    return this.getCannedMessages().pipe(
      map(messages => Object.keys(messages)));
  }

  getCannedMessages() {
    if (this.cannedMessages) {
      return observableOf(this.cannedMessages);
    }

    return this.apiService.get('/precanned-messages').pipe(
      map((res: any) => {
        this.cannedMessages = res;
        return res;
      }));
  }

  getCannedMessagesForSearch() {
    if (this.searchableMessages) {
      return this.searchableMessages;
    } else {
      this.searchableMessages = [];

      for (let category in this.cannedMessages) {
        if (this.cannedMessages.hasOwnProperty(category)) {
          this.cannedMessages[category].forEach(message => {
            this.searchableMessages.push({ title: message });
          });
        }
      }
      return this.searchableMessages;
    }
  }
}
