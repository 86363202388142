import * as moment from 'moment-timezone';

import { Component, OnInit, Input, OnChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import _ from 'lodash';

import { RiskAlertDialogComponent } from './../../modals/risk-alert-dialog/risk-alert-dialog.component';
import { RiskAlertDetailsDialogComponent } from './../../modals/risk-alert-details-dialog/risk-alert-details-dialog.component';

import { AppService } from 'app/core/services/app.service';
import { RiskAlertService } from 'app/core/services/risk-alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'app/core/services/notification.service';

@Component({
  selector: 'app-risk-alerts-table',
  templateUrl: './risk-alerts-table.component.html',
  styleUrls: ['./risk-alerts-table.component.scss']
})
export class RiskAlertsTableComponent implements OnInit, OnChanges {

  public moment = moment;

  @Input() riskAlerts;
  @Input() showReporter = false;

  // Parent Components can refetch riskAlerts on dialog close
  @Output() onDialogClose = new EventEmitter();

  riskAlertStatusPercentages = {
    'Done': '0%',
    'Needs plan or follow up': '0%',
    'Needs check in': '0%'
  };

  partnerId = null;
  dialogRef: MatDialogRef<RiskAlertDialogComponent>;
  detailsDialogRef: MatDialogRef<RiskAlertDetailsDialogComponent>;

  editingRiskAlert: any = { };
  deletingRiskAlert: any = { };

  editRiskAlertModal;
  deleteRiskAlertModal;

  @ViewChild('editRiskAlertModal', { read: null, static: false }) editRiskAlertModalContent;
  @ViewChild('deleteRiskAlertModal', { read: null, static: false }) deleteRiskAlertModalContent;

  constructor(
    private app: AppService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private notification: NotificationService,
    private riskAlertService: RiskAlertService
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    this.computeRiskAlerts();
  }

  afterClose = result => {
    this.riskAlertService.getRiskAlerts().subscribe(riskAlerts => {
      this.riskAlerts = riskAlerts;
      this.computeRiskAlerts();
    });

    if (result) {
      this.onDialogClose.emit();
    }
  }

  viewRiskAlert(riskAlert) {
    if (this.app.currentUser.type === 'Admin' || this.app.currentUser.type === 'PartnerAccount') {
      this.dialogRef = this.dialog.open(
        RiskAlertDialogComponent,
        { data: { riskAlert: _.cloneDeep(riskAlert) } }
      );
      this.dialogRef.afterClosed().subscribe(this.afterClose);
    }
  }

  computeRiskAlerts() {
    if (!this.riskAlerts) {
      return ;
    }
    const ra = this.riskAlerts;

    let statusCounts = {
      'Done': 0,
      'Needs plan or follow up': 0,
      'Needs check in': 0
    };

    this.riskAlerts.forEach(riskAlert => {
      statusCounts[this.getRiskAlertStatus(riskAlert).message] += 1;
    });

    const getPercent = status => {
      const percent = (statusCounts[status] / ra.length) * 100;
      return Number.isInteger(percent) ? `${percent}%` : '0%';
    };

    this.riskAlertStatusPercentages['Done'] = getPercent('Done');
    this.riskAlertStatusPercentages['Needs plan or follow up'] = getPercent('Needs plan or follow up');
    this.riskAlertStatusPercentages['Needs check in'] = getPercent('Needs check in');
  }

  getRiskAlertStatus(riskAlert) {
    let actionPlans = riskAlert.actionPlans;

    if (actionPlans.length) {
      let followUpDates = actionPlans.map(ap => ap.followedUpAt);

      // Check that at least one of the action plans has been followed up
      if (_.some(followUpDates)) {
        return { message: 'Done', color: 'success' };
      } else {
        return  { message: 'Needs plan or follow up', color: 'warning' };
      }
    } else {
      return { message: 'Needs check in', color: 'danger' };
    }
  }

  openEditModal(riskAlert) {
    this.editingRiskAlert = Object.assign({}, riskAlert);
    this.editRiskAlertModal = this.modalService.open(this.editRiskAlertModalContent);
  }

  openDeleteModal(riskAlert) {
    this.deletingRiskAlert = riskAlert;
    this.deleteRiskAlertModal = this.modalService.open(this.deleteRiskAlertModalContent);
  }

  editRiskAlert(riskAlert) {
    this.riskAlertService.updateRiskAlert(riskAlert._id, riskAlert).subscribe(updatedRiskAlert => {
      this.editRiskAlertModal.close();

      const index =
          this.riskAlerts.findIndex(rAlert => rAlert['_id'] === updatedRiskAlert['_id']);

      const riskAlerts = [...this.riskAlerts];
      riskAlerts[index] = updatedRiskAlert;

      this.riskAlerts = riskAlerts;
    }, err => this.notification.alertError(err));
  }

  deleteRiskAlert(riskAlert) {
    const deleteId =  riskAlert._id;

    this.riskAlertService.deleteRiskAlert(deleteId).subscribe(deletedRiskAlert => {
      this.deleteRiskAlertModal.close();

      const ind = this.riskAlerts.findIndex(rAlert => rAlert._id === deleteId);

      const riskAlerts = [...this.riskAlerts];

      if (ind > -1) {
        riskAlerts.splice(ind, 1);
      }

      this.riskAlerts = riskAlerts;
    }, err => this.notification.alertError(err));
  }
}
