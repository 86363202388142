import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'displayUserName', pure: true })
export class DisplayUserNamePipe implements PipeTransform {

  constructor() { }
  getUserName(user: any) {
    if (user.firstName && user.firstName.trim()) {
      const name = `${user.firstName} ${user.lastName}`;
      return name.trim();
    }
  }

  transform(user: any, args: string[]): any {
    if (user) {
      return this.getUserName(user) || 'Unnamed User';
    } else {
      return 'Unnamed User';
    }
  }
}
