<mat-toolbar >
    <span *ngIf="!loading">All Potential Crisis Text</span>
    <span *ngIf="loading">Loading..</span>
        <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button class="example-icon favorite-icon" (click)="closeCrisisDialog()">
      <mat-icon>close</mat-icon>
    </button>
   </mat-toolbar>
   <mat-progress-bar *ngIf="loading" mode="buffer"></mat-progress-bar>
   <mat-dialog-content>
    <h2 style="text-align: center; margin: 16px 0;">There {{crisis.length > 1 ? 'are' : 'is a'}} {{crisis.length}} potential crisis text{{crisis.length > 1 ? 's' : ''}} that you should take a look at</h2>
    <p style="margin-bottom: 16px"><i>Select all that apply as crisis texts in the list below and click on CONFIRM CRISIS to continue.</i></p>
    <mat-selection-list #crisisMessages style="margin-bottom: 24px;" [(ngModel)]="selectedItems">
        <mat-list-option matLine *ngFor="let item of crisis" [value]="item">
          {{item.message.length > 97 ? item.message.substring(0, 97) + '...' : item.message}}
        </mat-list-option>
    </mat-selection-list>
    </mat-dialog-content>
   <mat-dialog-actions align="end">
     <button class="btn-success" [disabled]="loading" mat-button (click)="declinePotentialCrisisMessages()" cdkFocusInitial>Decline Crisis</button>
     <button class="btn-danger" [disabled]="loading" mat-button (click)="savePotentialCrisisMessages()">CONFIRM CRISIS</button>
   </mat-dialog-actions>
   