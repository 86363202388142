<div style="width: 100%; margin-bottom: 16px;">
    <mat-form-field appearance="fill" style="width: 100%;" *ngIf="showName">
      <mat-label>Search Lead By Name</mat-label>
      <input matInput type="text" [(ngModel)]="leadFilter.name">
      <button *ngIf="leadFilter.name" matSuffix mat-icon-button aria-label="Clear" (click)="leadFilter.name=''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint align="start">
        <strong>
          <i>To search for both first and last names, use a space to separate the 2 names, otherwise this would search
            for first name only.</i>
        </strong>
      </mat-hint>
    </mat-form-field>
  </div>
  
  <mat-card [class.mat-elevation-z0]="disableShadow" style="margin-top: 40px;">
    <mat-card-content fxLayout="row wrap" fxLayoutGap="10px">

      <mat-form-field appearance="fill" *ngIf="showCity">
        <mat-label>City</mat-label>
        <mat-select #cityFilter multiple [(value)]="leadFilter.city" placeholder="City">
          <mat-option (click)="toggleAllSelection('city')">-- ALL --</mat-option>
          <mat-option *ngFor="let option of filterOptions.city$ | async" [value]="option.value">{{option.label}}
          </mat-option>
          <mat-option  [value]="'0'">No Data</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showState">
        <mat-label>State</mat-label>
        <mat-select #stateFilter multiple [(value)]="leadFilter.state" placeholder="State">
          <mat-option (click)="toggleAllSelection('state')">-- ALL --</mat-option>
          <mat-option *ngFor="let option of filterOptions.state$ | async" [value]="option.value">{{option.label}}
          </mat-option>
          <mat-option  [value]="'0'">No Data</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showZipCode">
        <mat-label>Zip Code</mat-label>
        <mat-select #zipCodeFilter multiple [(value)]="leadFilter.zipCode" placeholder="Zip Code">
          <mat-option (click)="toggleAllSelection('zipCode')">-- ALL --</mat-option>
          <mat-option *ngFor="let option of filterOptions.zipCode$ | async" [value]="option.value">{{option.label}}
          </mat-option>
          <mat-option  [value]="'0'">No Data</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showGender">
        <mat-label>Gender</mat-label>
        <mat-select #genderFilter multiple [(value)]="leadFilter.gender" placeholder="Gender">
          <mat-option (click)="toggleAllSelection('gender')">-- ALL --</mat-option>
          <mat-option *ngFor="let option of filterOptions.gender$ | async" [value]="option.value">{{option.label}}
          </mat-option>
          <mat-option  [value]="'0'">No Data</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showMrStatus">
        <mat-label>MR Status</mat-label>
        <mat-select #mrStatusFilter multiple [(value)]="leadFilter.mrStatus" placeholder="Status">
          <mat-option (click)="toggleAllSelection('mrStatus')">-- ALL --</mat-option>
          <mat-option *ngFor="let option of filterOptions.mrStatus" [value]="option.value">{{option.label}}
          </mat-option>
          <mat-option [value]="'0'">No Data</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="showPartner">
        <mat-label>Partner</mat-label>
        <mat-select #partnerFilter multiple [(value)]="leadFilter.partner" placeholder="Partner">
          <mat-option (click)="toggleAllSelection('partner')">-- ALL --</mat-option>
          <mat-option *ngFor="let option of filterOptions.partner$ | async" [value]="option.value">{{option.label}}
          </mat-option>
          <mat-option [value]="'0'">No Data</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card-content>
  
    <mat-card-actions>  
      <div *ngIf="showDateRange">
        <div fxLayout="row wrap" style="justify-content: left;" fxLayoutGap="15px">
          <mat-form-field appearance="fill">
            <mat-label>Show</mat-label>
            <mat-select [(value)]="filterOptions.show" (selectionChange)="onSelectShowFilter()">
              <mat-option value="all-time">All Time</mat-option>
              <mat-option value="custom-range">Custom Range</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" style="justify-content: left;" fxLayoutGap="15px">
          <mat-form-field appearance="fill" *ngIf="filterOptions.show === 'custom-range'">
            <mat-label>From</mat-label>
            <input matInput [max]="startMaxDate" (ngModelChange)="onDateInputChange('startDate', $event)"
              [ngModel]="filterOptions.startDate" [matDatepicker]="startDatePicker" placeholder="Choose a start date">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
    
          <mat-form-field appearance="fill" *ngIf="filterOptions.show === 'custom-range'">
            <mat-label>To</mat-label>
            <input matInput [min]="endMinDate" (ngModelChange)="onDateInputChange('endDate', $event)"
              [ngModel]="filterOptions.endDate" [matDatepicker]="endDatePicker" placeholder="Choose an end date">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>  
        </div>
      </div>
      <div>
        <button mat-flat-button color="primary" style="margin-right: 10px;" (click)="broadcastUpdate()">Apply Filter</button>
        <button mat-flat-button (click)="clearFilter()" color="warn">Clear Filter</button>
      </div>
    </mat-card-actions>
  </mat-card>
  