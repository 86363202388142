<h3 mat-dialog-title>Risk Alert</h3>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="20px">
    <h5>{{riskAlert._student.firstName}} <span class="fs-exclude">{{riskAlert._student.lastName}}</span></h5>

    <h5>Cause: {{riskAlert.cause}}</h5>
    <p><i class="fa fa-clock me-2"></i>{{riskAlert.createdAt | momentFormatDate }}</p>

    <div fxLayout="column">
      <span fxFlex>Is this alert helpful?</span>
      <div fxFlex="1">
        <mat-button-toggle-group appearance="legacy" [(ngModel)]="actionPlan.isAlertHelpful" name="rate">
          <mat-button-toggle [value]="1">Not at all helpful</mat-button-toggle>
          <mat-button-toggle [value]="2">Not helpful</mat-button-toggle>
          <mat-button-toggle [value]="3">Indifferent</mat-button-toggle>
          <mat-button-toggle [value]="4">Helpful</mat-button-toggle>
          <mat-button-toggle [value]="5">Very helpful</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div fxLayout="column">
      <span fxFlex>I have the capacity to act on this alert</span>
      <div fxFlex="1">
        <mat-button-toggle-group appearance="legacy" [(ngModel)]="actionPlan.canActOnAlert" name="rate">
          <mat-button-toggle [value]="1">Strongly disagree</mat-button-toggle>
          <mat-button-toggle [value]="2">Disagree</mat-button-toggle>
          <mat-button-toggle [value]="3">Neither agree nor disagree</mat-button-toggle>
          <mat-button-toggle [value]="4">Agree</mat-button-toggle>
          <mat-button-toggle [value]="5">Strongly agree</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <p><mat-checkbox [ngModel]="followedUp" (ngModelChange)="onFollowedUpChange($event)">We checked in with the Member</mat-checkbox></p>

    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Who followed up</mat-label>
        <input [(ngModel)]="actionPlan.whoFollowedUp" matInput />
        <mat-icon matPrefix>person</mat-icon>
      </mat-form-field>
    </div>

    <div>
      <p>
        Follow-up plan for Member (“N/A” if none needed)
      </p>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Leave a comment</mat-label>
        <textarea [(ngModel)]="actionPlan.followUpPlan" matInput rows="6" style="resize: none;"></textarea>
        <mat-icon matSuffix>note_add</mat-icon>
        <i class="fa fa-note" matPrefix></i>
      </mat-form-field>
    </div>

  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button cdkFocusInitial [disabled]="isSavingActionPlan" (click)="saveActionPlan()">
    <div *ngIf="isSavingActionPlan">
      <span class="mr-spinner mr-spinner-white"></span>
    </div>
    <span *ngIf="!isSavingActionPlan">Save</span>
  </button>
</mat-dialog-actions>
