import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AdminSettingsService } from './admin-settings.service';
import { IAppState } from 'app/redux/store';
import { SELECTORS as AppSelectors } from 'app/redux/reducers/app.reducer';

@Injectable()
export class CoachPreferenceService {
  private coachSexualOrientationsSubject = new BehaviorSubject<any[]>([]);
  private coachRacesSubject = new BehaviorSubject<any[]>([]);
  private coachGenderSubject = new BehaviorSubject<any[]>([]);

  constructor(
    private adminService: AdminSettingsService,
    private ngRedux: NgRedux<IAppState>
  ) {}

  initializePreferences(profileSections?: any): void {
    const profileSectionValues = Object.values(profileSections).map(Boolean).filter(p => p);
    if (profileSectionValues.length) {
      this.coachGenderSubject.next(this.processData(profileSections.coachGenders));
      this.coachRacesSubject.next(this.processData(profileSections.coachRaces));
      this.coachSexualOrientationsSubject.next(this.processData(profileSections.coachSexualOrientations));
    } else {
      this.loadAllPreferences();
    }
  }

  get coachSexualOrientations$(): Observable<any[]> {
    return this.coachSexualOrientationsSubject.asObservable();
  }

  get coachRaces$(): Observable<any[]> {
    return this.coachRacesSubject.asObservable();
  }

  get coachGenderList$(): Observable<any[]> {
    return this.coachGenderSubject.asObservable();
  }

  private loadAllPreferences(): void {
    this.loadPreference(AppSelectors.coachGenderOptions, this.coachGenderSubject, () => this.adminService.getCoachGenderSelections());
    this.loadPreference(AppSelectors.coachRaceOptions, this.coachRacesSubject, () => this.adminService.getCoachRacesSelections());
    this.loadPreference(AppSelectors.coachOrientationOptions, this.coachSexualOrientationsSubject, () => this.adminService.getCoachSexualOrientationSelections());
  }

  private loadPreference(
    selector: any,
    subject: BehaviorSubject<any[]>,
    apiCall: () => Observable<any>
  ): void {
    this.ngRedux.select(selector).pipe(
      take(1),
      switchMap(data => {
        if (data && Object.keys(data).length > 0) {
          return of(this.processData(data));
        } else {
          return apiCall().pipe(
            map(response => {
              const selections = response.genderSelections || response.racesSelections || response.sexualOrientationSelections || {};
              return this.processData(selections);
            })
          );
        }
      }),
      tap({
        next: processedData => subject.next(processedData),
        error: error => console.error('Failed to fetch data:', error)
      }),
      catchError(error => {
        console.error('Error fetching preference data:', error);
        return of([]);
      })
    ).subscribe();
  }

  private processData(data: any): any[] {
    return Object.entries(data).map(([value, label]) => ({ value, label }));
  }
}
