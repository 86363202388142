import { combineReducers } from 'redux';

import * as TYPES from 'app/redux/actions/action-types';

const addMessageToConversation = (state, action) => {
  const { message } = action;

  if (!state[message._student]) {
    return state;
  }
  const currentMessages = state[message._student];

  // Instead of going through the whole array of messages,
  // just check the last item against the new incoming message
  const newMessageWithShowName = transformShouldCollapseName([
    currentMessages[currentMessages.length - 1],
    message
  ])[1];

  return {
    ...state,
    [message._student]: [
      ...currentMessages,
      newMessageWithShowName
    ]
  };
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.CLEAR_CONVERSATIONS:
      return {};
    case TYPES.LOAD_CONVERSATION_SUCCESS:
      return { ...state, [action.studentId]: transformShouldCollapseName(action.conversation) };
    case TYPES.ON_CONVERSATION_NEW_MESSAGE:
    case TYPES.ON_SEND_MESSAGE_SUCCESS:
      return addMessageToConversation(state, action);
    case TYPES.ON_CONVERSATION_MESSAGE_UPDATE:
      const { message } = action;

      if (!state[message._student]) {
        return state;
      }

      let messages = state[message._student];
      let messageIndex = messages.findIndex(m => m._id === message._id);

      if (messageIndex > -1) {
        let newMessages = [...messages];
        // We are doing this, so the updated message doesn't erase the showName property
        newMessages[messageIndex] = { ...newMessages[messageIndex], ...message };

        return {
          ...state,
          [message._student]: newMessages
        };
      }

      return state;
    case TYPES.LOAD_MORE_IN_CONVERSATION_SUCCESS:
      if (!state[action.studentId]) {
        return state;
      }

      let moreConversations = transformShouldCollapseName([
        ...action.conversation,
        ...state[action.studentId]
      ]);

      return {
        ...state,
        [action.studentId]: moreConversations
      };
    default:
      return state;
  }
};

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case TYPES.LOAD_CONVERSATION_SUCCESS:
      return state;
    default:
      return state;
  }
};

const currentConversationReducer = (state = null, action) => {
  switch (action.type) {
    case TYPES.SET_CURRENT_CONVERSATION:
      return action.studentId;
    case TYPES.UNSET_CURRENT_CONVERSATION:
      return null;
    default:
      return state;
  }
};

const assignedStudentsConversationsReducer = (state = [], action) => {
  let cIndex;

  switch (action.type) {
    case TYPES.LOAD_CONVERSATIONS_SUCCESS:
      return action.conversations; //this need to be reworked its mutating state
    case TYPES.ON_CONVERSATION_NEW_MESSAGE:
      const { message, currentConversationId } = action;
      const lastMessage = { ...message };
      lastMessage._sender = lastMessage._sender ? lastMessage._sender._id : lastMessage._sender;

      let isMessageRead = message._student === currentConversationId;

      cIndex = state.findIndex(c => c._id === message._student);
      if (cIndex > -1) {
        let convs = state.filter(c => c._id !== message._student);
        let updatedMessage = { ...state[cIndex], isMessageRead, lastMessage };
        return [updatedMessage, ...convs];
      }
      return state;
    case TYPES.SET_CURRENT_CONVERSATION:
      const studentId = action.studentId;
      cIndex = state.findIndex(c => c._id === studentId);
      if (cIndex > -1) {
        let conversations = [...state];
        conversations[cIndex] = { ...conversations[cIndex], isMessageRead: true };
        return conversations;
      }
      return state;
    case TYPES.ON_NEW_STUDENT_CONVERSATION:
      let student = action.student;
      return [
        { isMessageRead: false, student: student, _id: student._id },
        ...state
      ];
    default:
      return state;
  }
};

const assignedMemberIdsReducer = (state = [], action) => {
  switch (action.type) {
    case TYPES.LOAD_ASSIGNED_MEMBER_IDS:
      return action.assignedMemberIds;
    default:
      return state;
  }
};

export const transformShouldCollapseName = (conversations: Array<any>): Array<any> => {
  return conversations.reduce((acc, currMessage) => {
    const prevMessage = acc[acc.length - 1];
    let showName = true;

    if (
      (prevMessage) &&
      (prevMessage._sender && currMessage._sender) &&
      (prevMessage._sender._id === currMessage._sender._id)
    ) {
      showName = false;
    }

    acc.push({ ...currMessage, showName });
    return acc;
  }, []);
};



export const SELECTORS = {
  getCurrentConversation: (state) =>
    state.entities.conversations.byId[state.entities.conversations.currentConversation],

  getAssignedStudentsConversations: (state) =>
    state.entities.conversations.assignedStudentsConversations,

  getAssignedMemberIds: state => state.entities.conversations.assignedMemberIds,
};

export default combineReducers({
  byId: byIdReducer,
  allIds: allIdsReducer,
  currentConversation: currentConversationReducer,
  assignedStudentsConversations: assignedStudentsConversationsReducer,
  assignedMemberIds: assignedMemberIdsReducer,
});
