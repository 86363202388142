
import {Observable, BehaviorSubject, of} from 'rxjs';
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {catchError, finalize, map} from "rxjs/operators";
import {engagement} from '../../models/engagement';
import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import * as _ from "lodash";
import { CoachService } from "app/core/services/coach.service";
import { UtilityService } from "app/core/services/util.service";
import * as moment from "moment-timezone";

@Injectable()
export class EngagementDataSource implements DataSource<engagement> {

    private engagementSubject = new BehaviorSubject<engagement[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    private _paginator: MatPaginator|null;

    constructor(
      private coachService: CoachService,
      private utilsService: UtilityService,
      ) { }

    connect(collectionViewer: CollectionViewer): Observable<engagement[]> {
        return this.engagementSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.engagementSubject.complete();
        this.loadingSubject.complete();
    }

    loadEngagement(id: string, pageIndex = 0, pageSize = 5) {
        this.loadingSubject.next(true);
        let shiftTimes = [
          {
            label: "5-7 PM ET",
            value: "1",
          },
          {
            label: "7-9 PM ET",
            value: "2",
          },
        ];
        let shiftDays = [
          {
            label: "Monday",
            value: "1",
          },
          {
            label: "Tuesday",
            value: "2",
          },
          {
            label: "Wednesday",
            value: "3",
          },
          {
            label: "Thursday",
            value: "4",
          },
          {
            label: "Friday",
            value: "5",
          },
          {
            label: "Saturday",
            value: "6",
          },
          {
            label: "Sunday",
            value: "7",
          },
          {
            label: "Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday",
            value: "8",
          },
        ];
        this.coachService.findEngagement(pageSize, pageIndex, id)
        .pipe(
          catchError(() => of({coachingSessions: undefined, total: 0})),
          finalize(() => this.loadingSubject.next(false))
         )
        .subscribe(enagagementData => {
            const parsedEngagementData: engagement[] = enagagementData.coachingSessions.map(x => {
            const time = _.find(
              shiftTimes,
              (i: any) => i.value === x.shiftTime
            );
            const day = _.find(
              shiftDays,
              (i: any) => i.value === x.shiftDay
            );
          return {
            ...x,
            shiftTime: time ? time.label : x.shiftTime,
            shiftDay: day ? day.label : x.shiftDay,
            date: moment
              .tz(x.date, this.utilsService.getDefaultTimeZone())
              .format("MM/DD/YY"),
              };
            });
            this.engagementSubject.next(parsedEngagementData);
            this._updatePaginator(enagagementData.total);
        });
    }

  get paginator(): MatPaginator | null { return this._paginator; }
  set paginator(paginator: MatPaginator|null) {
    this._paginator = paginator;
    //this._updateChangeSubscription();
  }

  _updatePaginator(filteredDataLength: number) {
    Promise.resolve().then(() => {
      if (!this.paginator) { return; }

      this.paginator.length = filteredDataLength;

      // If the page index is set beyond the page, reduce it to the last page.
      if (this.paginator.pageIndex > 0) {
        const lastPageIndex = Math.ceil(this.paginator.length / this.paginator.pageSize) - 1 || 0;
        this.paginator.pageIndex = Math.min(this.paginator.pageIndex, lastPageIndex);
      }
    });
  }
}
