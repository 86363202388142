
import { of as observableOf,  Observer ,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';

import { loadUsersSuccess } from 'app/redux/actions/user.actions';
import { loadAssignedStudentsSuccess } from 'app/redux/actions/student.actions';
import { SELECTORS as USER_SELECTORS } from 'app/redux/reducers/user.reducer';
import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';

import { ApiService } from './api.service';

@Injectable()
export class RiskAlertService {

  riskAlertsArray = [];
  assignedStudents = [];

  constructor(
    private api: ApiService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  createRiskAlert(riskAlert) {
    // {
    //   "_student": "58932fa346d0ee0a8a4cda3a",
    //   "_partner": "58d1a3093891dc2c2e167de4",
    //   "_createdBy": "58931243aa0e39fd2624d92c",
    //   "cause": "Suicide Ideation",
    //   "notes": "Test suicide ideation"
    //   }
    return this.api.post('/risk-alerts', riskAlert)
  }

  getRiskAlert(id: string) {
    return this.api.get('/risk-alerts/' + id).pipe(
      map(res => {
        const riskAlert = res;
        return riskAlert;
      }));
  }

  getRiskAlerts() {
    // return this.ngRedux.select(USER_SELECTORS.getAllRiskAlertes);
    return this.api.get('/risk-alerts').pipe(map((res: any) => res.data));
  }

  getRiskAlertsForPartner(id) {
    // return this.ngRedux.select(USER_SELECTORS.getAllRiskAlertes);
    return this.api.get(`/partners/${id}/risk-alerts`).pipe(map((res: any) => res.data));
  }

  updateRiskAlert(id: string, data) {
    return this.api.patch('/risk-alerts/' + id, data).pipe(
      map((res: any) => {
        let riskAlert = res;

        return riskAlert;
      }));
  }

  setActionPlan(id: string, data) {
    return this.api.patch(`/risk-alerts/${id}/action-plan`, data).pipe(
      map((res: any) => {
        let riskAlert = res;

        return riskAlert;
      }));
  }


  deleteRiskAlert(id: string) {
    return this.api.delete('/risk-alerts/' + id);
  }
}
