export const LeadFilter = {
    mrStatus: '',
    partner: '',
    gender: '',
    city: '',
    state: '',
    name: '',
    zipCode: '',
    show: 'all-time',
    startDate: null,
    endDate: null,
  };