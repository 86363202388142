import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { SocketIOService } from './socket.service';
import { UtilityService } from './util.service';


@Injectable()
export class StatisticsService {

  constructor(
    private api: ApiService,
    private utilService: UtilityService,
    private rxsoc: SocketIOService
  ) { }

  getTrendingTagsForPartner(partnerId, studentId?, filter: any = {}) {
    filter.studentId = studentId;
    filter = this.utilService.cleanUpQueryFilter(filter);
    return this.api.get(`/partners/${partnerId}/conversations/trending-tags`, filter);
  }

  getBreakdownOfMRUsageByAssessmentItems(partnerId, studentId?, filter?) {
    filter.studentId = studentId;
    filter = this.utilService.cleanUpQueryFilter(filter);
    return this.api.get(`/partners/${partnerId}/students/reports/breakdown-of-m-r-usage-by-assessment-items`, filter);
  }

  getBreakdownOfMRUsageByGender(partnerId, studentId?, filter?) {
    filter.studentId = studentId;
    filter = this.utilService.cleanUpQueryFilter(filter);
    return this.api.get(`/partners/${partnerId}/students/reports/breakdown-of-m-r-usage-by-gender`, filter);
  }

  getAceBreakdownForPartner(partnerId, filter: any = {}) {
    filter = this.utilService.cleanUpQueryFilter(filter);
    return this.api.get(`/partners/${partnerId}/students/reports/ace/breakdown`, filter);
  }

  getResponseRateForPartner(partnerId) {
    return this.api.get(`/partners/${partnerId}/students/average-response-rate`);
  }

  getAverageWeeklyUsageForPartner(partnerId) {
    return this.api.get(`/partners/${partnerId}/students/average-weekly-usage`);
  }

  getStudentsOnboardingForPartner(partnerId, count = true) {
    return this.api.get(`/partners/${partnerId}/students/onboarding`, { count });
  }

  getStudentsSignedUpForPartner(partnerId, count = true) {
    return this.api.get(`/partners/${partnerId}/students/signed-up`, { count });
  }

  getStudentsUsingMindRight(partnerId, count = true) {
    return this.api.get(`/partners/${partnerId}/students/started`, { count });
  }

  getStudentsInTier2(partnerId, count = true) {
    return this.api.get(`/partners/${partnerId}/students/tier2`, { count });
  }

  getStudentsTotalTexts(partnerId, filter?) {
    filter = this.utilService.cleanUpQueryFilter(filter);
    return this.api.get(`/partners/${partnerId}/students/reports/chats/total-texts`, filter);
  }
}
