import { Injectable } from "@angular/core";
import {  Subject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { Recommendation } from "../../models/recommendations";

@Injectable()
export class RecommendationsService {
  private linkRecommendationSubject = new Subject<void>();
  constructor(
    private api: ApiService,
  ) { }


  getRecommendations(id, pageNumber = 0, pageSize = 5) {
    return this.api.get(`/recommendations/${id}`).pipe(
      map(res => res['data']) 
  )}

  saveNewRecommendations(recommendations) {
    return this.api.post('/recommendations', {...recommendations });
  }

  linkRecommendationsToConversation(recommendation) {
    return this.api.post(`/recommendations/link`, { ...recommendation }).pipe(
      map(() => this.linkRecommendationSubject.next())
    );;
  }

  onLinkRecommendation(): Observable<void> {
    return this.linkRecommendationSubject.asObservable();
  }

  upDateRecommendations( recommendation) {
    return this.api.patch(`/recommendations/${recommendation._id}`, {...recommendation });
  }

  removeRecommendations( recommendation) {
    return this.api.delete(`/recommendations/${recommendation._id}`);
  }

  getRecommendationsByFilters(memberIds: string[], startDate = '', endDate = '') {
    return this.api.post('/recommendations/get-by-filter', {
      startDate, endDate, members: memberIds
    }).pipe(map(res => res['data']));
  }
}
