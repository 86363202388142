<ng-template #editRiskAlertModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Edit Risk Alert</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3><span class="text-muted">For: </span> {{editingRiskAlert._student.firstName}} <span class="fs-exclude">{{editingRiskAlert._student.lastName}}</span></h3>

    <div class="form">
      <div class="form-group">
        <label>Cause</label>
        <div class="row">
          <div class="col">
            <input class="form-control" placeholder="Suicide Ideation" [(ngModel)]="editingRiskAlert.cause" type="text">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Notes</label>
        <div class="row">
          <div class="col">
            <textarea rows="4" class="form-control" placeholder="More detail" [(ngModel)]="editingRiskAlert.notes" type="text"></textarea>
          </div>
        </div>
      </div>


    </div>
  </div>
  <div class="modal-footer">
    <button (click)="c('Cancel')" class="btn btn-secondary">Cancel</button>
    <button (click)="editRiskAlert(editingRiskAlert)" class="btn btn-success">OK</button>
  </div>
</ng-template>

<ng-template #deleteRiskAlertModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Delete Risk Alert</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h3><span class="text-muted">For: </span> {{deletingRiskAlert._student.firstName}} <span class="fs-exclude">{{deletingRiskAlert._student.lastName}}</span></h3>

    <div class="form">
      <div class="form-group">
        <label>Cause</label>
        <div class="row">
          <div class="col">
            <input class="form-control" [(ngModel)]="deletingRiskAlert.cause" type="text" disabled="true">
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Notes</label>
        <div class="row">
          <div class="col">
            <textarea rows="4" class="form-control" [(ngModel)]="deletingRiskAlert.notes" type="text" disabled="true"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="c('Cancel')" class="btn btn-secondary">Cancel</button>
    <button (click)="deleteRiskAlert(deletingRiskAlert)" class="btn btn-success">OK</button>
  </div>
</ng-template>

<div fxLayout="column" fxLayoutGap="20px">
  <div>
    <div class="progress" style="height: 30px">
      <div class="progress-bar bg-success progress-bar-striped progress-bar-animated fw-bold" role="progressbar" style="font-size: large; overflow: hidden"
        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [style.width]="riskAlertStatusPercentages['Done']">DONE {{riskAlertStatusPercentages['Done']}}</div>
      <div class="progress-bar bg-warning progress-bar-striped progress-bar-animated fw-bold" role="progressbar" style="font-size: large; overflow: hidden"
        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [style.width]="riskAlertStatusPercentages['Needs plan or follow up']">NEEDS PLAN</div>
      <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated fw-bold" role="progressbar" style="font-size: large; overflow: hidden"
        aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" [style.width]="riskAlertStatusPercentages['Needs check in']">NEEDS CHECK IN</div>
    </div>
  </div>

  <div>
    <!-- <ngx-datatable
      class="material mr-table"
      [columnMode]="'force'"
      [rows]="riskAlerts"
      [rowHeight]="'auto'"
      [headerHeight]="'auto'">
        <ngx-datatable-column name="Name">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row._student.firstName}} {{row._student.lastName}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Cause">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row.cause}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Partner">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row._partner?.name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Reported By" *ngIf="showReporter">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row._createdBy.firstName}} {{row._createdBy.lastName}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Date">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row.createdAt | momentFormatDate}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <h5>
              <span class="badge rounded-pill bg-{{getRiskAlertStatus(row).color}}">
                {{getRiskAlertStatus(row).message}}
              </span>
            </h5>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="[View]">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button class="btn btn-secondary" (click)="viewRiskAlert(row)">
              <i class="fa fa-sign-in-alt"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="[Edit]">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button class="btn btn-secondary" (click)="openEditModal(row)">
              <i class="fa fa-edit"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="[Delete]">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button class="btn btn-danger" (click)="openDeleteModal(row)">
              <i class="fa fa-trash"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>

    </ngx-datatable> -->
  </div>
</div>
