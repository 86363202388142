import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filterOptions = {
    city: null
  };

  constructor(
    private api: ApiService
  ) { }

  getCityFilterOptions() {
    if (this.filterOptions.city) {
      return of(this.filterOptions.city);
    } else {
      return this.api.get('/app/filters/city')
        .pipe(
          map(cities => {
            this.filterOptions.city = cities;
            return cities;
          })
        );
    }
  }
}
