import { normalize, denormalize, schema, Schema } from 'normalizr';

let userSchema = new schema.Entity('users', {}, { idAttribute: '_id' });
let studentSchema = new schema.Entity('students', {}, { idAttribute: '_id' });

const mySchema = {
  entities: {
    users: [userSchema],
    students: [studentSchema]
  }
};

const normal = data => {
  return normalize(data, mySchema.entities);
};

export { mySchema, normal };
