import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';

@Pipe({ name: 'momentFormatDate' })
export class MomentFormatPipe implements PipeTransform {
  constructor() { }

  transform(date: any, format: string): any {
    let momentTime: Moment = moment.tz(date, moment.tz.guess());
    if (date && !format) {
      if (
        moment().dayOfYear() === momentTime.dayOfYear() &&
        moment().year() === momentTime.year()
      ) {
        return momentTime.format('h:mm A z');
      }
      return momentTime.format('D MMMM YY, h:mm A z');
    } else if (date && format) {
      if (format === 'fromNow') {
        return momentTime.fromNow();
      } else if (format === 'calendar') {
        return momentTime.calendar();
      } else {
        return momentTime.format(format);
      }
    } else {
      return 'DATE';
    }
  }
}