<mat-toolbar>
  <mat-toolbar-row fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between center"
    style="color: #777; height: auto; min-height: 64px">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex>
        <i fxHide.gt-md class="fa fa-bars"></i>

        <span class="text-muted">
          {{ pageTitle }}
          <span *ngIf="!isProductionEnvironment" class="env-text">{{environmentName || 'Local'}}</span>
        </span>
      </div>

      <div fxFlex="2 1 0%" fxFlex.xs="nogrow">
        <span style="color: RGBA(127, 181, 57, 1.00); font-weight: normal" *ngIf="isOnline">
          <mat-icon style="line-height: 18px; vertical-align: bottom;">wifi</mat-icon>
        </span>
        <span style="color: RGBA(211, 84, 0, 1.00); font-weight: normal" *ngIf="!isOnline">
          <mat-icon style="line-height: 18px; vertical-align: bottom;">wifi_off</mat-icon>
          Offline
        </span>
      </div>


      <div fxFlex="2 1 0%" fxFlex.xs="nogrow">
      
        <span style="color: RGBA(211, 84, 0, 1.00); font-weight: normal; font-size: 14px;" *ngIf="!enabledTwoFA">
          <mat-icon style="line-height: 12px; vertical-align: bottom;">notification_important</mat-icon>
          Two Factor Authentication Not Enabled
        </span>
      </div>
    </div>

    <div fxFlex="2 1 0%" fxFlex.xs="nogrow"
      *ngIf="displayCheckIn && auth.isCurrentUserCoach() && (! auth.isCurrentUserCss() && !auth.isCurrentUserAdmin() && !auth.isUserManager())">
      <button mat-raised-button [disabled]="runCaseloadCheckInLoading" (click)="onClickCheckIn()" color="primary"
        class="mb-3 mt-3">Check In</button>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.xs="space-between">
      <span class="me-2">
        <span style="color: RGBA(211, 84, 0, 1.00); font-weight: normal" >
          <countdown [config]="config" (event)="handleEvent($event)"></countdown>
        </span>
      </span>
     
      <span class="me-3">
        <span class="badge rounded-pill bg-dashboard">
          <i class="fa fa-comment-dots me-1"></i>
          <span>
            886-886
          </span>
        </span>
      </span>

      <span fxHide.xs class="me-1">
        <a *ngIf="app.currentUser" routerLink="/account" class="badge rounded-pill bg-dashboard">
          {{app.currentUser.firstName}}
          <span class="badge bg-light rounded-pill text-muted"
            style="font-size: 100%; font-weight: bold; margin-left: 5px; margin-right: -0.4em;">
            {{userTitle}}
          </span>
        </a>
      </span>

      <mat-menu #appMenu="matMenu">
        <button mat-menu-item *ngIf="auth.isAdminOrAnalyst() || auth.isUserCss() || auth.isCurrentUserCoach() || auth.isUserManager()" routerLink="/settings"><i
            class="fa fa-cog me-2"></i> Settings</button>
        <button mat-menu-item routerLink="/account"><i class="fa fa-user me-2"></i> Account</button>
        <!-- <button mat-menu-item><i class="fa fa-question-circle me-2"></i> Support</button> -->
        <button mat-menu-item (click)="logout()"><i class="fa fa-sign-out-alt me-2"></i> Logout</button>
      </mat-menu>

      <mat-menu #crisisMembersMenu="matMenu" class="crisis-menu">
        <ul *ngIf="crisisTextsMembers.length" class="list-group">
          <li *ngFor="let member of crisisTextsMembers" class="list-group-item crisis-list-group">
            <p class="d-inline-block mb-0 icon-text">{{ member._user | displayStudentName }}</p>
            <i class="fa fa-comments fa-fw chat-icon chat-icon__concern" (click)="goToChat(member._user._id)"></i>
          </li>
        </ul>
      </mat-menu>

      <button class="position-relative" *ngIf="userTitle == 'Css'" mat-button [disabled]="!crisisTextsMembers.length" disableRipple="true" [matMenuTriggerFor]="crisisMembersMenu">
        <mat-icon>notifications</mat-icon>
        <p class="counter" *ngIf="crisisTextsMembers.length">
          <span>{{ crisisTextsMembers.length }}</span>
        </p>
      </button>

      <button mat-button disableRipple="true" [matMenuTriggerFor]="appMenu">
        <mat-icon>account_circle</mat-icon>
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
  <div *ngIf="runCaseloadCheckInLoading" class="col-sm">
    <div class="spinner-grow text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</mat-toolbar>
