<div>
  <ng-content></ng-content>

  <div class="mt-1" [hidden]="false">
    <div>
      <ul style="color: #d40; padding-left: 20px">
        <li *ngFor="let txt of passwordHelpTexts">
          {{txt}}
        </li>
      </ul>
    </div>
  </div>
</div>
