import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JWTokenService {

  constructor(private helper: JwtHelperService) { }

  getToken(): string {
    return window.sessionStorage.getItem('jwt');
  }

  storeToken(token: string) {
    window.sessionStorage.setItem('jwt', token);
    window.sessionStorage.setItem('timer', '7800');
  }

  clearToken() {
    window.sessionStorage.removeItem('jwt');
    window.sessionStorage.removeItem('timer');
  }

  // decodeToken(): object {
  //   if (!this.getToken()) {
  //     return null;
  //   }
  //   return this.helper.decodeToken(this.getToken());
  // }


  isTokenValid(): boolean {
    if (window.sessionStorage.getItem('jwt')) {
      return true;
    } else {
      return false;
    }
  }
}
