import { Pipe, PipeTransform } from '@angular/core';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { NgRedux } from '@angular-redux/store';
import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';
import { IAppState } from 'app/redux/store';

@Pipe({ name: 'displaySexualOrientation' })
export class DisplayOrientationPipe implements PipeTransform {

  constructor(private ngRedux: NgRedux<IAppState>, private adminService: AdminSettingsService) { }

  transform(sexualOrientation = '', args: string[]) {
    const sexualOrientationSelections = this.ngRedux.getState().entities.dashboard.profileSelections['sexualOrientation'];

    if (Object.keys(sexualOrientationSelections).includes(sexualOrientation)) {
      return sexualOrientationSelections[parseInt(sexualOrientation, 10)];
    } else {
      return sexualOrientation || '';
    }
  }
}
