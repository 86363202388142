import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from 'app/core/services/auth.service';

@Pipe({ name: 'displayStudentLastName', pure: true })
export class DisplayStudentLastNamePipe implements PipeTransform {

  constructor(private auth: AuthService) { }

  getStudentName(student: any) {
    if (student.lastName && student.lastName.trim()) {
      if (this.auth.isCurrentUserAdmin() || this.auth.isUserCss() || this.auth.isUserManager()) {
        return student.lastName.trim();
      }

      return `${student.lastName.charAt(0)}.`;
    }
  }

  transform(student: any, args: string[]): any {
    if (student) {
      return this.getStudentName(student) || 'Unnamed';
    } else {
      return 'Unnamed Student';
    }
  }
}
