import { combineReducers } from 'redux';
import * as TYPES from 'app/redux/actions/action-types';
import { normal } from './schema';

const meReducer = (state = {}, action) => {
  switch (action.type) {

    case TYPES.LOGIN_USER_SUCCESS:
      return { ...state, isAuthenticated: true };
    default:
      return state;
  }
};

export const SELECTORS = {
  getMe: state => state.entities.me
};

export default meReducer;
