import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-editable-html',
  templateUrl: './editable-html.component.html',
  styleUrls: ['./editable-html.component.scss']
})
export class EditableHtmlComponent implements OnInit {

  @Input() html = '';
  @Input() isSaving = '';
  @Input() isEditMode = false;
  @Input() isRecommendation = false;
  @Input() recommendationId = '';
  @Input() isCompleted = false;
  @Input() isLocked: boolean;

  @Output() onUpdateDone = new EventEmitter();
  @Output() onLinkClick = new EventEmitter();
  @Output() onRecommendationClick = new EventEmitter();

  editHtml = '';
  readonlyNotes = true;

  quillEditorOptions = {
    height: 200,
    style: { height: '200px', background: '#FFF' },
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        [{
          'align': []
        }],
        ['link'],
        [{ 'header': 1 }, { 'header': 2 }]
      ]
    }
  };

  quillEditorReadOnlyOptions =  {
    style: { height: '200px', background: '#FFF', border: 'none' },
    readOnly: true,
    theme: 'snow',
    modules: {
      toolbar: false
    }
  };

  constructor() {}

  ngOnInit() {
    this.editHtml = this.html;
  }
  handleEditClick() {
    this.isEditMode = true;
    this.editHtml = this.html;
  }

  handleLinkClick() {
    this.onLinkClick.emit(this.recommendationId);
  }

  handleSaveClick() {
    this.onUpdateDone.emit(this.editHtml);
    this.isEditMode = false;
  }

  handleRecommendationClick() {
    this.onRecommendationClick.emit(this.recommendationId);
  }
}
