import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Filter } from './filter-state';
import { of } from 'rxjs';
import * as moment from 'moment-timezone';
import { FilterService } from 'app/core/services/filter.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { PartnerService } from 'app/core/services/partner.service';

moment.tz.setDefault('UTC');

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  options: FormGroup;
  selected: [];

  @Input() disableShadow = false;
  @Input() enablePartnerDropDown: boolean = true;

  partner;

  RWJBHLocations = [
    {
      label: 'Clara Maass Medical Center',
      value: '1',
    },
    {
      label: 'Newark Beth Israel Medical Center',
      value: '2',
    },
    {
      label: "Children's Specialized Hospital Outpatient Center at Newark",
      value: '3',
    },
    {
      label: 'Saint Barnabas Medical Center',
      value: '4',
    },
    {
      label: 'Barnabas Health Ambulatory Care Center',
      value: '5',
    },
    {
      label: 'RWJBarnabas Health Medical Group',
      value: '6',
    },
    {
      label: "Children’s Specialized Mountainside",
      value: '7',
    },
    {
      label: "Children’s Specialized New Brunswick",
      value: '8',
    },
    {
      label: "Children’s Specialized Clifton",
      value: '9',
    },
    {
      label: 'Jersey City Medical Center',
      value: '10',
    },
    {
      label: 'RWJ-Rahway',
      value: '11',
    },
    {
      label: 'RWJ-Somerset',
      value: '12',
    },
    {
      label: 'RWJBH Campus Drive',
      value: '13',
    },
    {
      label: 'RWJBH other facility',
      value: '14',
    }
  ];

  filter = Filter;

  @Output() onFilterUpdate = new EventEmitter<object>();
  @Output() filterData$ = new EventEmitter<object>();

  filterOptions = {
    city$: null,
    gender$: null,
    race$: null,
    orientation$: null,
    partners$: null,
  };


  dateHelperButtonState = {
    '1d': false,
    '7d': false,
    'start of school year': false
  };

  startMaxDate;
  endMinDate;

  broadCastUpdateData; 
  filteredData;

  constructor(
    fb: FormBuilder,
    private adminService: AdminSettingsService,
    private filterService: FilterService,
    private partnerService: PartnerService,
    private store: NgRedux<IAppState>
  ) {
    this.options = fb.group({
      hideRequired: false,
      floatLabel: 'auto',
    });

    this.partnerService.currentPartnerInPartnerDashboard$.subscribe(partner => {
      this.partner = partner;
    });
  }

  broadcastUpdate() {
    if(!this.enablePartnerDropDown) {
        const partnerSelections=this.store.getState().entities.dashboard.profileSelections['partners'];
        this.filter.partners = partnerSelections.map(x => x._id);
    }
    this.filterData$.emit(this.filter);
    this.onFilterUpdate.emit(this.filter);
  }

  async ngOnInit() {
    await this.adminService.loadProfileSections();

    this.broadCastUpdateData = this.broadcastUpdate();

    this.filteredData = this.loadFilterOptions();
  }

  loadFilterOptions() {
    const raceSelections = this.store.getState().entities.dashboard.profileSelections['race'];
    const genderSelections = this.store.getState().entities.dashboard.profileSelections['gender'];
    const sexualOrientationSelections = this.store.getState().entities.dashboard.profileSelections['sexualOrientation'];
    const partnersSelections = this.store.getState().entities.dashboard.profileSelections['partners'];
    this.filterOptions.gender$ = of(Object.keys(genderSelections).map(val => {
      return {
        value: val,
        label: genderSelections[val]
      };
    }));

    this.filterOptions.race$ = of(Object.keys(raceSelections).map(val => {
      return {
        value: val,
        label: raceSelections[val]
      };
    }));

    this.filterOptions.orientation$ = of(Object.keys(sexualOrientationSelections).map(val => {
      return {
        value: val,
        label: sexualOrientationSelections[val]
      };
    }));

    this.filterOptions.partners$ = partnersSelections.map( value => {
      return {
        value,
        label: value.name,
      }
    });
    // this.filterOptions.city$ = this.partnerService.getCityFilterOptions(this.partner._id);
  }

  clearDateHelperButtonState() {
    this.dateHelperButtonState = {
      '1d': false,
      '7d': false,
      'start of school year': false
    };
  }

  onDateHelperClick(timeline) {
    let startDate: moment.Moment;
    let endDate = moment();

    this.clearDateHelperButtonState();

    if (timeline === '1d') {
      this.dateHelperButtonState['1d'] = true;
      startDate = moment().subtract(1, 'day');
    }  else if (timeline === '7d') {
      this.dateHelperButtonState['7d'] = true;
      startDate = moment().subtract(7, 'day');
    } else if (timeline === 'start of school year') {
      this.dateHelperButtonState['start of school year'] = true;
      let startSchoolYear = moment().month('August').date(1);

      if (startSchoolYear.dayOfYear() > moment().dayOfYear()) {
        startSchoolYear.subtract(1, 'year');
      }

      startDate = startSchoolYear;
    }

    this.onDateInputChange('startDate', startDate, true);
    this.onDateInputChange('endDate', endDate, true);

    this.broadcastUpdate();
  }

  onDateInputChange(whichDate: 'startDate' | 'endDate', momentDate: moment.Moment, doNotClearButtonState?) {
    if (!doNotClearButtonState) {
      this.clearDateHelperButtonState();
    }
    if (whichDate === 'startDate') {
      momentDate = momentDate.startOf('day');
      this.filter[whichDate] = momentDate.format();
      this.endMinDate = momentDate.toDate();
    } else if (whichDate === 'endDate') {
      momentDate = momentDate.endOf('day');
      this.filter[whichDate] = momentDate.format();
      this.startMaxDate = momentDate.toDate();
    }
  }

  clearFilter() {
    this.clearDateHelperButtonState();

    for (let filterOption in this.filter) {
      if (this.filter.hasOwnProperty(filterOption)) {
        this.filter[filterOption] = '';
      }
    }
    this.filter['show'] = 'live-time';
    this.broadcastUpdate();
  }

  onSelectShowFilter() {
    if (this.filter.show === 'custom-range') {
      const startDate = moment().startOf('day');
      const endDate = moment().endOf('day');

      this.endMinDate = startDate.format();
      this.startMaxDate = endDate.format();

      this.filter['startDate'] = startDate.format();
      this.filter['endDate'] = endDate.format();
    }

    this.broadcastUpdate();
  }
  
  ngOnDestroy(){
    this.broadCastUpdateData && (this.broadCastUpdateData.unsunscribe())
    this.filteredData && (this.filteredData.unsunscribe());
    this.clearFilter()
  }
}
