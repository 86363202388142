<div style="display: flex; flex-direction: column; justify-content: space-around;">
<mat-toolbar>
    <span>Link Recommendation</span>
    <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button class="example-icon favorite-icon" (click)="closeLinkRecommendationDialog()">
      <mat-icon>close</mat-icon>
    </button>
   </mat-toolbar>
   <mat-dialog-content>
     <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
     <mat-form-field style="width: 100%; margin-top: 1rem;" appearance="fill">
      <mat-label>Link Date</mat-label>
      <input matInput
      [(ngModel)]="linkDate"  type="date" placeholder="Link Date">
    </mat-form-field>
   </mat-dialog-content>
   <mat-dialog-actions align="end">
    <button class="btn-secondary" [disabled]="isLoading" mat-button (click)="closeLinkRecommendationDialog()" cdkFocusInitial>Cancel</button>
     <button class="btn-danger" [disabled]="isLoading" mat-button (click)="unlinkRecommendation()" cdkFocusInitial>Unlink</button>
     <button class="btn-success" [disabled]="isLoading" mat-button (click)="linkRecommendation()">Link</button>
   </mat-dialog-actions>
  </div>
   