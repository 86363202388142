import { Component, OnInit, Input, EventEmitter, OnChanges } from '@angular/core';

import { PartnerService } from 'app/core/services/partner.service';
import { MRUserService } from 'app/core/services/mruser.service';

@Component({
  selector: 'app-assessment-analytics',
  templateUrl: './assessment-analytics.component.html',
  styleUrls: ['./assessment-analytics.component.scss']
})
export class AssessmentAnalyticsComponent implements OnInit, OnChanges {
  mostCommonAces: any = [];
  frequentPtsdSymptoms: any = [];
  postitiveRsqStrategies: any = [];
  negativeRsqStrategies: any = [];
  highAndLowEpochAreas: any = [];
  matTooltipForEpochLow = `"Highest | Frequency" ==> "Number of students for whom the EPOCH area is their highest scoring area, meaning students are doing well in those areas"`;
  matTooltipForEpochHigh = `"Lowest | Frequency" ==> "Number of students for whom the EPOCH area is their lowest scoring area, meaning students are NOT doing well in those areas"`;

  @Input()
  partnerId = null;
  @Input()
  studentId = null;

  @Input()
  filterParams$: EventEmitter<any>;

  constructor(
    private userService: MRUserService,
    private partnerService: PartnerService
  ) {}

  ngOnInit() { }

  ngOnChanges() {
    if (this.studentId && this.partnerId) {
      this.loadAnalytics();
    } else if (this.partnerId && this.filterParams$) {
      this.loadAnalytics();
      this.filterParams$.subscribe(filter => this.loadAnalytics(filter));
    }
  }

  loadAnalytics(filter = {}) {
    this.loadMostCommonAces(this.partnerId, this.studentId, filter);
    this.loadPtsdSymptoms(this.partnerId, this.studentId, filter);
    this.loadRsqStrategies(this.partnerId, this.studentId, filter);
    this.loadHighAndLowEpochAreas(this.partnerId, this.studentId, filter);
  }

  loadMostCommonAces(partnerId, studentId, filter) {
    filter.studentId = studentId;
    this.partnerService
      .getMostCommonAces(partnerId, filter)
      .subscribe(
        mostCommonAcesData => (this.mostCommonAces = mostCommonAcesData)
      );
  }

  loadPtsdSymptoms(partnerId, studentId, filter) {
    filter.studentId = studentId;
    this.partnerService
      .getFrequencyOfPtsdSymptoms(partnerId, filter)
      .subscribe(ptsdSymptoms => (this.frequentPtsdSymptoms = ptsdSymptoms));
  }

  loadRsqStrategies(partnerId, studentId, filter) {
    filter.studentId = studentId;
    this.partnerService
      .getFrequenciesOfRsq(partnerId, filter)
      .subscribe((rsqStrategies: any) => {
        this.postitiveRsqStrategies = rsqStrategies.positive;
        this.negativeRsqStrategies = rsqStrategies.negative;
      });
  }

  loadHighAndLowEpochAreas(partnerId, studentId, filter) {
    filter.studentId = studentId;
    this.partnerService
      .getFrequenciesOfHighAndLowEpoch(partnerId, filter)
      .subscribe(
        highAndLowEpochAreas =>
          (this.highAndLowEpochAreas = highAndLowEpochAreas)
      );
  }

  filterData(filter) {
    this.loadMostCommonAces(this.partnerId, this.studentId, filter);
    this.loadPtsdSymptoms(this.partnerId, this.studentId, filter);
    this.loadRsqStrategies(this.partnerId, this.studentId, filter);
    this.loadHighAndLowEpochAreas(this.partnerId, this.studentId, filter);
  }
}
