import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {

  subjectsDict: any = {};

  constructor() { }

  getItemSync(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  getItem(key): Observable<any> {
    if (this.subjectsDict[key]) {
      return this.subjectsDict[key];
    }

    let subject = new BehaviorSubject(JSON.parse(sessionStorage.getItem(key)));
    this.subjectsDict[key] = subject;
    return this.subjectsDict[key];
  }

  setItem(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));

    if (this.subjectsDict[key]) {
      this.subjectsDict[key].next(value);
    }
  }

  removeItem(key) {
    sessionStorage.removeItem(key);
  }
}
