import * as TYPES from './action-types';

export function clearConversations() {
  return {
    type: TYPES.CLEAR_CONVERSATIONS
  };
}

export function loadConversationSuccess(studentId, conversation) {
  return {
    type: TYPES.LOAD_CONVERSATION_SUCCESS,
    studentId,
    conversation
  };
}

export function loadMoreInConversationSuccess(studentId, conversation) {
  return {
    type: TYPES.LOAD_MORE_IN_CONVERSATION_SUCCESS,
    studentId,
    conversation
  };
}

export function loadConversationsSuccess(conversations) {
  return {
    type: TYPES.LOAD_CONVERSATIONS_SUCCESS,
    conversations
  };
}

export function loadAssignedMemberIds(assignedMemberIds) {
  return {
    type: TYPES.LOAD_ASSIGNED_MEMBER_IDS,
    assignedMemberIds
  };
}

export function onConversationNewMessage(message, currentConversationId) {
  return {
    type: TYPES.ON_CONVERSATION_NEW_MESSAGE,
    currentConversationId,
    message
  };
}

export function onConversationMessageUpdate(message) {
  return {
    type: TYPES.ON_CONVERSATION_MESSAGE_UPDATE,
    message
  };
}

export function onSendMessageSuccessful(message) {
  return {
    type: TYPES.ON_SEND_MESSAGE_SUCCESS,
    message
  };
}

export function onNewStudentConversation(student) {
  return {
    type: TYPES.ON_NEW_STUDENT_CONVERSATION,
    student
  };
}

export function setCurrentConversation(studentId) {
  return {
    type: TYPES.SET_CURRENT_CONVERSATION,
    studentId
  };
}

export function unsetCurrentConversation() {
  return {
    type: TYPES.UNSET_CURRENT_CONVERSATION
  };
}
