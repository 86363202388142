export const Filter = {
  city: '',
  gender: '',
  ethnicity: '',
  orientation: '',
  grade: '',
  aceScoreRange: '',
  epochScoreRange: '',
  ptsdScoreRange: '',
  rsqScoreRange: '',
  partnerLocation: '',
  age: '',
  startDate: '',
  endDate: '',
  show: 'live-time',
  partners: [],
};
