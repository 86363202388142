<mat-toolbar>
 <span>Potential Crisis Text</span>
 <span style="flex: 1 1 auto;"></span>
 <button mat-icon-button class="example-icon favorite-icon" (click)="closeCrisisDialog()">
   <mat-icon>close</mat-icon>
 </button>
</mat-toolbar>
<mat-dialog-content class="mat-typography">
  <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
  <h2 style="text-align: center; margin: 16px 0;">There {{data.messages.length > 1 ? 'are' : 'is a'}} {{data.messages.length}} potential crisis text{{data.messages.length > 1 ? 's' : ''}} that you should take a look at</h2>
  <p style="margin-bottom: 16px"><i>Select all that apply as crisis texts in the list below and click on CONFIRM CRISIS to continue.</i></p>
  <mat-selection-list #crisisMessages style="margin-bottom: 24px;" [(ngModel)]="selectedItems">
    <mat-list-option matLine *ngFor="let item of data.messages" [value]="item">
      {{item.message.length > 97 ? item.message.substring(0, 97) + '...' : item.message}}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn-success" [disabled]="isLoading" mat-button (click)="declinePotentialCrisisMessages()" cdkFocusInitial>Decline Crisis</button>
  <button class="btn-danger" [disabled]="isLoading" mat-button (click)="savePotentialCrisisMessages()">CONFIRM CRISIS</button>
</mat-dialog-actions>
