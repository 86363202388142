import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { RiskAlertService } from 'app/core/services/risk-alert.service';
import { NotificationService } from 'app/core/services/notification.service';

@Component({
  selector: 'app-risk-alert-details-dialog',
  templateUrl: 'risk-alert-details-dialog.html',
  styleUrls: ['risk-alert-details-dialog.component.scss']
})
export class RiskAlertDetailsDialogComponent {
  riskAlert = null;
  actionPlans = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private riskAlertService: RiskAlertService,
    public dialogRef: MatDialogRef<RiskAlertDetailsDialogComponent>,
    private notification: NotificationService
  ) {
    this.riskAlert = data.riskAlert;
    this.actionPlans = data.riskAlert.actionPlans;

    this.riskAlertService.getRiskAlert(this.riskAlert._id)
      .subscribe((fullRiskAlert: any) => {
        this.riskAlert = fullRiskAlert;
        this.actionPlans = fullRiskAlert.actionPlans;
      }, (err) => this.notification.alertError('Failed to load Risk Alert details'));
  }

  translateCanActSurvey(n) {
    return {
      1: 'Strongly disagree',
      2: 'Disagree',
      3: 'Neither agree nor disagree',
      4: 'Agree',
      5: 'Strongly agree'
    }[n];
  }

  translateIsHelpfulSurvey(n) {
    return {
      1: 'Not at all helpful',
      2: 'Not helpful',
      3: 'Indifferent',
      4: 'Helpful',
      5: 'Very helpful'
    }[n];
  }
}
