
import { Injectable } from '@angular/core';

import { Observable, Observer, BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';

import { NotificationService } from './notification.service';

import { environment } from 'environments/environment';

import * as lodash from 'lodash';

import * as io  from 'socket.io-client';

@Injectable()
export class ReportService {

  sock: io;
  reports = {
    statusReport: {
      active: { totalMembers: 'N/A' },
      inactive: { totalMembers: 'N/A' },
      away: { totalMembers: 'N/A' },
      exited: { totalMembers: 'N/A' },
      totalMembers: 0,
      lastActive: { totalMembers: 'N/A' },
      lastInactive: { totalMembers: 'N/A' },
      lastAway: { totalMembers: 'N/A' },
      lastExited: { totalMembers: 'N/A' },
      lastTotalMembers: 0,
      message: null,
    },
    statusReportLoading: false,
    demographicsReport: {
      race: { totalMembers: 'N/A' },
      gender: { totalMembers: 'N/A' },
      sexuality: { totalMembers: 'N/A' },
      pronouns: { totalMembers: 'N/A' },
      school: { totalMembers: 'N/A' },
      city: { totalMembers: 'N/A' },
      state: { totalMembers: 'N/A' },
      zipCode: { totalMembers: 'N/A' },
      totalMembers: 0,
      message: null,
    },
    demographicsReportLoading: false,
    engagementReport: {
      totalAverageEngagement: 0,
      maximumEngagement: 0,
      minimumEngagement: 0,
      message: null,
    },
    engagementReportLoading: null,
    totalTextsReport:  {
      incoming: 'N/A',
      outgoing: 'N/A',
      total: 'N/A',
      responseRate: 'N/A',
      totalMembers: 0,
    },
    totalTextsReportLoading: false,
    statisticsReport: {
      growthRate: 0,
      chunRateExitOnly: 0,
      chunRateNonActive: 0,
      returnRate: 0
    },
    statisticsReportLoading: null,
    activeRetentionReport: {
      totalStudentFiltered: 0,
      averageRetention: 0,
      minimumActive: 0,
      maximumActive: 0
    },
    activeRetentionReportLoading: null,
    exitedRetentionReport: {
      totalStudentFiltered: 0,
      averageRetention: 0,
      minimumRetention: 0,
      maximumRetention: 0,
    },
    exitedRetentionReportLoading: null,
    crisisTextReport: {
      allUsersCrisis: 0,
      totalMembers: 0,
      allUsersTotalTexts: 0,
      allUsersTotalTextsPercentageCrisis: 0,
      totalResponse: []
    },
    crisisTextReportLoading: null,
    sentimentTextReport: {
      totalSentimentText: 0,
      allUsersMinimumSentiment: 0,
      allUsersMaximumSentiment: 0,
      allUsersaverageSentiment: 0,
      totalResponse: []
    },
    sentimentTextReportLoading: null,
    emotionReport: {
      totalResponse: [],
    },
    emotionReportLoading: null,
    topicReport: {
      totalResponse: []
    },
    memberEngagementReport: {
      totalMembers: 0,
      totalShiftAssigned: 0,
      totalShiftEngaged: 0,
      percentageEngaged: 0
    },
    enrollmentReport: {
      totalMembers: 0,
      lastActive: { totalMembers: 'N/A' },
      lastWaitlist: { totalMembers: 'N/A' },
      lastSignedUp: { totalMembers: 'N/A' },
      lastSignedUpWaitlist: { totalMembers: 'N/A' },
      lastExited: { totalMembers: 'N/A' },
      lastTotalMembers: 0,
      message: null,
    },
    memberEngagementReportLoading: null,
    topicReportLoading: null,
    wellnessDataReport: null,
    wellnessDataReportLoading: null,
    npsReport: null,
    npsReportLoading: null,
    partnerReport: {
      totalActivePartners: [],
    },
    billingReport: {
      totalClaims: 0,
    },
    billingReportLoading: null,
    partnerReportLoading: null,
    enrollmentReportLoading: null
  }

  private totalTextSubject = new BehaviorSubject<any>({report: this.reports.totalTextsReport, loading: this.reports.totalTextsReportLoading});   
  private demographicsSubject = new BehaviorSubject<any>({report: this.reports.demographicsReport, loading: this.reports.demographicsReportLoading}); 
  private statusSubject = new BehaviorSubject<any>({report: this.reports.statusReport, loading: this.reports.statusReportLoading}); 
  private emotionSubject = new BehaviorSubject<any>({report: this.reports.emotionReport, loading: this.reports.emotionReportLoading }); 
  private exitRetentionSubject = new BehaviorSubject<any>({report: this.reports.exitedRetentionReport, loading: this.reports.exitedRetentionReportLoading}); 
  private activeRetentionSubject = new BehaviorSubject<any>({report: this.reports.activeRetentionReport, loading: this.reports.activeRetentionReportLoading}); 
  private topicSubject = new BehaviorSubject<any>({report: this.reports.topicReport, loading: this.reports.topicReportLoading}); 
  private sentimentSubject = new BehaviorSubject<any>({report: this.reports.sentimentTextReport, loading: this.reports.sentimentTextReportLoading}); 
  private crisisSubject = new BehaviorSubject<any>({report: this.reports.crisisTextReport,loading: this.reports.crisisTextReportLoading}); 
  private iMemberEngangementSubject = new BehaviorSubject<any>({report: this.reports.memberEngagementReport, loading: this.reports.memberEngagementReportLoading}); 
  private statSubject = new BehaviorSubject<any>({report: this.reports.statisticsReport, loading: this.reports.statisticsReportLoading}); 
  private wellnessSubject = new BehaviorSubject<any>({report: this.reports.wellnessDataReport, loading: this.reports.wellnessDataReportLoading}); 
  private engangementSubject = new BehaviorSubject<any>({report: this.reports.engagementReport, loading: this.reports.memberEngagementReportLoading}); 
  private partnerSubject = new BehaviorSubject<any>({report: this.reports.partnerReport, loading: this.reports.partnerReportLoading});
  private enrollmentSubject = new BehaviorSubject<any>({ report: this.reports.enrollmentReport, loading: this.reports.enrollmentReportLoading });

  textReport$: Observable<any> = this.totalTextSubject.asObservable();
  demographicReport$: Observable<any> = this.demographicsSubject.asObservable();
  statusReport$: Observable<any> = this.statusSubject.asObservable();
  emotionSubject$: Observable<any> = this.emotionSubject.asObservable();
  exitRetentionSubject$: Observable<any> = this.exitRetentionSubject.asObservable(); 
  activeRetentionSubject$: Observable<any> = this.activeRetentionSubject.asObservable(); 
  topicSubject$: Observable<any> = this.topicSubject.asObservable();
  sentimentSubject$: Observable<any> = this.sentimentSubject.asObservable(); 
  crisisSubject$: Observable<any> = this.crisisSubject.asObservable();
  iMemberEngangementSubject$: Observable<any> = this.iMemberEngangementSubject.asObservable(); 
  statSubject$: Observable<any> = this.statSubject.asObservable();
  wellnessSubject$: Observable<any> = this.wellnessSubject.asObservable(); 
  engangementSubject$: Observable<any> = this.engangementSubject.asObservable();
  partnertSubject$: Observable<any> = this.partnerSubject.asObservable();
  enrollmentSubject$: Observable<any> = this.enrollmentSubject.asObservable();

  isOpen = false;

  constructor(
    private api: ApiService,
    private notification: NotificationService,
  ) { 
    this.sock = io(environment.socketUrl, {
      autoConnect: false,
      transports: ['websocket']
    });
    this.init()
  }

  init() {
    this.sock.on('connect', () => {
      this.isOpen = true;
    });
  }

  connect() {
    if (!this.isOpen) {
      this.sock.open();
    }
    else this.sock.disconnect(true)
  }

  on(socketEvent: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      function listen(data) {
        observer.next(data);
      }

      this.sock.on(socketEvent, listen);

      return () => {
        this.sock.removeListener(socketEvent, listen);
        // kill this.io.on to unsubscribe
      };
    });
  }


  emit(event:string, args:any) {
    this.sock.emit(event, args)
  }

  disconnect() {
    this.sock.disconnect(true);
    this.isOpen = false;
    return () => {
      this.sock.removeListener('REPORTING');
    };
  }

  private buildParams(filter) {
    return {
      start_date: filter.startDate,
      end_date: filter.endDate,
      partners: filter.partners,
      student_ids: filter.students,
      tier2: filter.tier2
    };
  }

  handleReportingFilters(filters) {
    return {
      gender: lodash.compact(filters.gender) || [],
      orientation: lodash.compact(filters.orientation) || [],
      race: lodash.compact(filters.race) || [],
      city: lodash.compact(filters.city) || [],
      state: lodash.compact(filters.state) || [],
      reports: filters.reports || '',
      school: lodash.compact(filters.school) || [],
      shiftTime: lodash.compact(filters.shiftTime) || [],
      shiftDays: lodash.compact(filters.shiftDays) || [],
      wellness: lodash.compact(filters.SWEMWBSScore) || [],
      nps: lodash.compact(filters.NPSScore) || [],
      members: lodash.compact(filters.selectedMembers) || [],
      partners: lodash.compact(filters.selectedPartners) || [],
      coaches: lodash.compact(filters.selectedCoaches) || [],
      statusOptions: lodash.compact(filters.statusOptions) || [],
      startTime: filters.startDate || null,
      endTime: filters.endDate || null,
      shouldPopulateSheet: filters.shouldPopulateSheet || false,
      socketName: filters.socketName || null,
      ages: filters.ageOptions || [],
      engagementStatus: filters.engagementStatus || [],
    };
  }


  getConversations(filter) {
    let params = this.buildParams(filter);
    return this.api.get('/reports/sms', params);
  }

  getConversationsCSV(filter) {
    let params = this.buildParams(filter);
    return this.api.get('/reports/sms/csv', params, { responseType: 'text' });
  }

  getFeelingsAndMood(filter) {
    let params = this.buildParams(filter);
    return this.api.get('/reports/feeling-and-mood', params);
  }

  generateReportv2(filter) {
    let params = this.handleReportingFilters(filter);
    return this.api.post('/reports/reportv2', {...params});
  }

  getFeelingsAndMoodCSV(filter) {
    let params = this.buildParams(filter);
    return this.api.get('/reports/feeling-and-mood/csv', params, { responseType: 'text' });
  }

  getTagsCSV(filter) {
    let params = this.buildParams(filter);
    return this.api.get('/reports/tags/csv', params, { responseType: 'text' });
  }

  getTotalTextsCSV(filter) {
    let params = this.buildParams(filter);
    return this.api.get('/reports/sms/total-texts/csv', params, { responseType: 'text' });
  }

  async loadAllMyReports(limit = 100, page = 0) {
    // if not forced, do not load, just use from redux as cache
    const response: any = await this.api.get(`/reports/reportv2?limit=${limit}&page=${page}`)
      .toPromise()
    return response
  }

  async loadAllReportsDownloadHistory(limit = 100, page = 0) {
    // if not forced, do not load, just use from redux as cache
    const response: any = await this.api.get(`/reports/reportv2/download-history?limit=${limit}&page=${page}`)
      .toPromise()
    return response
  }

  async saveDownloadRequest (reportId) {
    const response: any = await this.api.post(`/reports/reportv2/save-download-request`, {reportId})
      .toPromise()
    return response
  }

  generateStatusReport(generate) {
    const statusReportObservable = this.on('STATUS_REPORTING_SOCKET')     
    statusReportObservable.subscribe( res => {
      this.statusSubject.next({report: res, loading:this.reports.statusReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return statusReportObservable;
  }

  generateDemographicsReport(generate) {
     
      const demographicsReportObservable = this.on('DEMOGRAPHIC_REPORTING_SOCKET')     
      demographicsReportObservable.subscribe( res => {
        this.demographicsSubject.next({report: res, loading:this.reports.demographicsReportLoading = false});

        if (res.message && generate) {
          this.notification.toastr.info(res.message)
         }
         this.reports.demographicsReportLoading = false;
      })
      return demographicsReportObservable;
  }

  generateTotalTextReport(generate) {
      const textReportObservable = this.on('TEXT_AND_RETENTION_REPORTING_SOCKET');
     
      textReportObservable.subscribe( res => {
        this.totalTextSubject.next({report: res, loading:this.reports.totalTextsReportLoading = false});
        if (res.message && generate) {
          this.notification.toastr.info(res.message)
         }
      })
     
      return textReportObservable;
  }

  generateExitRetentionReport(generate) {
    const exitReportObservable = this.on('EXIT_RETENTION_REPORTING_SOCKET')     
    exitReportObservable.subscribe( res => {
      this.exitRetentionSubject.next({report: res, loading:this.reports.exitedRetentionReportLoading = false});
        if (res.message && generate) {
          this.notification.toastr.info(res.message)
       }
    })
    return exitReportObservable;
  }

  generateActiveRetentionReport(generate) {
    const activeReportObservable = this.on('ACTIVE_RETENTION_REPORTING_SOCKET')     
    activeReportObservable.subscribe( res => {
      this.activeRetentionSubject.next({report: res, loading:this.reports.activeRetentionReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return activeReportObservable;
  }

  generateEngagementReport(generate) {
    const engagementReportObservable = this.on('ENGANGEMENT_REPORTING_SOCKET')     
    engagementReportObservable.subscribe( res => {
      this.engangementSubject.next({report: res, loading:this.reports.engagementReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return engagementReportObservable;
  }

  generateEmotionReport(generate) {
    const emotionReportObservable = this.on('EMOTION_REPORTING_SOCKET')     
    emotionReportObservable.subscribe( res => {
      this.emotionSubject.next({report: res, loading:this.reports.emotionReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return emotionReportObservable;
  }
  generateTopicReport(generate) {
    const topicReportObservable = this.on('TOPIC_REPORTING_SOCKET')     
    topicReportObservable.subscribe( res => {
      this.topicSubject.next({report: res, loading:this.reports.topicReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return topicReportObservable;
  }
  generateCrisisReport(generate) {
    const crisisReportObservable = this.on('CRISIS_REPORTING_SOCKET')     
    crisisReportObservable.subscribe( res => {
      this.crisisSubject.next({report: res, loading:this.reports.crisisTextReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return crisisReportObservable;
  }
  generateSentimentReport(generate) {
    const sentimentReportObservable = this.on('SENTIMENT_REPORTING_SOCKET')     
    sentimentReportObservable.subscribe( res => {
      this.sentimentSubject.next({report: res, loading:this.reports.sentimentTextReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return sentimentReportObservable;
  }
  generateStatisticsReport(generate) {
    const statReportObservable = this.on('STATISTIC_REPORTING_SOCKET')     
    statReportObservable.subscribe( res => {
      this.statSubject.next({report: res, loading:this.reports.statisticsReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return statReportObservable;
  }
  generateIndividualEngagementReport(generate) {
    const iEngangementReportObservable = this.on('INDIVIDUAL_MEMBER_ENGANGEMENT_REPORTING_SOCKET')     
    iEngangementReportObservable.subscribe( res => {
      this.iMemberEngangementSubject.next({report: res, loading:this.reports.memberEngagementReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return iEngangementReportObservable;
  }
  generateWellnessReport(generate) {
    const wellnessReportObservable = this.on('WELLNESS_REPORTING_SOCKET')     
    wellnessReportObservable.subscribe( res => {
      this.wellnessSubject.next({report: res, loading:this.reports.wellnessDataReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return wellnessReportObservable;
  }
  generatePartnerHistoryReport(generate) {
    const partnerReportObservable = this.on('PARTNER_REPORTING_SOCKET')     
    partnerReportObservable.subscribe( res => {
      this.partnerSubject.next({report: res, loading:this.reports.partnerReportLoading = false});
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return partnerReportObservable;
  }

  generateEnrollmentReport(generate: boolean) {
    const enrollmentReportObservable = this.on('ENROLLMENT_REPORTING_SOCKET')     
    enrollmentReportObservable.subscribe( res => {
      this.enrollmentSubject.next({ report: res, loading: this.reports.enrollmentReportLoading = false });
      if (res.message && generate) {
        this.notification.toastr.info(res.message)
       }
    })
    return enrollmentReportObservable;
  }
}
