import { Pipe, PipeTransform } from '@angular/core';
import { AdminSettingsService } from 'app/core/services/admin-settings.service';
import { NgRedux } from '@angular-redux/store';
import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';
import { IAppState } from 'app/redux/store';

@Pipe({ name: 'displayPronouns' })
export class DisplayPronounsPipe implements PipeTransform {

  constructor(private ngRedux: NgRedux<IAppState>, private adminService: AdminSettingsService) { }

  transform(pronouns = '', args: string[]) {
    const pronounsSelections = this.ngRedux.getState().entities.dashboard.profileSelections['pronouns'];

    if (Object.keys(pronounsSelections).includes(pronouns.toString())) {
     return pronounsSelections[pronouns.toString()];
    } else {
      return pronouns || '';
    }
  }
}
