import * as Raven from 'raven-js';

import { Injectable, EventEmitter } from '@angular/core';
export interface AppLoading {
  percentage: number,
  message: string,
  isLoading: boolean,
}

@Injectable()
export class AppService {

  attemptedUrl;

  currentUser: any;

  isLoggedIn = false;

  currentUserContext = {};

  onLogin$ = new EventEmitter();
  onLogout$ = new EventEmitter();
  onUserRefresh$ = new EventEmitter();
  onUnauthorisedResponse$ = new EventEmitter();

  onShowSplashLoading$ = new EventEmitter<boolean>();
  onShowRequestSpinner$ = new EventEmitter<boolean>();

  constructor() {
    this.onLogin$.subscribe(loggedInUser => {
      this.isLoggedIn = true;
      this.currentUserContext['isLoggedIn'] = true;
      this.setRavenUserContext(loggedInUser);

      this.currentUser = loggedInUser;
   
    });

    this.onLogout$.subscribe(() => {

      this.isLoggedIn = false;
      this.currentUserContext['isLoggedIn'] = false;
      this.setRavenUserContext(this.currentUserContext);

      this.currentUser = null;
    });

    this.onUserRefresh$.subscribe(loggedInUser => {
      
      this.isLoggedIn = true;
      this.currentUserContext['isLoggedIn'] = true;
      this.setRavenUserContext(loggedInUser);

      this.currentUser = loggedInUser;
    });

    this.onUnauthorisedResponse$.subscribe(() => {
      this.isLoggedIn = false;
      this.currentUserContext['isLoggedIn'] = false;
      this.setRavenUserContext(this.currentUserContext);
    });
  }

  getCurrentUser() {
    return this.currentUser;
  }

  setRavenUserContext(user) {
    this.currentUserContext = user;
    Raven.setUserContext(user);
  }

  isAdminOrAnalyst() {
    return this.currentUser && (this.currentUser._role.hierarchy >= 4 || this.currentUser._role.name === 'Analyst');
  }

  getUrlQueryStringParam = (key: string) => {
    try {
      const search = location.search.substring(1) || '';

      const data = JSON.parse('{"' + search.replace(/&/g, '","')
        .replace(/=/g, '":"') + '"}', function (item, value) {
          return item === '' ? value : decodeURIComponent(value);
        });
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

}
