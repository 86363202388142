import { Router, NavigationExtras, NavigationEnd, ActivatedRoute } from '@angular/router';

import { catchError, debounceTime, filter, distinctUntilChanged, tap, switchMap, merge as mergeOperator } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';

import { Observable, of, merge } from 'rxjs';

import { AppService } from 'app/core/services/app.service';
import { AuthService } from 'app/core/services/auth.service';
import { ApiService } from 'app/core/services/api.service';
import { SocketIOService } from 'app/core/services/socket.service';
import { CoachService } from "app/core/services/coach.service";
import { NotificationService } from 'app/core/services/notification.service';

import { environment } from 'environments/environment';
import { ConversationService } from 'app/core/services/conversation.service';
import { Student } from 'app/models/student';

@Component({
  selector: "app-header-dashboard",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  environmentName;
  isProductionEnvironment;

  isCollapsed = true;

  isSearchMode = false;
  isSearching = false;
  searchFailed = false;

  searchModel;
  displayCheckIn = false;
  studentId = null;
  userTitle: string;
  runCaseloadCheckInLoading = false;

  isOnline = false;
  enabledTwoFA;
  crisisTextsMembers: Student[] = [];

  @Input() pageTitle = null;
  config: CountdownConfig = {leftTime: 7800, notify: 0}

  hideSearchingWhenUnsubscribed = new Observable(
    () => () => (this.isSearching = false)
  );

  constructor(
    public auth: AuthService,
    public app: AppService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private coachService: CoachService,
    private socketService: SocketIOService,
    private notificationService: NotificationService,
    private convService: ConversationService
  ) {
    this.isProductionEnvironment = environment.env === "production";

    this.environmentName = environment.env.toUpperCase();

    this.socketService.socketStatus$.subscribe((onlineStatus) => {
      this.isOnline = onlineStatus.isOpen;
    });
  }

  ngOnInit() {
    const currentUrl = this.router.url;
    const firstNavState = new NavigationEnd(1, currentUrl, currentUrl);
    
    /**7800 is the timer duration for 2hrs 10mins */
    let timerValue = Number(window.sessionStorage.getItem('timer')) ?? 7800;
    if (timerValue <= 0) timerValue = 7800
    this.config = {...this.config, leftTime: timerValue }

    merge(of(firstNavState), this.router.events)
      .pipe(filter((ev) => ev instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
       this.displayCheckIn = false;
        if(ev.urlAfterRedirects == '/dashboard' || ev.urlAfterRedirects.includes('chat')) {
          this.displayCheckIn = true;
        }
        if (/chat\/.+/.test(ev.urlAfterRedirects)) {
          let route = this.route;
          // Get targeted route component to get student-id from params
          while (route.firstChild) {
            route = route.firstChild;
          }
          this.studentId = route.snapshot.params["student-id"];
        } else {
          this.studentId = null;
        }
      });

    this.userTitle = this.app.currentUser._role.title;
    if (this.userTitle == "Manager") {
      this.userTitle = "Coordinator";
    }
    this.getMFAStatus();
    this.getCrisisTextsMembers();
  }

  reloadApp(ev) {
    window.location.reload();
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleSearchMode() {
    this.isSearchMode = !this.isSearchMode;
  }

  searchService(searchQuery) {
    return this.api.get("/conversations/" + this.studentId, {
      search_query: searchQuery,
    });
  }

  onClickCheckIn() {
    this.runCaseloadCheckInLoading = true;
    this.coachService
      .runCaseloadCheckIn()
      .subscribe(
        (res: any) => {
          const data = res.data;
          this.notificationService.toastr.info(
            data
              ? data.message
              : "Check in messages sent to members on caseload"
          );
        },
        (err) => {
          this.notificationService.toastr.error(
            "Could not send check in messages"
          );
        }
      )
      .add(() => (this.runCaseloadCheckInLoading = false));
  }

  handleEvent = ($event) => {

    if ($event.action === 'notify') {
      const left = $event.left / 1000
      if(left == 300) {
        this.notificationService.toastr.info('5minutes left in this session')
      }
      window.sessionStorage.setItem('timer', `${left}`);
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      filter((term) => term.length > 1),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.searchService(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false)),
      mergeOperator(this.hideSearchingWhenUnsubscribed)
    );

  jumpToDateInConversation(studentId: string, date: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: { jump_to: date },
    };

    this.router.navigate(["/chat/", studentId], navigationExtras);
    setTimeout((_) => (this.searchModel = ""));
  }

  getMFAStatus() {
    this.auth.enabledMfA$.subscribe(res => {
        if (res == undefined) {
            this.auth.getCurrentUser().subscribe(res => {
                let enaledValue = res?.enabledMFA;
                this.enabledTwoFA = enaledValue;
            })
            return []
        }
        let enaledValue = res;
        this.enabledTwoFA = enaledValue;
    })
  }

  private getCrisisTextsMembers(): void {
    this.convService.getCrisisTextsMembers().subscribe(data => this.crisisTextsMembers = data);
  }

  goToChat(memberId: string): void {
    this.router.navigate([`chat/${memberId}`]);
  }

  logout() {
    this.auth.logout();
  }
}
