
import { throwError as observableThrowError,  Observable } from 'rxjs';

import { map, finalize } from 'rxjs/operators';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { environment } from 'environments/environment';

import { AppService } from './app.service';
import { JWTokenService } from './jwtoken.service';

@Injectable()
export class ApiService {
  readonly API_BASE = environment.apiUrl;

  constructor(
    private app: AppService,
    private httpClient: HttpClient,
    private jwtService: JWTokenService
  ) { }

  private getHeaders() {
    if (this.jwtService.getToken()) {
      return { 'Authorization': this.jwtService.getToken() };
    } else {
      return {};
    }
  }

  private handleError = (err: HttpErrorResponse | any) => {
    if (
      err.status === 401 &&
      err.error.message === 'Invalid Access Token'
    ) {
      this.app.onUnauthorisedResponse$.emit();
    }

    return observableThrowError(err);
  }

  private convertObjectToParams(object) {
    const params = {};
    for (let prop in object) {
      if (object.hasOwnProperty(prop) && object[prop]) {
        params[prop] = object[prop];
      }
    }
    return params;
  }

  get(
    endpoint: string,
    params?: any,
    options: { responseType? } = { responseType: 'json'}
  ) {
    params = this.convertObjectToParams(params);

    return this.httpClient.get(this.API_BASE + endpoint, {
      responseType: options.responseType,
      params,
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }

  post(endpoint: string, data: any = {}) {
    return this.httpClient.post(this.API_BASE + endpoint, data, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }
  put(endpoint: string, data: any = {}) {
    return this.httpClient.put(this.API_BASE + endpoint, data, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }

  patch(endpoint: string, data: any = {}) {
    return this.httpClient.patch(this.API_BASE + endpoint, data, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }

  patchData<T>(endpoint:string, data: unknown) {
    return this.httpClient.patch<T>(this.API_BASE + endpoint, data, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }

  delete(endpoint: string) {
    return this.httpClient.delete(this.API_BASE + endpoint, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }

  postData<T>(endpoint: string, data: unknown ) {
    return this.httpClient.post<T>(this.API_BASE + endpoint, data, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
   }

  getData<T>(endpoint: string) {
    return this.httpClient.get<T>(this.API_BASE + endpoint, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }

  dispose(endpoint: string, data: unknown ) {
    return this.httpClient.request('DELETE', this.API_BASE + endpoint, {
        body: data,
        headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
}
}

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {

  currentRequestsCount = 0;

  constructor(private app: AppService) { }

  private setShowRequestSpinner = (showRequestSpinner = true) => {
    if (showRequestSpinner) {
      this.currentRequestsCount++;
    } else {
      this.currentRequestsCount--;
    }
    this.app.onShowRequestSpinner$.emit(true);

    if (this.currentRequestsCount === 0) {
      this.app.onShowRequestSpinner$.emit(showRequestSpinner);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.setShowRequestSpinner(true);
    return next
      .handle(req)
      .pipe(finalize(() => this.setShowRequestSpinner(false)));
  }
}
