import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UploadApiService {
  constructor(private http: HttpClient) {}

  uploadFile(uploadUrl: string, file: File): Observable<number | HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': file.type
    });

    const progressSubject = new Subject<number | HttpResponse<any>>();

    this.http.put(uploadUrl, file, { headers, reportProgress: true, observe: 'events' })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((100 * event.loaded) / event.total!);
            progressSubject.next(progress);
          } else if (event instanceof HttpResponse) {
            progressSubject.next(event);
            progressSubject.complete();
          }
        },
        error: (err) => {
          console.error('File upload failed:', err);
          progressSubject.error(err);
        }
      });

    return progressSubject.asObservable();
  }
}
