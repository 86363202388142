export const EMOJI_DB: any = [
  {
    emoji: "😄",
    description: "smiling face with open mouth and smiling eyes",
    aliases: ["smile"],
    tags: ["happy", "joy", "pleased"]
  },
  {
    emoji: "😃",
    description: "smiling face with open mouth",
    aliases: ["smiley"],
    tags: ["happy", "joy", "haha"]
  },
  {
    emoji: "😀",
    description: "grinning face",
    aliases: ["grinning"],
    tags: ["smile", "happy"]
  },
  {
    emoji: "😊",
    description: "smiling face with smiling eyes",
    aliases: ["blush"],
    tags: ["proud"]
  },
  {
    emoji: "☺️",
    description: "white smiling face",
    aliases: ["relaxed"],
    tags: ["blush", "pleased"]
  },
  {
    emoji: "😜",
    description: "face with stuck-out tongue and winking eye",
    aliases: ["stuck_out_tongue_winking_eye"],
    tags: ["prank", "silly"]
  },
  {
    emoji: "😝",
    description: "face with stuck-out tongue and tightly-closed eyes",
    aliases: ["stuck_out_tongue_closed_eyes"],
    tags: ["prank"]
  },
  {
    emoji: "😛",
    description: "face with stuck-out tongue",
    aliases: ["stuck_out_tongue"],
    tags: []
  },
  {
    emoji: "😁",
    description: "grinning face with smiling eyes",
    aliases: ["grin"],
    tags: []
  },
  {
    emoji: "😌",
    description: "relieved face",
    aliases: ["relieved"],
    tags: ["whew"]
  },
  {
    emoji: "😂",
    description: "face with tears of joy",
    aliases: ["joy"],
    tags: ["tears"]
  },
  {
    emoji: "😅",
    description: "smiling face with open mouth and cold sweat",
    aliases: ["sweat_smile"],
    tags: ["hot"]
  },
  {
    emoji: "😆",
    description: "smiling face with open mouth and tightly-closed eyes",
    aliases: ["laughing", "satisfied"],
    tags: ["happy", "haha"]
  },
  {
    emoji: "😋",
    description: "face savouring delicious food",
    aliases: ["yum"],
    tags: ["tongue", "lick"]
  },
  {
    emoji: "😎",
    description: "smiling face with sunglasses",
    aliases: ["sunglasses"],
    tags: ["cool"]
  },
  {
    emoji: "😯",
    description: "hushed face",
    aliases: ["hushed"],
    tags: ["silence", "speechless"]
  },
  {
    emoji: "😇",
    description: "smiling face with halo",
    aliases: ["innocent"],
    tags: ["angel"]
  },
  {
    emoji: "😏",
    description: "smirking face",
    aliases: ["smirk"],
    tags: ["smug"]
  },
  {
    emoji: "😺",
    description: "smiling cat face with open mouth",
    aliases: ["smiley_cat"],
    tags: []
  },
  { emoji: "🔥", description: "fire", aliases: ["fire"], tags: ["burn"] },
  {
    emoji: "✨",
    description: "sparkles",
    aliases: ["sparkles"],
    tags: ["shiny"]
  },
  { emoji: "🌟", description: "glowing star", aliases: ["star2"], tags: [] },
  {
    emoji: "👀",
    description: "eyes",
    aliases: ["eyes"],
    tags: ["look", "see", "watch"]
  },
  {
    emoji: "💛",
    description: "yellow heart",
    aliases: ["yellow_heart"],
    tags: []
  },
  {
    emoji: "💭",
    description: "thought balloon",
    aliases: ["thought_balloon"],
    tags: ["thinking"]
  },
  {
    emoji: "🐹",
    description: "hamster face",
    aliases: ["hamster"],
    tags: ["pet"]
  },
  {
    emoji: "🌞",
    description: "sun with face",
    aliases: ["sun_with_face"],
    tags: ["summer"]
  },
  {
    emoji: "🌝",
    description: "full moon with face",
    aliases: ["full_moon_with_face"],
    tags: []
  },
  {
    emoji: "🌜",
    description: "last quarter moon with face",
    aliases: ["last_quarter_moon_with_face"],
    tags: []
  },
  {
    emoji: "🌛",
    description: "first quarter moon with face",
    aliases: ["first_quarter_moon_with_face"],
    tags: []
  },
  {
    emoji: "⭐",
    description: "white medium star",
    aliases: ["star"],
    tags: []
  },
  {
    emoji: "☀️",
    description: "black sun with rays",
    aliases: ["sunny"],
    tags: ["weather"]
  },
  {
    emoji: "⛅",
    description: "sun behind cloud",
    aliases: ["partly_sunny"],
    tags: ["weather", "cloud"]
  },
  {
    emoji: "❄️",
    description: "snowflake",
    aliases: ["snowflake"],
    tags: ["winter", "cold", "weather"]
  },
  {
    emoji: "🌈",
    description: "rainbow",
    aliases: ["rainbow"],
    tags: ["pride"]
  },
  { emoji: "🌊", description: "water wave", aliases: ["ocean"], tags: ["sea"] },
  {
    emoji: "🎓",
    description: "graduation cap",
    aliases: ["mortar_board"],
    tags: ["education", "college", "university", "graduation"]
  },
  {
    emoji: "🎆",
    description: "fireworks",
    aliases: ["fireworks"],
    tags: ["festival", "celebration"]
  },
  {
    emoji: "🎇",
    description: "firework sparkler",
    aliases: ["sparkler"],
    tags: []
  },
  {
    emoji: "🎃",
    description: "jack-o-lantern",
    aliases: ["jack_o_lantern"],
    tags: ["halloween"]
  },
  {
    emoji: "🎅",
    description: "father christmas",
    aliases: ["santa"],
    tags: ["christmas"]
  },
  {
    emoji: "🎄",
    description: "christmas tree",
    aliases: ["christmas_tree"],
    tags: []
  },
  {
    emoji: "🎁",
    description: "wrapped present",
    aliases: ["gift"],
    tags: ["present", "birthday", "christmas"]
  },
  {
    emoji: "🎉",
    description: "party popper",
    aliases: ["tada"],
    tags: ["party"]
  },
  {
    emoji: "🎊",
    description: "confetti ball",
    aliases: ["confetti_ball"],
    tags: []
  },
  {
    emoji: "🎈",
    description: "balloon",
    aliases: ["balloon"],
    tags: ["party", "birthday"]
  },
  { emoji: "✏️", description: "pencil", aliases: ["pencil2"], tags: [] },
  {
    emoji: "📏",
    description: "straight ruler",
    aliases: ["straight_ruler"],
    tags: []
  },
  { emoji: "📓", description: "notebook", aliases: ["notebook"], tags: [] },
  { emoji: "📚", description: "books", aliases: ["books"], tags: ["library"] },
  {
    emoji: "🎨",
    description: "artist palette",
    aliases: ["art"],
    tags: ["design", "paint"]
  },
  {
    emoji: "🎬",
    description: "clapper board",
    aliases: ["clapper"],
    tags: ["film"]
  },
  {
    emoji: "🎤",
    description: "microphone",
    aliases: ["microphone"],
    tags: ["sing"]
  },
  {
    emoji: "🎧",
    description: "headphone",
    aliases: ["headphones"],
    tags: ["music", "earphones"]
  },
  {
    emoji: "🎵",
    description: "musical note",
    aliases: ["musical_note"],
    tags: []
  },
  {
    emoji: "🎶",
    description: "multiple musical notes",
    aliases: ["notes"],
    tags: ["music"]
  },
  {
    emoji: "🎹",
    description: "musical keyboard",
    aliases: ["musical_keyboard"],
    tags: ["piano"]
  },
  { emoji: "🎻", description: "violin", aliases: ["violin"], tags: [] },
  { emoji: "🎺", description: "trumpet", aliases: ["trumpet"], tags: [] },
  { emoji: "🎷", description: "saxophone", aliases: ["saxophone"], tags: [] },
  { emoji: "🎸", description: "guitar", aliases: ["guitar"], tags: ["rock"] },
  {
    emoji: "🎮",
    description: "video game",
    aliases: ["video_game"],
    tags: ["play", "controller", "console"]
  },
  {
    emoji: "🏈",
    description: "american football",
    aliases: ["football"],
    tags: ["sports"]
  },
  {
    emoji: "🏀",
    description: "basketball and hoop",
    aliases: ["basketball"],
    tags: ["sports"]
  },
  {
    emoji: "⚽",
    description: "soccer ball",
    aliases: ["soccer"],
    tags: ["sports"]
  },
  {
    emoji: "⚾️",
    description: "baseball",
    aliases: ["baseball"],
    tags: ["sports"]
  },
  {
    emoji: "🏆",
    description: "trophy",
    aliases: ["trophy"],
    tags: ["award", "contest", "winner"]
  },
  { emoji: "🍕", description: "slice of pizza", aliases: ["pizza"], tags: [] },
  {
    emoji: "🍔",
    description: "hamburger",
    aliases: ["hamburger"],
    tags: ["burger"]
  },
  { emoji: "🍟", description: "french fries", aliases: ["fries"], tags: [] },
  {
    emoji: "🍗",
    description: "poultry leg",
    aliases: ["poultry_leg"],
    tags: ["meat", "chicken"]
  },
  { emoji: "🍱", description: "bento box", aliases: ["bento"], tags: [] },
  {
    emoji: "🍜",
    description: "steaming bowl",
    aliases: ["ramen"],
    tags: ["noodle"]
  },
  { emoji: "🍞", description: "bread", aliases: ["bread"], tags: ["toast"] },
  { emoji: "🍩", description: "doughnut", aliases: ["doughnut"], tags: [] },
  { emoji: "🍮", description: "custard", aliases: ["custard"], tags: [] },
  {
    emoji: "🍦",
    description: "soft ice cream",
    aliases: ["icecream"],
    tags: []
  },
  { emoji: "🍨", description: "ice cream", aliases: ["ice_cream"], tags: [] },
  { emoji: "🍧", description: "shaved ice", aliases: ["shaved_ice"], tags: [] },
  {
    emoji: "🎂",
    description: "birthday cake",
    aliases: ["birthday"],
    tags: ["party"]
  },
  {
    emoji: "🍰",
    description: "shortcake",
    aliases: ["cake"],
    tags: ["dessert"]
  },
  { emoji: "🍪", description: "cookie", aliases: ["cookie"], tags: [] },
  {
    emoji: "🍫",
    description: "chocolate bar",
    aliases: ["chocolate_bar"],
    tags: []
  },
  { emoji: "🍭", description: "lollipop", aliases: ["lollipop"], tags: [] },
  { emoji: "🍎", description: "red apple", aliases: ["apple"], tags: [] },
  {
    emoji: "🍏",
    description: "green apple",
    aliases: ["green_apple"],
    tags: ["fruit"]
  },
  {
    emoji: "🍊",
    description: "tangerine",
    aliases: ["tangerine", "orange", "mandarin"],
    tags: []
  },
  { emoji: "🍇", description: "grapes", aliases: ["grapes"], tags: [] },
  { emoji: "🍉", description: "watermelon", aliases: ["watermelon"], tags: [] },
  { emoji: "🍐", description: "pear", aliases: ["pear"], tags: [] },
  { emoji: "🍍", description: "pineapple", aliases: ["pineapple"], tags: [] },
  {
    emoji: "✈️",
    description: "airplane",
    aliases: ["airplane"],
    tags: ["flight"]
  },
  {
    emoji: "💯",
    description: "hundred points symbol",
    aliases: ["100"],
    tags: ["score", "perfect"]
  }
];
