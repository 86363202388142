import * as TYPES from './action-types';

export function setAppLoading(isLoading = false) {
  return {
    type: TYPES.SET_APP_LOADING,
    payload: isLoading
  };
}

export function setAppLoadingPercentage(percentage = 0) {
  return {
    type: TYPES.SET_APP_LOADING_PERCENTAGE,
    payload: percentage
  };
}

export function setAppLoadingMessage(message = '') {
  return {
    type: TYPES.SET_APP_LOADING_PERCENTAGE,
    payload: message
  };
}

export function setStatusOptions(options) {
  return {
    type: TYPES.SET_STATUS_OPTIONS,
    payload: options || []
  };
}
