import { combineReducers } from 'redux';
import * as TYPES from 'app/redux/actions/action-types';
import { normal } from './schema';

function setAllStudentsIsSelected(studentsById, isSelected) {
  let dashStudents = { ...studentsById };
  Object.keys(dashStudents).forEach(key => dashStudents[key].isSelected = isSelected);
  return dashStudents;
}

const byIdReducer = (state = {}, action) => {
  switch (action.type) {

    case TYPES.LOAD_USER_SUCCESS:
      return { ...state, [action.user._id]: action.user };
    case TYPES.ADD_USER_SUCCESS:
      return { ...state, [action.user._id]: action.user };
    case TYPES.ADD_STUDENT_SUCCESS:
      return { ...state, [action.student._id]: action.student };
    case TYPES.LOAD_USERS_SUCCESS:
      return { ...state, ...normal(action).entities.users };
    case TYPES.LOAD_STUDENTS_SUCCESS:
      let data = {
        users: action.students
      };
      const normalizedUsers = normal(data).entities.users;
      return { ...state, ...normalizedUsers };
    case TYPES.UPDATE_STUDENT_SUCCESS:
      let studentId = action.student._id;
      let editStudent = { ...state[studentId], ...action.student };
      return { ...state, [studentId]: editStudent };
    case TYPES.ON_NEW_STUDENT:
      return { ...state, [action.student._id]: action.student };
    default:
      return state;
  }
};

const coachesIdsReducer = (state = [], action) => {
  switch (action.type) {
    case TYPES.LOAD_USERS_SUCCESS:
      return [...normal(action).result.users];
    default:
      return state;
  }
};

const studentsIdsReducer = (state = [], action) => {
  switch (action.type) {
    case TYPES.ADD_STUDENT_SUCCESS:
      return [...state, action.student._id];
    case TYPES.DELETE_STUDENT_SUCCESS:
      return state.filter(id => id !== action.studentId);
    case TYPES.LOAD_STUDENTS_SUCCESS:
      let data = {
        users: action.students
      };
      return normal(data).result.users;
    case TYPES.ON_NEW_STUDENT:
      return [action.student._id, ...state];
    default:
      return state;
  }
};

export const SELECTORS = {
  getAllStudents: (state) => state.entities.users
    .allIds.map(id => state.entities.users.byId[id]),

  getAllCoaches: (state) => state.entities.users
    .coachesIds.map(id => state.entities.users.byId[id])
};

export default combineReducers({
  byId: byIdReducer,
  coachesIds: coachesIdsReducer,
  studentsIds: studentsIdsReducer
});
