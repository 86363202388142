
import { of as observableOf,  Observer ,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';

import { loadUsersSuccess } from 'app/redux/actions/user.actions';
import { loadAssignedStudentsSuccess } from 'app/redux/actions/student.actions';
import { SELECTORS as USER_SELECTORS } from 'app/redux/reducers/user.reducer';
import { SELECTORS as DASHBOARD_SELECTORS } from 'app/redux/reducers/dashboard.reducer';

import { ApiService } from './api.service';

@Injectable()
export class PartnerAccountService {

  partnerAccountsProfile = {};
  partnerAccountsArray = [];
  assignedStudents = [];

  constructor(
    private api: ApiService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  // login(email: string, password: string) {
  //   return this.api.post('/accounts/login', {
  //     email,
  //     password
  //   });
  // }

  createPartnerAccount(partnerAccount) {
    return this.api.post('/partner-accounts', partnerAccount)
  }

  getPartnerAccount(id: string) {
    if (this.partnerAccountsProfile[id]) {
      return observableOf(this.partnerAccountsProfile[id]);
    } else {
      return this.api.get('/partner-accounts/' + id).pipe(
        map(res => {
          const student = res;
          this.partnerAccountsProfile[id] = student;
          return student;
        }));
    }
  }

  getPartnerAccounts() {
    // return this.ngRedux.select(USER_SELECTORS.getAllPartnerAccountes);
    return this.api.get('/partner-accounts').pipe(map((res: any) => res.data));
  }

  // loadPartnerAccounts() {
    // if (this.ngRedux.getState().entities.users.partnerAccountsIds.length) {
    //   return ;
    // }
    // this.api.get('/partner-accounts').subscribe((res: any) => {
    //   this.ngRedux.dispatch(loadUsersSuccess(res.data));
    // });
  // }

  // getAssignedStudents() {
  //   return this.ngRedux.select(DASHBOARD_SELECTORS.getAllAssignedStudents);
  // }

  // getAssignedStudentsDashboard() {
  //   return this.ngRedux.select(DASHBOARD_SELECTORS.getAllAssignedStudentsDashboard);
  // }

  // loadAssignedStudents(force?) {
  //   if (!force && this.ngRedux.getState().entities.dashboard.assignedStudentsIds.length) {
  //     return ;
  //   }
  //   this.api.get('/partner-accounts/me/students')
  //     .subscribe((res: any) => {
  //       this.ngRedux.dispatch(loadAssignedStudentsSuccess(res.data));
  //     });
  // }

  updatePartnerAccount(id: string, data) {
    return this.api.patch('/partner-accounts/' + id, data).pipe(
      map((res: any) => {
        let partnerAccount = res;
        delete partnerAccount.password;
        return partnerAccount;
      }));
  }

  updatePassword(currentPassword, newPassword) {
    return this.api.put('/accounts/me/password', {
      currentPassword,
      newPassword
    });
  }

  resetPassword(password, code) {
    return this.api.post('/accounts/reset-password/password', {
      password,
      code
    });
  }

  sendPasswordResetEmail(email) {
    return this.api.post('/accounts/reset-password', {
      email
    });
  }

  deletePartnerAccount(id: string) {
    return this.api.delete('/partner-accounts/' + id);
  }
}
