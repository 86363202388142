<h3 mat-dialog-title>Action Plans for {{riskAlert._student | displayStudentName}} - {{riskAlert.cause}}</h3>
<mat-dialog-content>
  <ul class="action-plan-list">
    <li *ngFor="let ap of actionPlans" class="action-plan-list__item" gdColumns="1fr 1fr" gdGap="10px">
      <div gdColumn="1/3" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <i class="fa fa-user fa-fw fa-2x text-muted"></i>
        <div>
          <p><span class="detail-title text-body">{{ap._partnerAccount.firstName}} {{ap._partnerAccount.lastName}}</span> </p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <i class="fa fa-chess-knight fa-fw fa-2x text-muted"></i>
        <div>
          <p><span class="detail-title">Follow up plan:</span> {{ap.followUpPlan}} </p>
          <p><span class="detail-title">Who followed up:</span> {{ap.whoFollowedUp}}</p>
          <p *ngIf="ap.followedUpAt"><span class="detail-title">Followed up at:</span> {{ap.followedUpAt | momentFormatDate: 'calendar'}}</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <i class="fa fa-poll-h fa-fw fa-2x text-muted"></i>
        <div>
          <p><span class="detail-title">Can act on alert?</span> {{ap.canActOnAlert | fn: translateCanActSurvey }}</p>
          <p><span class="detail-title">Is Alert Helpful?</span> {{ap.isAlertHelpful | fn: translateIsHelpfulSurvey}}</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <i class="fa fa-clock fa-fw fa-2x text-muted"></i>
        <div>
          <p><span class="detail-title">Created at</span> {{ap.createdAt | momentFormatDate: 'calendar'}}</p>
          <p><span class="detail-title">Last updated at</span> {{ap.updatedAt | momentFormatDate: 'calendar' }}</p>
        </div>
      </div>
    </li>

    <div *ngIf="!actionPlans.length">
      No action plans have been created yet
    </div>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial mat-dialog-close>Close</button>
</mat-dialog-actions>
