<ng-template #tagModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Tag message</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-primary">
      {{message.message}}
    </div>

    <b>All Categories</b>
    <ng-select [items]="tags" [bindLabel]="'name'" [multiple]="true" [ngModel]="tempTagsModel"
      (ngModelChange)="masterTempTagChange($event)">
      <ng-template ng-option-tmp let-item="item">
        <span class="badge bg-primary">{{item.category}}</span> {{item.name}}
      </ng-template>
    </ng-select>

    <hr>

    <div class="col p-0">
      <div class="col">
        <b>Emotion</b>
        <ng-select [items]="tagsDict['emotion']" [bindLabel]="'name'" [multiple]="true"
          [ngModel]="tempTagsModelDict['emotion']" (ngModelChange)="tempTagModelChange($event, 'emotion')">
          <ng-template ng-option-tmp let-item="item">
            <span class="badge bg-primary">{{item.category}}</span> {{item.name}}
          </ng-template>
        </ng-select>
      </div>

      <div class="col">
        <b>Topic</b>
        <ng-select [items]="tagsDict['topic']" [bindLabel]="'name'" [multiple]="true"
          [(ngModel)]="tempTagsModelDict['topic']" (ngModelChange)="tempTagModelChange($event, 'topic')">
          <ng-template ng-option-tmp let-item="item">
            <span class="badge bg-primary">{{item.category}}</span> {{item.name}}
          </ng-template>
        </ng-select>
      </div>

      <div class="col">
        <b>Strategy</b>
        <ng-select [items]="tagsDict['strategy']" [bindLabel]="'name'" [multiple]="true"
          [(ngModel)]="tempTagsModelDict['strategy']" (ngModelChange)="tempTagModelChange($event, 'strategy')">
          <ng-template ng-option-tmp let-item="item">
            <span class="badge bg-primary">{{item.category}}</span> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button (click)="c('Cancel')" class="btn btn-secondary">Cancel</button>
    <button (click)="submitTags()" class="btn btn-success">Save</button>
  </div>
</ng-template>

<ng-template #linkConversationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Link Conversation</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-primary">
      {{message.message}}
    </div>
    <div class="col p-0">
      <div class="col">
        <b>Link Type</b>
        <ng-select [items]="conversationLinkOptions" class="class-margin" [bindLabel]="'name'" [multiple]="false"
          [ngModel]="linkConversationOption" (ngModelChange)="linkConversationType($event)">
            item
        </ng-select>
      </div>

      <div class="col" *ngIf="linkConversationOption === 'Recommendations'">
        <div *ngIf="!currentRecommendationToBeLinked.isCompleted" class="recommendation-card"
        [class.active]="currentRecommendationToBeLinked.saved === false">
        <div class="rec-inner">
          <div class="recommendation-content">
            <app-editable-html [isRecommendation]="true" [recommendationId]="currentRecommendationToBeLinked?._id" #eHtmlRec
              (onUpdateDone)="saveRecommendations($event, eHtmlRec)"
              [html]="currentRecommendationToBeLinked.text" style="width: 100%;">
              <div class="recommendation-delete" ehDeleteIcon (click)="removeRecommendation()">
                <mat-icon class="recomendation-icon" style="color: red">
                  delete_outline
                </mat-icon>
              </div>
            </app-editable-html>
          </div>
        </div>
      </div>
      </div>
      <div *ngIf="linkConversationOption === 'Notes'">
        <mat-accordion class="note-inner">
          <mat-expansion-panel *ngFor="let subnotes of noteKeys;let first = first; let last = last"
            [ngClass]="{ first: first, last: last }" (expandedChange)="expandNotes(subnotes)">
            <mat-expansion-panel-header
              [ngStyle]="{'background': subnotes === 'SWEMWBS' && swembsScoreChange || subnotes === 'SWEMWBS' && hover==true && swembsScoreChange ? '#f4a261' : '#2980b9'}">
              <span class="d-inline-block fw-bold">{{subnotes}}</span>
            </mat-expansion-panel-header>

            <div fxFlex>
              <div *ngIf="noteExpand[subnotes]">
                <app-editable-html (onUpdateDone)="saveNotes($event, subnotes,eHtmlNote)" #eHtmlNote
                  [html]="student._data.notesObj[subnotes]">
                </app-editable-html>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button (click)="c('Cancel')" class="btn btn-secondary">Cancel</button>
    <button (click)="linkConversationOption === 'Recommendations' ? linkRecommmendationToMessage(message.updatedAt, message._student): linkNoteToConversation()" class="btn btn-success">
      Save
    </button>
  </div>
</ng-template>

<div class="message-container" [class.sent-message]="message._sender?.type !== 'Student'">

  <div class="message-body">
    <!-- <div *ngIf="isUserAdmin" style="align-self: center; padding: 10px">
      <i class="fa fa-eye-slash tag-icon-button" (click)="onSelect.emit({ messageId: message._id })"
        style="font-size: 24px"></i>
    </div>
    <div style="align-self: center; padding: 10px">
      <i class="fa fa-hashtag tag-icon-button" (click)="openTagModal()" style="font-size: 24px"></i>
    </div> -->
    <div class="message-inner-container">
      <mat-card class="card" [ngClass]="{
          'from-coach': message._sender?.type !== 'Student',
          'bg-primary': message._sender?.type === 'Student',
          'bg-secondary': message._sender?.type === undefined
        }">
        <!-- <div class="card-body p-1">
          <ul class="mediaItems-list" *ngIf="message.mediaItems?.length">
            <li class="mediaItems-list-item" *ngFor="let mediaItem of message.mediaItems; let ind = index">
              <a target="_blank" href="{{mediaItem.url}}">Media Link {{ind + 1}}</a>
            </li>
          </ul>
          <div class="card-text">
            {{ displayMessage(message.message) }}
          </div>
        </div> -->
        <mat-card-header>
          <mat-card-title>
            <span *ngIf="message.showName !== false" class="message-sender-name">
              <span *ngIf="message._sender?.type !== 'Student'">
                {{ getAccountName(message._sender) }}
              </span>
              <span *ngIf="(message._sender?.type === 'Student') && (!message._sender?.lastName)">
                {{ message._sender | displayStudentName }}
              </span>
              <span *ngIf="(message._sender?.type === 'Student') && (message._sender?.lastName)">
                {{ message._sender.firstName }} <span class="fs-exclude">{{ message._sender | displayStudentLastName }}</span>
              </span>
            </span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-text">
          {{ displayMessage(message.message) }}
        </mat-card-content>
        <mat-card-actions class="message-actions">
          <button mat-icon-button matTooltip="Select Message" *ngIf="isUserAdmin"
            (click)="onSelect.emit({ messageId: message._id })">
            <mat-icon>visibility_off</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Tag Message" (click)="openTagModal()">
            <mat-icon>tag</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Link Conversation" (click)="openLinkConversationModal()">
            <mat-icon>link</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Copy Link To ClipBoard" (click)="copyConversationLinkToClipboard()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
      <div class="message-timestamp-container py-1">
        <div class="fw-bold" style="font-size: 10px; ">
          <p class="d-block">
            <span class="">
              <i *ngIf="message.messageStatus === 'delivered'" class="fa fa-check text-success me-1"></i>
              <i class="fa fa-clock"></i>
              {{ formattedTimeStamp }}
            </span>
            <i *ngIf="message._sender?.type == undefined" class="fa fa-reply float-end"></i>
            <b *ngIf="message.tier === 2" class="float-end text-danger fw-bold">T2</b>
            <span *ngIf="showConversationResponseTime" class="float-end" [class]="responseTimeStyleClass">
              {{ coachResponseTime }} min RT
            </span>
          </p>
          <p *ngIf="message.restrictions?.isHiddenFromCoach || message.restrictions?.isHiddenFromSupervisor">
            <i class="fa fa-eye-slash"></i>
            <strong> Hidden from: </strong>
            <span class="badge bg-primary ms-1" *ngIf="message.restrictions?.isHiddenFromCoach">Coach</span>
            <span class="badge bg-primary ms-1" *ngIf="message.restrictions?.isHiddenFromSupervisor">Supervisor</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="message-footer">
    <div *ngFor="let tag of message.tags" [ngClass]="{
      'bg-secondary': !!tag.name,
      'bg-success': ((message.emotion !== message.originalEmotion) && tag.category === 'emotion') ||
      ((message.topic !== message.originalTopic) && tag.category === 'topic')
    }" class="badge m-1">{{tag?.name}}</div>
  </div>
</div>
