import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from 'app/core/services/auth.service';

@Pipe({ name: 'displayStudentName', pure: true })
export class DisplayStudentNamePipe implements PipeTransform {

  constructor(private auth: AuthService) { }

  getMaskedPhone(phoneNumber: string) {
    phoneNumber = phoneNumber || '';
    const phone = phoneNumber.split('');
    const dots = phone.splice(phone.length - 4, phone.length).fill('•');
    return phone.concat(dots).join('');
  }

  getStudentName(student: any) {
    if (student.firstName && student.firstName.trim()) {
      if (this.auth.isCurrentUserAdmin() || this.auth.isUserCss() || this.auth.isUserManager()) {
        const name = `${student.firstName} ${student.lastName}`;
        return name.trim();
      }

      return `${student.firstName.trim()} ${student.lastName.charAt(0)}.`;
    }
  }

  transform(student: any, args: string[]): any {
    if (student) {
      return this.getStudentName(student) || this.getMaskedPhone(student.phone) || 'Unnamed Student';
    } else {
      return 'Unnamed Student';
    }
  }
}
